const initialState = {
  active: false,
  title: '',
  childView: null,
  dataModel: {},
  method: null,
  hideInitialModal: false
}

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case 'SHOW_MODAL': {
      return {
        ...state,
        active: true,
        title: action.title,
        childView: action.childView,
        dataModel: action.dataModel,
        method: action.method,
        hideInitialModal: action.hideInitialModal
      }
    }
    case 'HIDE_MODAL': {
      return {
        ...state,
        active: false,
        title: '',
        childView: null,
        dataModel: {},
        method: null
      }
    }
    default:
      return state
  }
}
