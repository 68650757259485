const initialState = {
  recommandation: null,
  objectifsGlycemiques: null,
  objectifsLipidiques: null,
  objectifsTensionnels: null,
  risqueCardio: null,
  strategieTherapeutique: null,
  methodToRunAfterMoreInformations: null
}

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case 'SELECT_RECOMMANDATION': {
      return {
        ...state,
        recommandation: action.recommandation
      }
    }
    case 'RESET_RECOMMANDATION': {
      return {
        ...state,
        recommandation: null
      }
    }
    case 'OBJECTIFS_GLYCEMIQUES': {
      return {
        ...state,
        objectifsGlycemiques: action.objectifsGlycemiques
      }
    }
    case 'OBJECTIFS_LIPIDIQUES': {
      return {
        ...state,
        objectifsLipidiques: action.objectifsLipidiques
      }
    }
    case 'OBJECTIFS_TENSIONNELS': {
      return {
        ...state,
        objectifsTensionnels: action.objectifsTensionnels
      }
    }
    case 'RISQUE_CARDIO': {
      return {
        ...state,
        risqueCardio: action.risqueCardio
      }
    }
    case 'STRATEGIE_THERAPEUTIQUE': {
      return {
        ...state,
        strategieTherapeutique: action.strategieTherapeutique
      }
    }
    case 'SAVE_METHOD_TO_RUN_AFTER_MORE_INFORMATIONS': {
      return {
        ...state,
        methodToRunAfterMoreInformations: action.methodToRunAfterMoreInformations
      }
    }
    default:
      return state
  }
}
