import variables from '../assets/stylesheets/variables'
import { Image, StyleSheet, TouchableHighlight, View } from 'react-native'
import Text from './Text'
import React from 'react'

const ListItem = ({ label, content, icon, iconStyle, onPress }) => {
  return (
    <TouchableHighlight style={styles.bodyItemContainer}
                        underlayColor={variables.colorMidGray}
                        activeOpacity={0.9}
                        onPress={onPress}>
      <View style={styles.bodyItem}>
        <View style={styles.itemCircle}>
          <Image style={{ width: 36, height: 36, resizeMode: 'contain', ...iconStyle }}
                 source={icon} />
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.itemText}>
            {label.replace('\n', ' ')}
          </Text>
          <Text>
            {content}
          </Text>
        </View>
      </View>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  bodyItemContainer: {
    width: '40%',
    minWidth: 158,
    minHeight: 160,
    margin: 10,
    borderRadius: 8,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOpacity: 1,
    shadowRadius: 16,
    elevation: 5,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bodyItem: {
    borderRadius: 8,
    width: '100%',
    minWidth: 158,
    minHeight: 160,
    backgroundColor: 'white',
    padding: 26,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  itemCircle: {
    height: 70,
    width: 70,
    marginRight: 26,
    backgroundColor: '#DBEDFF',
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemText: {
    marginTop: 8,
    fontSize: 15,
    fontFamily: variables.fontFamily.semiBold,
    marginBottom: 6
  }
})

export default ListItem
