const styles = {
  baseLayout: {
    flexDirection: 'row',
    height: '100vh'
  },
  contentSection: {
    width: '60%'
  }
}

export default styles
