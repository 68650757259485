import store from '../../../../store'
import { texts } from '../texts'

export function StrategieInsuffisanceCardiaque () {
  const state = store.getState()
  const patientAge = state.patient.age
  const fevgMoreThan40 = state.patient.ejection === 1
  const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
  const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
  const carefulGLP1 = hasInsuffisanceCardiaque && !fevgMoreThan40
  const result = []
  let label = null
  let info = [texts.metformine_insuffisance_cardiaque]

  label = state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine'
  label += ' et ajouter iSGLT2 quelle que soit l\'HbA1c'
  if (patientAge >= 75) {
    label += ' (si envisageable pour ce patient agé)'
    info.push(texts.isglt2_ages)
  }
  result.push({
    label: label,
    info: info.join('\n\n'),
    nextLineIfLabel: 'ou protection cardiovasculaire supplémentaire recherchée',
    children: [
      {
        label: `Ou AR GLP-1 si intolérance ou contre-indication aux iSGLT2${hasMaladieAtheromateuse ? '' : ' ET objectif non atteint'}`,
        info: carefulGLP1 ? `${texts.careful_aglp1_fe}\n\n${texts.aglp1_benefice_cardiaque}` : texts.aglp1_benefice_cardiaque
      }
    ]
  })

  result.push({
    label: 'Si patient sous iSGLT2, ajouter AR GLP-1',
    info: carefulGLP1 ? texts.careful_aglp1_fe : null
  })

  info = ['Ne pas utiliser la saxagliptine.']
  label = "L'ensemble des autres médicaments antidiabétiques oraux ou injectables peuvent être envisagés dans le respect des éventuelles contre-indications."
  info.push("L'insuline, le répaglinide et les sulfamides peuvent être envisagés en faisant attention au risque hypoglycémique.")

  result.push({
    label: label,
    info: info.join('\n\n'),
    style: {
      fontSize: 14.5
    }
  })
  return result
}
