import React from 'react'
import {
  View,
  StyleSheet,
  TouchableHighlight,
  Linking,
  ScrollView
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../components/Text'
import variables from '../../assets/stylesheets/variables'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/Ionicons'
import { openWebBrowser } from '../../utils'
import LottieContainer from '../../components/LottieContainer'

import baseStyle from '../../assets/stylesheets/layouts/base'

class More extends React.Component {
  constructor() {
    super()
  }

  async openLink (url) {
    openWebBrowser(url)
  }

  render() {
    return (
      <View style={baseStyle.baseLayout}>
        <LinearGradient style={baseStyle.contentSection}
                        colors={variables.bgGradient}>
          <ScrollView alwaysBounceVertical={false}
                      style={{ width: '100%' }}
                      showsVerticalScrollIndicator={false}>
            <TouchableHighlight activeOpacity={0.9}
                                underlayColor={variables.colorHoverItem}
                                onPress={() => this.openLink('https://www.diabguide.com')}>
              <View style={styles.itemRow}>
                <Icon style={{ marginTop: 4, marginRight: 15 }}
                      name="information-circle-outline"
                      size={30}
                      color={variables.primaryColor} />
                <Text style={styles.itemText}>À propos</Text>
                <Icon style={{ marginTop: 5 }}
                      name="chevron-forward"
                      color={variables.primaryColor}
                      size={18} />
              </View>
            </TouchableHighlight>
            <TouchableHighlight activeOpacity={0.9}
                                underlayColor={variables.colorHoverItem}
                                onPress={() => this.openLink('https://www.diabguide.com/faq/')}>
              <View style={styles.itemRow}>
                <Icon style={{ marginTop: 4, marginRight: 15 }}
                      name="help-circle-outline"
                      size={30}
                      color={variables.primaryColor} />
                <Text style={styles.itemText}>FAQ</Text>
                <Icon style={{ marginTop: 5 }}
                      name="chevron-forward"
                      color={variables.primaryColor}
                      size={18} />
              </View>
            </TouchableHighlight>
            <TouchableHighlight activeOpacity={0.9}
                                underlayColor={variables.colorHoverItem}
                                onPress={() => Linking.openURL('mailto:alexandre@diabguide.com?subject=Contact DiabGuide')}>
              <View style={styles.itemRow}>
                <Icon style={{ marginTop: 4, marginRight: 15 }}
                      name="chatbubbles-outline"
                      size={30}
                      color={variables.primaryColor} />
                <Text style={styles.itemText}>Contact</Text>
                <Icon style={{ marginTop: 5 }}
                      name="chevron-forward"
                      color={variables.primaryColor}
                      size={18} />
              </View>
            </TouchableHighlight>
          </ScrollView>
        </LinearGradient>
        <LottieContainer primary
                         lottieAnimation={require('../../assets/lottie/data-analysis.json')} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    paddingHorizontal: 20,
    borderBottomWidth: 0.5,
    borderColor: variables.colorLightGray
  },
  itemText: {
    fontSize: 17,
    flex: 1
  }
})

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(More)
