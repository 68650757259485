import React from 'react'
import { TouchableWithoutFeedback } from 'react-native'
import Text from './Text'
import { SafeAreaView } from 'react-native'
import tabBarStyle from '../assets/stylesheets/components/tabBar'
import variables from '../assets/stylesheets/variables'

const getTextStyle = (index, routeIndex) => {
  const style = { ...tabBarStyle.tabBarItem }
  if (index) {
    style.color = 'white'
  }
  if (index !== routeIndex) {
    style.opacity = 0.7
  } else {
    style.fontFamily = variables.fontFamily.semiBold
  }
  return style
}

function TabBar (props) {
  const { routes, index } = props.state

  return (
    <SafeAreaView forceInset={{ top: 'always', bottom: 'never' }}
                  style={tabBarStyle.tabBar}>
      {routes.map((route, routeIndex) => (
        <TouchableWithoutFeedback onPress={() => props.navigation.navigate(route.name)}
                                  key={route.name}>
          <Text style={getTextStyle(index, routeIndex)}
                numberOfLines={1}>
            {props.descriptors[route.key].options.tabBarLabel}
          </Text>
        </TouchableWithoutFeedback>
      ))}
    </SafeAreaView>
  )
}

export default TabBar
