import React from 'react'
import {
  View,
  StyleSheet,
  SafeAreaView,
  Image,
  AsyncStorage
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../components/Text'
import variables from '../assets/stylesheets/variables'
import { connect } from 'react-redux'
import Button from '../components/Button'
import messaging from '@react-native-firebase/messaging'

class Notifications extends React.Component {
  constructor() {
    super()
  }

  state = {
    isLoading: false
  }

  enableNotifications = async () => {
    await messaging().requestPermission({
      alert: true,
      sound: true,
      badge: false
    })
    this.setState({
      isLoading: true
    })
    if (messaging.AuthorizationStatus.AUTHORIZED) {
      await messaging().subscribeToTopic('all')
      await AsyncStorage.setItem('notifications_set', 'true')
    } else {
      await AsyncStorage.setItem('notifications_set', 'false')
    }
    this.props.navigation.navigate('Preferences')
  }

  render() {
    return (
      <LinearGradient
        colors={variables.primaryGradient}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
        style={styles.container}>
        <SafeAreaView style={{ marginVertical: 60 }}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <View style={{ alignItems: 'center', marginBottom: 60 }}>
              <Image style={{ width: 180, height: 180 }}
                     source={require('../assets/img/icon-notifications.png')} />
            </View>
            <Text style={styles.informationTitle}>
              Activer les notifications
            </Text>
            <Text style={styles.information}>
              Afin d'être informé des dernières mises à jour des nouvelles recommandations, veuillez activer les notifications de l'application DiabGuide.
            </Text>
          </View>
          <Button onPress={this.enableNotifications}
                  loading={this.state.isLoading}
                  label="Activer les notifications" />
        </SafeAreaView>
      </LinearGradient>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingHorizontal: 40
  },
  informationTitle: {
    fontFamily: variables.fontFamily.semiBold,
    fontSize: 19,
    color: 'white',
    letterSpacing: -0.7
  },
  information: {
    fontFamily: 'OpenSans-Light',
    fontSize: 17,
    color: 'white',
    marginTop: 25,
    lineHeight: 28
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100%',
    paddingVertical: 10,
    backgroundColor: variables.secondaryColor,
    borderRadius: 4
  }
})

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
