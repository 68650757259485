import React from 'react'
import {
  View,
  ScrollView
} from 'react-native'
import moment from 'moment'
import InputList from '../../../components/InputList'
import LinearGradient from 'react-native-linear-gradient'
import { updatePatient } from '../../../store/actions/patientActions'
import { connect } from 'react-redux'
import Button from '../../../components/Button'

import variables from '../../../assets/stylesheets/variables'
import baseStyle from '../../../assets/stylesheets/layouts/base'
import stepsStyle from '../../../assets/stylesheets/views/steps'
import Text from '../../../components/Text'
import LottieContainer from '../../../components/LottieContainer'
import { AlertBanner } from '../../../components/AlertBanner'
import { complications } from '../utils'

class SecondStep extends React.Component {
  constructor() {
    super()
  }

  state = {
    errors: {},
    hasError: false
  }

  onChange = async (key, value) => {
    await this.props.updatePatient(key, value)
    this.validateField(key)
  }

  validateField = (field = null) => {
    const errors = {
      diabetesSince: !this.props.patient.diabetesSince,
      last_hba1c: !this.props.patient.last_hba1c,
      lifeExpectancy: !this.props.patient.lifeExpectancy
    }

    this.setState((prevState) => {
      const newErrors = field ? {
        ...prevState.errors,
        [field]: errors[field]
      } : errors

      const hasError = !Object.values(newErrors).every(i => !i)
      return {
        errors: newErrors,
        hasError
      }
    })

    return errors
  }

  validateSecondStep = () => {
    const errors = this.validateField()
    const hasError = !Object.values(errors).every(i => !i)
    if (!hasError || __DEV__) {
      this.props.navigation.navigate('ThirdStep')
    }
  }

  render() {
    const hasRenalDisease = this.props.patient.complications.some(i => i.id === 3)
    const hasEjection = this.props.patient.complications.some(i => i.id === 5)

    return (
      <View style={baseStyle.baseLayout}>
        <LinearGradient style={baseStyle.contentSection}
                        colors={variables.bgGradient}>
          <ScrollView alwaysBounceVertical={false}
                      contentContainerStyle={stepsStyle.contentContainerStyle}>
            <Text style={stepsStyle.stepTitle}>2. Données diabète</Text>
            {
              (this.state.hasError || hasRenalDisease) && (
                <AlertBanner type={this.state.hasError ? 'error': 'info'} tooltip={this.state.hasError ? null : 'maladie-renale'}>
                  {this.state.hasError
                    ? 'Certaines informations sont manquantes ou incorrectes'
                    : 'Le patient est atteint d\'une maladie rénale chronique'
                  }
                </AlertBanner>
              )
            }
            <InputList title="Année de diagnostic"
                       type="number"
                       error={this.state.errors.diabetesSince}
                       options={{
                         minUnit: 1900,
                         maxUnit: moment().year(),
                         initialUnits: moment().year() - 5
                       }}
                       onChange={(value) => this.onChange('diabetesSince', value)}
                       value={this.props.patient.diabetesSince} />
            <InputList title="Dernière HbA1c"
                       type="number"
                       error={this.state.errors.last_hba1c}
                       placeholder="Dernière HbA1c du patient..."
                       options={{
                         regex: /^[1-9]{1,2}(,|.)?\d?$/,
                         unit: '%',
                         digits: 1
                       }}
                       onChange={(value) => this.onChange('last_hba1c', value)}
                       value={this.props.patient.last_hba1c} />
            <InputList title="Albuminurie/créatininurie"
                       type="choice"
                       choices={[{ id: 'under_30', label: 'Moins de 30 mg/g' }, { id: 'between_30_and_300', label: '30 à 300 mg/g' }, { id: 'over_300', label: 'Plus de 300 mg/g' }]}
                       onChange={(value) => this.onChange('albuminurie', value)}
                       value={this.props.patient.albuminurie} />
            <InputList title="DFG"
                       type="number"
                       options={{
                         minUnit: 15,
                         maxUnit: 59,
                         hasLower: true,
                         hasHigher: true,
                         unit: 'mL/min/1,73 m2'
                       }}
                       onChange={(value) => this.onChange('dfg', value)}
                       value={this.props.patient.dfg} />
            <InputList title="Complications connues"
                       type="sidepanel"
                       sidePanelTitle="Complications"
                       childView="complications"
                       onChange={(values) => this.props.updatePatient('complications', values)}
                       choices={complications}
                       value={this.props.patient.complications} />
            {hasEjection &&
              <InputList title="Fraction d'éjection"
                         type="choice"
                         choices={[{ id: 1, label: 'Plus de 40%' }, { id: 2, label: 'Moins de 40%' }]}
                         onChange={(value) => this.onChange('ejection', value)}
                         value={this.props.patient.ejection}/>
            }
            <InputList title="Autre pathologie sévère"
                       type="choice"
                       onChange={(value) => this.onChange('comorbidite', value)}
                       choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.comorbidite} />
            <InputList title="Espérance de vie estimée inférieure à 5 ans"
                       type="choice"
                       onChange={(value) => this.onChange('lifeExpectancy', value)}
                       choices={[{ id: 1, label: 'Oui' }, { id: 2, label: 'Non' }]}
                       value={this.props.patient.lifeExpectancy} />
          </ScrollView>
          <View style={stepsStyle.footer}>
            <View style={stepsStyle.footerContainer}>
              <Button style={{ marginRight: 10 }}
                      type="outline"
                      onPress={() => this.props.navigation.goBack()}
                      label="Retour" />
              <Button onPress={this.validateSecondStep}
                      label="Suivant" />
            </View>
          </View>
        </LinearGradient>
        <LottieContainer primary
                         lottieAnimation={require('../../../assets/lottie/data-analysis.json')} />
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (key, value) => {
    dispatch(updatePatient(key, value))
  }
})

const mapStateToProps = (state) => ({
  patient: state.patient
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep)
