import React from 'react'
import variables from '../assets/stylesheets/variables'
import Lottie from 'react-native-web-lottie'
import lottieStyle from '../assets/stylesheets/components/lottie'
import LinearGradient from 'react-native-linear-gradient'

class LottieContainer extends React.Component {
  render() {
    return (
      <LinearGradient style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                      colors={this.props.primary ? variables.primaryGradient : variables.bgGradient}
                      start={this.props.primary ? { x: 0, y: 1 } : undefined}
                      end={this.props.primary ? { x: 1, y: 0 } : undefined}>
        <Lottie style={lottieStyle.lottie}
                source={this.props.lottieAnimation} autoPlay loop />
      </LinearGradient>
    )
  }
}

export default LottieContainer
