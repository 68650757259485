export function updatePatient (key, value) {
  return {
    type: 'UPDATE_PATIENT',
    key,
    value
  }
}

export function loadPatientProfile (patientProfile) {
  return {
    type: 'LOAD_PATIENT_PROFILE',
    patientProfile
  }
}

export function resetPatient () {
  return {
    type: 'RESET_PATIENT'
  }
}
