import React from 'react'
import { connect } from 'react-redux'
import variables from '../assets/stylesheets/variables'
import {
  View,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight,
  Animated,
  Dimensions,
  TouchableWithoutFeedback,
  ScrollView,
  Platform
} from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'
import Text from './Text'
import LinearGradient from 'react-native-linear-gradient'
import CheckButton from './CheckButton'
import { hideSidePanel } from '../store/actions/sidepanelActions'
import { showModal } from '../store/actions/modalActions'

const screenWidth = Dimensions.get('window').width
const sidepanelSize = Platform.OS === 'web' ? 0.4 : 0.8

class SidePanel extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    fadeAnim: new Animated.Value(0),
    translateAnim: new Animated.Value(screenWidth*sidepanelSize),
    values: this.props.value || []
  }

  componentDidMount () {
    this.toggleRevealSidePanel(true)
  }

  async toggleValue (value) {
    if (value.disabled) {
      return
    }
    const containsValue = this.state.values.find(item => item.id === value.id)
    if (containsValue) {
      let newValues = this.state.values.filter(item => item.id !== containsValue.id)
      await this.setState({
        values: newValues
      })
      this.onValidateItems(false)
    } else {
      await this.setState(prevState => ({
        values: [ ...prevState.values, value ]
      }))
      this.onValidateItems(false)
    }
  }

  toggleRevealSidePanel = (reveal) => {
    Animated.timing(
      this.state.fadeAnim,
      {
        toValue: reveal ? 1 : 0,
        duration: 200
      }
    ).start()
    Animated.timing(
      this.state.translateAnim,
      {
        toValue: reveal ? 0 : screenWidth*sidepanelSize,
        duration: 200
      }
    ).start()
  }

  hideSidePanel = () => {
    this.toggleRevealSidePanel(false)
    setTimeout(() => {
      this.props.hideSidePanel()
    }, 200)
  }

  sidepanelItems = () => {
    return this.props.choices.map((item, index) => {
      const isItemSelected = this.state.values.some(value => value.id === item.id)
      return (!item.disabled || isItemSelected) && (
        <TouchableHighlight key={index}
                            activeOpacity={0.9}
                            underlayColor={variables.colorHoverItem}
                            {...(!item.disabled && { onPress: () => this.toggleValue(item) })}>
          <View style={{ ...styles.sidepanelItem, ...(item.disabled && { opacity: 0.5 }) }}>
            <CheckButton checked={isItemSelected} />
            <View style={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontSize: 16, marginLeft: 15, fontFamily: isItemSelected ? variables.fontFamily.regular : variables.fontFamily.light }}>
                {item.label}
              </Text>
              {item.info &&
                <TouchableOpacity activeOpacity={0.8}
                                  onPress={() => this.props.showModal('', item.info)}
                                  style={{ width: 30, height: 30, alignItems: 'center', justifyContent: 'center' }}>
                  <Icon style={{ marginTop: 4 }}
                        name="ios-help-circle-outline"
                        size={20}
                        color={variables.colorMidGray} />
                </TouchableOpacity>
              }
            </View>
          </View>
        </TouchableHighlight>
      )
    })
  }

  onValidateItems = (closeSidePanel) => {
    this.props.selectValue(this.state.values)
    if (closeSidePanel) {
      this.hideSidePanel()
    }
  }

  render() {
    return (
      <Animated.View style={[styles.sidepanelBg, { opacity: this.state.fadeAnim }]}>
        <TouchableOpacity style={{ flex: 1 }}
                          activeOpacity={1}
                          onPress={this.hideSidePanel}>
          <Animated.View style={[styles.sidepanelContainer, { transform: [{ translateX: this.state.translateAnim }] }]}>
            <TouchableWithoutFeedback onPress={() => {}}>
              <View style={{ flex: 1 }}>
                <LinearGradient colors={variables.primaryGradient}
                                start={{ x: 0, y: 0 }}
                                end={{ x: 1, y: 0 }}>
                  <SafeAreaView>
                    <View style={styles.sidepanelHeader}>
                      <TouchableOpacity activeOpacity={0.8}
                                        hitSlop={10}
                                        onPress={this.hideSidePanel}>
                        <Icon style={{ marginTop: 2 }}
                              name="ios-close"
                              color="white"
                              size={22} />
                      </TouchableOpacity>
                      <Text style={{ color: 'white', fontSize: 16 }}>
                        {this.props.title}
                      </Text>
                      <TouchableOpacity activeOpacity={0.8}
                                        hitSlop={10}
                                        onPress={() => this.onValidateItems(true)}>
                        <Text style={{ color: 'white', fontSize: 16, fontFamily: 'OpenSans-Semibold' }}>OK</Text>
                      </TouchableOpacity>
                    </View>
                  </SafeAreaView>
                </LinearGradient>
                <LinearGradient style={styles.sidepanelBody}
                                colors={['#FFFFFF', '#F8FBFF']}>
                  <ScrollView alwaysBounceVertical={false}
                              showsVerticalScrollIndicator={false}>
                    {this.sidepanelItems()}
                  </ScrollView>
                </LinearGradient>
              </View>
            </TouchableWithoutFeedback>
          </Animated.View>
        </TouchableOpacity>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  sidepanelBg: {
    display: 'flex',
    flex: 1,
    position: 'absolute',
    zIndex: 9998,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%'
  },
  sidepanelContainer: {
    position: 'absolute',
    zIndex: 9999,
    right: 0,
    height: '100%',
    width: sidepanelSize*100 + '%',
    display: 'flex'
  },
  sidepanelHeader: {
    height: 75,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 28,
    paddingRight: 28
  },
  sidepanelBody: {
    flex: 1
  },

  sidepanelItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 76,
    paddingLeft: 22,
    paddingRight: 22,
    borderBottomWidth: 1,
    borderBottomColor: variables.colorLightGray
  }
})

const mapStateToProps = (state) => ({
  selectValue: state.sidepanel.method,
  title: state.sidepanel.title,
  choices: state.sidepanel.choices,
  childView: state.sidepanel.childView,
  value: state.sidepanel.value
})

const mapDispatchToProps = (dispatch) => ({
  hideSidePanel: () => {
    dispatch(hideSidePanel())
  },
  showModal: (title, childView) => {
    dispatch(showModal(title, childView))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel)
