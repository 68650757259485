import React from 'react'
import variables from '../assets/stylesheets/variables'
import Text from './Text'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'
import { useDispatch } from 'react-redux'
import { showModal } from '../store/actions/modalActions'

const bg = {
  error: variables.dangerColor,
  warning: variables.warningColor,
  info: variables.infoBgColor
}

const color = {
  error: variables.errorColor,
  warning: variables.warningColor,
  info: variables.primaryColor
}

export const AlertBanner = ({ type, children, tooltip }) => {
  const dispatch = useDispatch()

  return (
    <View style={{ paddingHorizontal: Platform.OS === 'web' ? 10 : 0, width: '100%' }}>
      <View style={{ ...styles.banner, backgroundColor: bg[type], flexDirection: 'row', alignItems: 'center' }}>
        <Text style={{ color: color[type], maxWidth: tooltip ? '95%' : '100%' }}>
          {children}
        </Text>
        {tooltip &&
          <TouchableOpacity activeOpacity={0.8}
                            style={{ marginLeft: 6, marginTop: 1, marginBottom: -1 }}
                            hitSlop={10}
                            onPress={() => dispatch(showModal('', tooltip))}>
            <Icon name="ios-help-circle-outline"
                  size={18}
                  color={color[type]} />
          </TouchableOpacity>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  banner: {
    padding: 20,
    width: '100%',
    ...(Platform.OS === 'web' && {
      borderRadius: 4,
      marginBottom: 20
    })
  }
})
