import React from 'react'
import {
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  StyleSheet
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../components/Text'
import DetailStrategie from '../../components/DetailStrategie'
import Badge from '../../components/Badge'
import variables from '../../assets/stylesheets/variables'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/Ionicons'
import { showPicker } from '../../store/actions/pickerActions'
import {
  selectRecommandation,
  objectifsGlycemiques,
  strategieTherapeutique
} from '../../store/actions/recommandationActions'
import { adaeasd2022, adakdigo2022, sfd2023 } from '../../config/recommandations'
import { showModal } from '../../store/actions/modalActions'
import { BannerHba1c } from '../../components/BannerHba1c'

const dimensions = Dimensions.get('window')

class StrategieTherapeutique extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    enableScroll: false
  }

  onPressRecommandation = () => {
    const choices = [
      sfd2023,
      adaeasd2022
    ]
    const hasRenalDisease = this.props.patient.complications.some(i => i.id === 3)
    if (hasRenalDisease) {
      choices.push(adakdigo2022)
    }
    this.props.showPicker('Choix de la recommandation', choices, this.props.recommandation.id, 'choice', this.onRecommandationSelected)
  }

  onRecommandationSelected = (id) => {
    const choices = [
      sfd2023,
      adaeasd2022
    ]
    const hasRenalDisease = this.props.patient.complications.some(i => i.id === 3)
    if (hasRenalDisease) {
      choices.push(adakdigo2022)
    }
    const selectedRecommandation = choices.find(c => c.id === id)
    this.props.refreshRecommandation(selectedRecommandation)
    this.props.refreshObjectifsGlycemiques()
    this.props.refreshStrategieTherapeutique()
  }

  formatLabel = (text) => {
    const components = {
      DetailStrategie: DetailStrategie
    }
    if (typeof text === 'string') {
      const regex = new RegExp(/\<(\w*)\s?.*?>([A-zÀ-ÿ|\s]*)\<\/\w*>/g)
      if (regex.test(text)) {
        const matches = [...text.matchAll(/\<(\w*)\s?.*?>([A-zÀ-ÿ|\s]*)\<\/\w*>/g)]
        const match = matches[0]
        const formattedLabel = []
        const splittedText = text.split(match[0])
        const Component = components[match[1]]
        let componentProps = null

        if (/props=\{(.*)\}/g.test(match[0])) { // check for props
          const matchProps = [...match[0].matchAll(/props=\{(.*)\}/g)]
          componentProps = JSON.parse(`{${matchProps[0][1]}}`)
        }
        formattedLabel.push(...[splittedText[0], <Component {...componentProps}>{match[2]}</Component>])
        if (splittedText[1]) {
          formattedLabel.push(...this.formatLabel(splittedText[1]))
        }
        return formattedLabel
      }
    }
    return text
  }

  getItemTitle = (item, index) => {
    const hasInfo = !!item.info
    const customStyle = item.style || {}
    return (
      <View style={styles.flexRow}>
        <Badge value={index + 1}
               style={{ marginLeft: -14, marginRight: 16 }} />
        <Text style={{ ...styles.itemContent, ...customStyle }}>
          {this.formatLabel(item.label, { style: { ...styles.itemContent, maxWidth: '100%', ...customStyle }})}
        </Text>
        {hasInfo &&
          <TouchableOpacity style={{ paddingHorizontal: 10, alignSelf: 'center' }}
                            activeOpacity={0.8}
                            onPress={() => this.props.showModal('', 'tooltip', { text: item.info })}>
            <Icon name="ios-information-circle-outline"
                  size={24}
                  color={variables.colorMidGray} />
          </TouchableOpacity>
        }
      </View>
    )
  }

  getItemChild = (item) => {
    let customStyle = {}
    let choiceLabel = ''
    if (item.choice === 2) {
      customStyle = styles.itemContentSecond
      choiceLabel = '(second choix)'
    } else if (item.choice === 'non_privilegie') {
      customStyle = styles.itemContentSecond
      choiceLabel = '(choix non privilégié)'
    } else if (item.choice === 3) {
      customStyle = styles.itemContentThird
      choiceLabel = '(troisème choix)'
    } else if (!item.choice) {
      customStyle = styles.itemContentSecond
    }
    const hasInfo = !!item.info
    return (
      <View style={{ ...styles.flexRow, marginTop: 10 }}>
        <Text style={customStyle}>
          {item.label} {choiceLabel}
        </Text>
        {hasInfo &&
          <TouchableOpacity style={{ paddingHorizontal: 10 }}
                            activeOpacity={0.8}
                            onPress={() => this.props.showModal('', 'tooltip', { text: item.info })}>
            <Icon name="ios-information-circle-outline"
                  size={24}
                  color={variables.colorMidGray} />
          </TouchableOpacity>
        }
      </View>
    )
  }

  objectifNonAtteint (nextLineIfLabel) {
    return (
      <Text style={styles.objectifNonAtteint}>
        Si objectif non atteint {nextLineIfLabel ? `${nextLineIfLabel} ` : ''}...
      </Text>
    )
  }

  render() {
    const formattedView = []
    this.props.strategieTherapeutique.forEach((item, index) => {
      formattedView.push(this.getItemTitle(item, index))
      if (item.children && item.children.length) {
        item.children.forEach(child => {
          formattedView.push(this.getItemChild(child))
        })
      }
      if (index < this.props.strategieTherapeutique.length - 1) {
        formattedView.push(this.objectifNonAtteint(item.nextLineIfLabel))
      }
    })
    return (
      <React.Fragment>
        <SafeAreaView style={{ position: 'absolute', zIndex: 3, width: '100%' }}>
          <View style={styles.headerContainer}>
            <View style={{ position: 'relative', width: '100%' }}>
              <TouchableOpacity style={{ marginTop: 4, position: 'absolute', left: 5, zIndex: 3, paddingLeft: 10, paddingRight: 10 }}
                                activeOpacity={0.8}
                                onPress={() => this.props.navigation.goBack()}>
                <Icon style={{ marginTop: 1 }}
                      name="chevron-back"
                      size={28}
                      color="white" />
              </TouchableOpacity>
              <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 26, color: 'white', letterSpacing: -1, textAlign: 'center' }}>
                Traitement de la glycémie
              </Text>
              <TouchableOpacity style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 5, paddingVertical: 5 }}
                                activeOpacity={0.8}
                                onPress={this.onPressRecommandation}>
                <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 14, color: 'white' }}>
                  Selon recommandations {this.props.recommandation.label}
                </Text>
                <Icon style={{ marginLeft: 5, marginTop: 3 }}
                      name="caret-down"
                      color="white"
                      size={14} />
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
        <View style={{ height: '100%' }}>
          <LinearGradient style={{ zIndex: 1, height: '40%' }}
            colors={['#007BEE', '#0069CB']}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 0 }} />
          <View style={{ height: '60%', backgroundColor: '#004598' }}>
            <LinearGradient style={{ height: dimensions.height*0.2, position: 'absolute', bottom: 0, width: '100%' }}
                            colors={variables.bgGradient} />
          </View>
          <View style={styles.bodyContainer}>
            <View style={{ flex: 0.12 }} />
            <View style={{ flex: 0.88, display: 'flex', justifyContent: 'center' }}>
              <View style={styles.slide}>
                <ScrollView contentContainerStyle={{ paddingVertical: 20, paddingLeft: 16, paddingRight: 16 }}
                            alwaysBounceVertical={false}>
                  <BannerHba1c objectifsGlycemiques={this.props.objectifsGlycemiques} />
                  <View style={styles.body}>
                    {formattedView}
                  </View>
                </ScrollView>
                <View style={styles.footer}>
                  <Text style={styles.footerText}>
                    NB : la prise en charge du diabète de type 2 a pour base la mise en œuvre des mesures hygiénodiététiques. Le clinicien doit s'assurer que les stratégies proposées correspondent aux conditions de remboursement.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    marginTop: 12
  },
  bodyContainer: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  slide: {
    zIndex: 1000,
    width: dimensions.width*0.94,
    height: dimensions.height*0.75,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.07)',
    shadowOffset: {
      width: 0,
      height: 10
    },
    shadowOpacity: 1,
    shadowRadius: 25,
    elevation: 5
  },
  itemTitle: {
    fontSize: 16
  },
  itemContent: {
    marginTop: 1,
    fontSize: 15.5,
    lineHeight: 22,
    maxWidth: '87%',
    color: variables.colorDarkGray,
    fontFamily: 'OpenSans-Semibold'
  },
  itemContentSecond: {
    marginLeft: 30,
    fontSize: 14.5,
    lineHeight: 22,
    maxWidth: '87%',
    color: variables.colorDarkGray
  },

  itemContentThird: {
    marginLeft: 30,
    marginTop: 5,
    fontSize: 13.5,
    lineHeight: 22,
    maxWidth: '87%',
    color: variables.colorDarkGray
  },
  objectifNonAtteint: {
    marginVertical: 22,
    color: variables.colorShinyGray,
    fontFamily: 'OpenSans-Light',
    fontSize: 15,
    marginLeft: 30
  },
  body: {
    borderLeftColor: variables.borderColor,
    borderLeftWidth: 1,
    marginHorizontal: 10
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    paddingHorizontal: 20,
    paddingVertical: 20,
    shadowColor: 'white',
    shadowOffset: {
      width: 0,
      height: -10
    },
    shadowOpacity: 1,
    shadowRadius: 5
  },
  footerText: {
    fontSize: 11,
    textAlign: 'center',
    color: variables.colorMidGray
  },
  flexRow: {
    width: '87%',
    display: 'flex',
    flexDirection: 'row'
  },
  shortNote: {
    color: 'white',
    fontSize: 12,
    marginTop: 5
  }
})

const mapDispatchToProps = (dispatch) => ({
  showPicker: (title, choices = [], value, childView, method, options = {}) => {
    dispatch(showPicker(title, choices, value, childView, method, options))
  },
  showModal: (title, childView, dataModel = {}, method = null) => {
    dispatch(showModal(title, childView, dataModel, method))
  },
  refreshRecommandation: (recommandation) => {
    dispatch(selectRecommandation(recommandation))
  },
  refreshObjectifsGlycemiques: () => {
    dispatch(objectifsGlycemiques())
  },
  refreshStrategieTherapeutique: () => {
    dispatch(strategieTherapeutique())
  }
})

const mapStateToProps = (state) => ({
  patient: state.patient,
  recommandation: state.recommandation.recommandation,
  objectifsGlycemiques: state.recommandation.objectifsGlycemiques,
  strategieTherapeutique: state.recommandation.strategieTherapeutique
})

export default connect(mapStateToProps, mapDispatchToProps)(StrategieTherapeutique)

// Vérifier qu'on rentre pas dans le cas MRC si on coche MRC mais qu'on a pas albuminurie ni dfg < 60
// Lister les champs qui ont été ajoutés entre temps
