import React from 'react'
import {
  View,
  TouchableHighlight,
  StyleSheet,
  ScrollView,
  TouchableOpacity
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../../components/Text'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/Ionicons'
import variables from '../../../assets/stylesheets/variables'
import { openWebBrowser } from '../../../utils'
import * as recommandations from '../../../config/recommandations'
import LottieContainer from '../../../components/LottieContainer'

import baseStyle from '../../../assets/stylesheets/layouts/base'

class LiensUtiles extends React.Component {
  constructor() {
    super()
    this.liens = [
      {
        label: 'Prise de position de la Société Francophone du Diabète (SFD) sur les stratégies d\'utilisation des traitements anti-hyperglycémiants dans le diabète de type 2',
        date: 'Décembre 2023',
        url: 'https://www.sfdiabete.org/sites/www.sfdiabete.org/files/files/ressources/1-s2.0-s1957255723002298-main.pdf',
        recommandation: recommandations.sfd2023
      },
      {
        label: 'ESC Guidelines for the management of cardiovascular disease in patients with diabetes',
        date: 'Août 2023',
        url: 'https://academic.oup.com/eurheartj/article/44/39/4043/7238227',
        recommandation: recommandations.esc2023
      },
      {
        label: 'Glycemic Targets: Standards of Care in Diabetes - 2023 (ADA)',
        date: 'Décembre 2022',
        url: 'https://diabetesjournals.org/care/article-pdf/46/Supplement_1/S97/693609/dc23s006.pdf',
        recommandation: recommandations.ada2023
      },
      {
        label: 'Older Adults: Standards of Care in Diabetes - 2023 (ADA)',
        date: 'Décembre 2022',
        url: 'https://diabetesjournals.org/care/article-pdf/46/Supplement_1/S216/693585/dc23s013.pdf',
        recommandation: recommandations.ada2023
      },
      {
        label: 'Management of Hyperglycemia in Type 2 Diabetes, 2022. A Consensus Report by the American Diabetes Association (ADA) and the European Association for the Study of Diabetes (EASD)',
        date: 'Septembre 2022',
        url: 'https://diabetesjournals.org/care/article-pdf/45/11/2753/691567/dci220034.pdf',
        recommandation: recommandations.adaeasd2022
      },
      {
        label: 'Diabetes Management in Chronic Kidney Disease: A Consensus Report by the American Diabetes Association (ADA) and Kidney Disease: Improving Global Outcomes (KDIGO)',
        date: 'Juin 2022',
        url: 'https://kdigo.org/wp-content/uploads/2018/03/ADA-KDIGO-Consensus-Report-Diabetes-CKD-Diabetes-Care-2022.pdf',
        recommandation: recommandations.adakdigo2022
      },
      /*
      {
        label: 'ADA - Glycemic Targets: Standards of Medical Care in Diabetes',
        date: 'Décembre 2021',
        url: 'https://diabetesjournals.org/care/article/45/Supplement_1/S83/138927/6-Glycemic-Targets-Standards-of-Medical-Care-in'
      },
      {
        label: 'ADA - Pharmacologic Approaches to Glycemic Treatment: Standards of Medical Care in Diabetes',
        date: 'Décembre 2021',
        url: 'https://diabetesjournals.org/care/article/45/Supplement_1/S125/138908/9-Pharmacologic-Approaches-to-Glycemic-Treatment'
      },
      {
        label: 'ADA - Older Adults: Standards of Medical Care in Diabetes',
        date: 'Décembre 2021',
        url: 'https://diabetesjournals.org/care/article/45/Supplement_1/S195/138920/13-Older-Adults-Standards-of-Medical-Care-in'
      },
       */
      {
        label: 'ESC Guidelines on cardiovascular disease prevention in clinical practice',
        date: 'Août 2021',
        url: 'https://academic.oup.com/eurheartj/article/42/34/3227/6358713',
        recommandation: recommandations.esc2021
      },
      {
        label: 'Évaluation du risque et dépistage de la maladie coronaire chez le patient diabetique asymptomatique. Consensus de la société française de cardiologie et de la société francophone de diabétologie',
        date: 'Mars 2021',
        url: 'https://www.sfdiabete.org/sites/www.sfdiabete.org/files/files/ressources/position_paper_sfc_sfd_march_2021.pdf',
        recommandation: recommandations.sfdsfc2021
      },
      {
        label: '2019 Update to: Management of Hyperglycemia in Type 2 Diabetes, 2018. A Consensus Report by the American Diabetes Association (ADA) and the European Association for the Study of Diabetes (EASD)',
        date: 'Novembre 2019',
        url: 'https://care.diabetesjournals.org/content/diacare/early/2019/12/18/dci19-0066.full.pdf',
        recommandation: recommandations.ada2019
      },
      {
        label: 'ESC Guidelines on diabetes, pre-diabetes and cardiovascular diseases',
        date: 'Septembre 2019',
        url: 'https://www.escardio.org/static-file/Escardio/Guidelines/Publications/_Slide%20Set/2019%20Diabetes%20Slide-set%20for%20web.pptx',
        recommandation: recommandations.esc2019
      }
    ]
  }

  goToLink = (url) => {
    openWebBrowser(url)
  }

  render() {
    const isInTab = this.props.route.name === 'HomeLiensUtiles'

    return (
      <View style={baseStyle.baseLayout}>
        <LinearGradient style={baseStyle.contentSection}
                        colors={variables.bgGradient}>
          <ScrollView style={styles.body}
                      contentContainerStyle={styles.contentContainerStyle}
                      alwaysBounceVertical={false}>
            {!isInTab &&
              <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 32 }}>
                <TouchableOpacity activeOpacity={0.8}
                                  style={{ padding: 10 }}
                                  onPress={() => this.props.navigation.goBack()}>
                  <Icon style={{ marginTop: 2 }}
                        name="chevron-back"
                        size={24}
                        color={variables.colorDarkGray} />
                </TouchableOpacity>
                <Text style={{
                  fontSize: 18,
                  fontFamily: variables.fontFamily.semiBold
                }}>Liens utiles</Text>
              </View>
            }
            {this.liens.map((item, index) => (
              <TouchableHighlight key={index}
                                  style={styles.itemContainer}
                                  underlayColor={variables.colorHoverItem}
                                  activeOpacity={0.9}
                                  onPress={() => this.goToLink(item.url)}>
                <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 13 }}>
                      {item.label}
                    </Text>
                    <Text style={{ marginTop: 10, fontSize: 12, fontFamily: 'OpenSans-Semibold' }}>
                      {item.recommandation.label} {item.recommandation.flag}  -  {item.date}
                    </Text>
                  </View>
                  <Icon style={{ marginLeft: 20 }}
                        name="chevron-forward"
                        color={variables.primaryColor}
                        size={18} />
                </View>
              </TouchableHighlight>
            ))}
          </ScrollView>
        </LinearGradient>
        <LottieContainer primary
                         lottieAnimation={require('../../../assets/lottie/data-analysis.json')} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  body: {
    zIndex: 1000,
    width: '100%',
    height: '100%',
    paddingHorizontal: 10
  },
  contentContainerStyle: {
    paddingVertical: 15
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOpacity: 1,
    shadowRadius: 16,
    marginBottom: 14
  }
})

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(LiensUtiles)
