export function showPicker (title, choices = [], value, childView, method, options = {}) {
  return {
    type: 'SHOW_PICKER',
    title,
    choices,
    value,
    childView,
    method,
    options
  }
}

export function hidePicker () {
  return {
    type: 'HIDE_PICKER'
  }
}
