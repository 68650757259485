import React, { Fragment } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import Text from '../../../components/Text'
import variables from '../../../assets/stylesheets/variables'
import LinearGradient from 'react-native-linear-gradient'
import { BannerHba1c } from '../../../components/BannerHba1c'

const cardStyle = {
  padding: 20,
  backgroundColor: 'white',
  borderRadius: 8,
  marginBottom: 20,
  shadowColor: 'rgba(0, 0, 0, 0.07)',
  shadowOffset: {
    width: 0,
    height: 10
  },
  shadowOpacity: 1,
  shadowRadius: 25,
  elevation: 5
}

const Divider = () => (
  <View style={{ width: '100%', height: StyleSheet.hairlineWidth, backgroundColor: variables.borderColor, marginVertical: 10 }} />
)

const row = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingVertical: 10
}

const Card = ({ children, title, subtitle }) => {
  return <View style={cardStyle}>
    {title && <Text style={{ fontFamily: variables.fontFamily.semiBold, fontSize: 16 }}>{title}</Text>}
    {subtitle && <Text style={{ fontFamily: variables.fontFamily.light, fontSize: 13, color: variables.colorMidGray }}>{subtitle}</Text>}
    {(title || subtitle) && <Divider />}
    {children}
  </View>
}

const styles = StyleSheet.create({
  group: {
    borderRadius: 5, paddingHorizontal: 15, paddingVertical: 10
  },
  label: {
    fontSize: 14
  },
  value: {
    fontSize: 14,
    color: variables.primaryColor,
    fontFamily: variables.fontFamily.semiBold
  }
})

const Table = ({ tir }) => (
  <>
    <View style={{ ...styles.group, backgroundColor: 'rgba(232, 238, 245, 0.5)', marginBottom: 10 }}>
      <View style={row}>
        <Text style={styles.label}>{tir[0].label}</Text>
        <Text style={styles.value}>{tir[0].value}</Text>
      </View>
    </View>
    <View style={{ ...styles.group, backgroundColor: 'rgba(232, 238, 245, 0.8)', marginBottom: 10 }}>
      <View style={row}>
        <Text style={styles.label}>{tir[1][0].label}</Text>
        <Text style={styles.value}>{tir[1][0].value}</Text>
      </View>
      <View style={row}>
        <Text style={styles.label}>{tir[1][1].label}</Text>
        <Text style={styles.value}>{tir[1][1].value}</Text>
      </View>
    </View>
    <View style={{ ...styles.group, backgroundColor: 'rgba(232, 238, 245, 1)' }}>
      <View style={row}>
        <Text style={styles.label}>{tir[2][0].label}</Text>
        <Text style={styles.value}>{tir[2][0].value}</Text>
      </View>
      <View style={row}>
        <Text style={styles.label}>{tir[2][1].label}</Text>
        <Text style={styles.value}>{tir[2][1].value}</Text>
      </View>
    </View>
  </>
)

export const Sfd2023 = ({ objectifs }) => {
  return (
    <LinearGradient style={{ flex: 1 }} colors={variables.bgGradient}>
      <ScrollView alwaysBounceVertical={false}
                  contentContainerStyle={{ padding: 20 }}
                  showsVerticalScrollIndicator={false}>
        <Card>
          <BannerHba1c objectifsGlycemiques={objectifs} />
          <Text style={{ fontSize: 15 }}>
            {objectifs.note.join('\n\n')}
          </Text>
          <Text style={{
            fontSize: 11,
            marginTop: 20,
            color: variables.colorMidGray }}>
            NB : L'objectif d'HbA1c doit être individualisé selon le profil du patient et co-décidé avec lui, et peut donc évoluer au fil du temps.
          </Text>
        </Card>
        <Card title="Temps dans la cible" subtitle="Si patient sous insuline">
          <Table tir={objectifs.tir} />
        </Card>
      </ScrollView>
    </LinearGradient>
  )
}
