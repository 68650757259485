import React from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { StatusBar } from 'react-native'
import { Provider } from 'react-redux'
import store from './store/store'
import RootContainer from './components/RootContainer'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

console.disableYellowBox = true

const App: () => React$Node = () => {
  if (process.env.NODE_ENV === 'production') {
    const firebaseConfig = {
      apiKey: 'AIzaSyBR0H40kr9v2J9-p9W8WRfLWdH96lVfCyo',
      authDomain: 'diabguide.firebaseapp.com',
      projectId: 'diabguide',
      storageBucket: 'diabguide.appspot.com',
      messagingSenderId: '1051303870052',
      appId: '1:1051303870052:web:28c66ddd9d0db0d00e6971',
      measurementId: 'G-KLE0H7TFF5'
    }

    const app = initializeApp(firebaseConfig)
    getAnalytics(app)
  }

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <StatusBar translucent={true}
                   backgroundColor={'rgba(0, 0, 0, 0)'} />
        <RootContainer />
      </Provider>
    </SafeAreaProvider>
  )
}

export default App
