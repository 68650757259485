import store from '../../../../store'
import { texts } from '../texts'

export function StrategieMaladieAtheromateuse () {
  const state = store.getState()
  const patientAge = state.patient.age
  const hasRenalDisease = state.patient.complications.some(i => i.id === 3)
  const dfg = state.patient.dfg
  const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
  const fevgMoreThan40 = state.patient.ejection === 1
  const carefulGLP1 = hasInsuffisanceCardiaque && !fevgMoreThan40 // à utiliser avec précaution
  const result = []
  let label = null
  let info = null
  if (hasRenalDisease && dfg < 30) {
    label = state.patient.metformine ? 'Arrêter metformine' : 'Ne pas utiliser metformine'
    info = texts.metformine_renal_issues
  } else if (!hasRenalDisease || dfg >= 30) {
    label = state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine'
    if (dfg < 45) {
      label += ' à 1g/jour maximum'
    } else if (dfg < 60) {
      label += ' à 2g/jour maximum'
    }
    info = hasInsuffisanceCardiaque ? texts.metformine_insuffisance_cardiaque : ''
  }
  if (hasInsuffisanceCardiaque) {
    if (dfg >= 25) {
      label += ' et ajouter iSGLT2 quelle que soit l\'HbA1c'
    } else if (dfg >= 20) { // Entre 20 et 24
      label += ' et ajouter empagliflozine quelle que soit l\'HbA1c'
      info = "Dans le cas d'un DFG entre 20 et 24 mL/min/1,73 et d'une insuffisance cardiaque, seul l'empagliflozine est utilisable à 10mg par jour."
    } else if (dfg >= 15) {
      label += ' et ajouter AR GLP-1 quelle que soit l\'HbA1c'
      info += carefulGLP1 ? texts.careful_aglp1_fe : ''
    }

    if (patientAge >= 75) {
      label += ' (si envisageable pour ce patient âgé)'
      info += texts.isglt2_ages
    }

    result.push({
      label: label,
      info: info,
      children: dfg >= 20 ? [ // Si DFG entre 15 et 20, il sera dans le texte principal, pas besoin de le rajouter en second choix. par contre, s'il est supérieur à 20, on proposera ISGLT2 à la place.
        {
          label: 'Ou AR GLP-1 si intolérance ou contre-indication aux iSGLT2',
          choice: 2,
          info: carefulGLP1 ? `${texts.careful_aglp1_fe}\n\n${texts.aglp1_benefice_cardiaque}` : texts.aglp1_benefice_cardiaque
        }
      ] : null
    })
    info = null // reset info
  } else if (hasRenalDisease && dfg < 60) {
    if (dfg >= 30) {
      label += ' et ajouter iSGLT2 ou AR GLP-1 quelle que soit l\'HbA1c'
      info += carefulGLP1 ? texts.careful_aglp1_fe : ''
    } else if (dfg >= 25) { // Entre 25 et 29
      label += ' et ajouter dapagliflozine quelle que soit l\'HbA1c'
      info = "Dans le cas d'un DFG entre 25 et 29 mL/min/1,73, seule la dapagliflozine est utilisable à 10mg par jour."
    } else if (dfg >= 15) {
      label += ' et ajouter AR GLP-1 quelle que soit l\'HbA1c'
      info += carefulGLP1 ? texts.careful_aglp1_fe : ''
    }
    if (patientAge >= 75) {
      label += ' (si envisageable pour ce patient âgé)'
      info += texts.isglt2_ages
    }

    result.push({
      label: label,
      info: info
    })
    info = null // reset info
  } else {
    label += ' et ajouter iSGLT2 ou AR GLP-1 quelle que soit l\'HbA1c'
    info = texts.aglp1_benefice_cardiaque
    if (patientAge >= 75) {
      label += ' (si envisageable pour ce patient âgé)'
      info += texts.isglt2_ages
    }
    result.push({
      label: label,
      info: info
    })
    info = null // reset info
  }
  if (dfg >= 60) {
    label = "L'ensemble des autres médicaments antidiabétiques oraux ou injectables peuvent être envisagés dans le respect des éventuelles contre-indications."
    info = "L'insuline, le répaglinide et les sulfamides peuvent être envisagés en faisant attention au risque hypoglycémique."
  } else if (dfg >= 30) { // moins de 60
    if (patientAge >= 75) {
      label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine et la sitagliptine peuvent également être utilisés à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    } else {
      label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine et la sitagliptine peuvent également être utilisés à dose de 50 mg/jour (sans association avec un AR GLP-1). Le répaglinide, le glimépiride, le gliclazide et l'insuline peuvent être utilisés en faisant attention au risque hypoglycémique."
    }
    info = null
  } else if (hasInsuffisanceCardiaque ? dfg >= 20 : dfg >= 25) {// On propose un iSGLT2 en premier, il faut donc rajouter le AR GLP-1 dans la phrase
    label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine peut également être utilisée à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    info = null
  } else if (dfg >= 15) {
    label = "La vildagliptine peut être utilisée à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    info = null
  } else {
    label = "La vildagliptine peut être utilisée à dose de 50 mg/jour. L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    info = null
  }

  result.push({
    label: label,
    info: info,
    style: {
      fontSize: 14.5
    }
  })
  return result
}
