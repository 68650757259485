const config = {
  url: 'https://wise-staging.semeia.io/api/v1/diabguide-recommendation-computed-journal-events',
  token: 'eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODc3NzI3OTIsImV4cCI6MTg5MzQ1NjAwMCwic3ViIjo1MDA5MCwidXVpZCI6IjQ5MmUzZmY4LWMxN2ItNDhmMi1iMGMyLTQ5ODZhMmJjM2M1ZiIsImNsYXNzIjoiTWVkaWMifQ.8n45ny0ug7zZzWw_MDzvj3hTHC6q4U1Npjjs6BcxF4E'
}

if (process.env.NODE_ENV === 'production') {
  config.url = 'https://wise.semeia.io/api/v1/diabguide-recommendation-computed-journal-events'
  config.token = 'eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODkyNDAyNDIsImV4cCI6MTc4MzkzNDY0Miwic3ViIjo1MDIzOCwidXVpZCI6bnVsbCwiY2xhc3MiOiJNZWRpYyJ9.2BojjAU7B40SOPpFLQr-L3tjnr4WCAhMoVIQ8QGxtzM'
}
export const sendPayload = (payload) => {
  return fetch(config.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${config.token}`
    },
    body: JSON.stringify(payload)
  })
}
