import variables from '../variables'

const styles = {
  contentContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 40,
    maxWidth: 740,
    marginHorizontal: 'auto'
  },
  stepTitle: {
    flexBasis: '100%',
    marginLeft: 10,
    marginBottom: 36,
    fontSize: 18,
    fontFamily: variables.fontFamily.semiBold
  },
  imcInfoBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 18,
    marginLeft: 10,
    marginTop: 30
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#f5f9ff',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    shadowColor: variables.colorMidGray,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5
  },
  footerContainer: {
    maxWidth: 720,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}

export default styles
