import React, { useState } from 'react'
import Text from './Text'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'
import variables from '../assets/stylesheets/variables'
import Dropdown from './Dropdown'

const ChoiceInput = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
    props.toggleActiveInput(!dropdownOpen)
  }

  let text = null
  if (props.value !== null) {
    const label = props.choices.find(item => item.id === props.value).label
    text = <Text style={styles.inputTextAnswer}>{label}</Text>
  } else {
    text = <Text style={styles.inputTextSelect}>Selectionner</Text>
  }

  return (
    <View style={{ width: '100%', zIndex: 1 }}>
      <TouchableOpacity activeOpacity={0.8}
                        style={styles.touchableOpacityStyle}
                        onPress={toggleDropdown}>
        {text}
        <Icon style={{ marginRight: dropdownOpen ? -2 : 0, marginLeft: 2, marginTop: 3 }}
              name="chevron-down"
              color={variables.primaryColor}
              size={18}/>
      </TouchableOpacity>
      {dropdownOpen &&
        <Dropdown {...props}
                  closeDropdown={toggleDropdown} />
      }
    </View>
  )
}

const styles = StyleSheet.create({
  inputTextAnswer: {
    fontFamily: variables.fontFamily.regular,
    fontSize: 16
  },
  inputTextSelect: {
    fontFamily: variables.fontFamily.regular,
    fontSize: 16,
    color: variables.primaryColor
  },
  touchableOpacityStyle: {
    height: 60,
    width: '100%',
    paddingLeft: 14,
    paddingRight: 18,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default ChoiceInput
