import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import variables from '../assets/stylesheets/variables'

class Badge extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const customStyle = this.props.style || {}
    return (
      <View style={{ ...styles.badge, ...customStyle }}>
        <Text style={styles.value}>
          {this.props.value}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  badge: {
    width: 28,
    height: 28,
    backgroundColor: variables.stepColor,
    borderRadius: 14,
    borderColor: 'white',
    borderWidth: 4,
    shadowColor: '#BAC2D2',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowOpacity: 1,
    shadowRadius: 5,
    elevation: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  value: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    color: 'white'
  }
})

export default Badge
