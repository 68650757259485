import store from '../../store'
import { sfd2023, sfd2021, adaeasd2022, adakdigo2022 } from '../../../config/recommandations'
import { imc } from '../../../utils'
import { StrategieInsuffisanceRenale as StrategieInsuffisanceRenale2021 } from './strategies/sfd2021/StrategieInsuffisanceRenale'
import { StrategieInsuffisanceRenale as StrategieInsuffisanceRenale2023 } from './strategies/sfd2023/StrategieInsuffisanceRenale'
import { StrategieInsuffisanceCardiaque as StrategieInsuffisanceCardiaque2023 } from './strategies/sfd2023/StrategieInsuffisanceCardiaque'
import { StrategieMaladieAtheromateuse as StrategieMaladieAtheromateuse2023 } from './strategies/sfd2023/StrategieMaladieAtheromateuse'
import { StrategieInsuffisanceCardiaque as StrategieInsuffisanceCardiaque2021 } from './strategies/sfd2021/StrategieInsuffisanceCardiaque'
import { StrategieMaladieAtheromateuse as StrategieMaladieAtheromateuse2021 } from './strategies/sfd2021/StrategieMaladieAtheromateuse'
import { StrategiePersonneAgee as StrategiePersonneAgee2021 } from './strategies/sfd2021/StrategiePersonneAgee'
import { StrategiePersonneAgee as StrategiePersonneAgee2023 } from './strategies/sfd2023/StrategiePersonneAgee'
import { getObjectifsGlycemiques } from '../recommandationActions'

const texts = {
  isglt2_obese: "Chez un patient indemne de pathologie cardiovasculaire ou rénale, l'association metformine + iDPP4 constitue l'option présentant le meilleur compromis entre efficacité anti-hyperglycémiante, profil de tolérance/de sécurité et coût journalier de traitement.\n\nLe choix d'un iSGLT2 ou AR GLP-1 peut être envisagé lorsque l'objectif d'HbA1c n'est pas atteint sous metformine chez le patient dont l'IMC est entre 30 et 35 kg/m2.",
  isglt2_benefice_cardio: 'En utilisant une molécule qui à prouvé son bénéfice cardiovasculaire.',
  isglt2_ages: 'Après 75 ans, l\'utilisation des AR GLP-1 et des iSGLT2 doit être réservée à une minorité de patients, idéalement après avis d\'un endocrinologue-diabétologue, compte tenu de leur rapport bénéfices-risques incertain dans ce groupe de population.',
  metformine: 'A la dose maximale tolérée et dans le respect des contre indications (notamment rénales).',
  metformine_renal_issues: 'La metformine doit être arrêtée si DFG inférieur à 30 mL/min.',
  aglp1_renal_issues: 'Tous contre indiqués en cas de DFG < 15 mL/min.',
  idpp4_avoid_saxagliptine: 'Saxagliptine à éviter.',
  aglp1: 'Ayant fait la preuve de son bénéfice cardiovasculaire : liraglutide, dulaglutide, sémaglutide avec un niveau de preuve moins avéré.'
}

function getStrategieTherapeutique () {
  const generalItem = {
    label: 'L\'ensemble des autres médicaments antidiabétiques oraux ou injectables peuvent être envisagés dans le respect des éventuelles contre-indications.',
    style: {
      fontSize: 14.5
    }
  }

  const state = store.getState()
  const patientComplications = state.patient.complications.filter(i => i.id !== 1)
  const dfg = state.patient.dfg
  const hasRenalDisease = patientComplications.some(i => i.id === 3)
  const hasAlbuminurie = hasRenalDisease && state.patient.albuminurie && state.patient.albuminurie !== 'under_30'
  const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
  const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
  const patientImc = imc(state.patient.weight, state.patient.height)
  const patientAge = state.patient.age
  const isPatientOld = patientAge >= 75

  let result = []
  if (state.recommandation.recommandation === sfd2023) {
    const generalItemSfd2023 = {
      ...generalItem,
      ...(isPatientOld && {
        info: 'Sulfamides et glinides déconseillés chez les sujets âgés.'
      })
    }
    if (isPatientOld && [2, 3].includes(state.patient.health)) { // Cas d'un patient agé fragile ou dépendant
      return StrategiePersonneAgee2023()
    }
    if (hasRenalDisease) {
      return StrategieInsuffisanceRenale2023()
    }
    if (hasInsuffisanceCardiaque) {
      return StrategieInsuffisanceCardiaque2023()
    }
    if (hasMaladieAtheromateuse) {
      return StrategieMaladieAtheromateuse2023()
    }
    result.push({
      label: state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine',
      info: texts.metformine
    })
    const deltaHba1c = parseFloat(state.patient.last_hba1c.replace(',', '.')) - parseFloat(getObjectifsGlycemiques().hba.replace(',', '.'))
    if (deltaHba1c >= 1) {
      result.push({
        label: 'privilégier AR GLP-1 ou AR GIP/GLP-1 (par ordre croissant d\'efficacité sur l\'HbA1c)',
        ...(isPatientOld && {
          info: "Vigilance sur l'état nutritionnel avec les AR GLP-1 et surtout avec les AR GIP/GLP-1 (risque de sarcopénie et de dénutrition, expérience limitée)"
        })
      })
      result.push(generalItemSfd2023)
      return result
    }
    if (state.patient.isHighRiskCV === true) { // === true parce que ca peut etre 'unknown' qui est truthy
      result.push({
        label: 'privilégier privilégier iSGLT2 ou AR GLP-1',
        ...(isPatientOld && {
          info: "Vigilance sur l'état nutritionnel avec les AR GLP-1 (risque de sarcopénie et de dénutrition, expérience limitée)"
        })
      })
      result.push(generalItemSfd2023)
      return result
    }
    if (patientImc > 30) { // Cas patient obèse
      result.push({
        label: 'privilégier iSGLT2 ou AR GLP-1 ou AR GIP/GLP-1 (par ordre croissant d\'efficacité sur le poids)',
        ...(isPatientOld && {
          info: "Vigilance sur l'état nutritionnel avec les AR GLP-1 et surtout avec les AR GIP/GLP-1 (risque de sarcopénie et de dénutrition, expérience limitée)"
        })
      })
      result.push({
        label: "Dans le cas d'un patient atteint d'obésité, associer metformine, iSGLT2 et au choix GLP1 ou AR GIP/GLP-1. Discuter au cas par cas d'une chirurgie métabolique."
      })
      return result
    }
    if (patientImc > (isPatientOld ? 27 : 25)) { // Cas patient en surpoids. IMC 27 pour le patient âgé, 25 autrement
      result.push({
        label: 'privilégier iSGLT2 ou AR GLP-1 ou AR GIP/GLP-1 (par ordre croissant d\'efficacité sur l\'HbA1c et le poids)',
        ...(isPatientOld && {
          info: "Vigilance sur l'état nutritionnel avec les AR GLP-1 et surtout avec les AR GIP/GLP-1 (risque de sarcopénie et de dénutrition, expérience limitée)"
        })
      })
      result.push(generalItemSfd2023)
      return result
    }
    result.push({
      label: 'Ajouter iSGLT2, AR GLP-1 ou iDPP4',
      ...(isPatientOld && {
        info: "Vigilance sur l'état nutritionnel avec les AR GLP-1 (risque de sarcopénie et de dénutrition, expérience limitée)"
      }),
      ...(!isPatientOld && {
        children: [
          {
            label: 'Ou sulfamide',
            choice: 'non_privilegie'
          }
        ]
      })
    })
    result.push(generalItemSfd2023)
    return result
  } else if (state.recommandation.recommandation === sfd2021) {
    if (patientAge >= 75 && state.patient.health !== 1) { // Cas d'un patient agé fragile ou dépendant
      return StrategiePersonneAgee2021()
    }
    if (hasRenalDisease) {
      return StrategieInsuffisanceRenale2021()
    }
    if (hasInsuffisanceCardiaque) {
      return StrategieInsuffisanceCardiaque2021()
    }
    if (hasMaladieAtheromateuse) {
      return StrategieMaladieAtheromateuse2021()
    }
    if (patientAge >= 75) { // Cas d'un patient agé en bonne santé
      return StrategiePersonneAgee2021()
    }
    result.push({
      label: state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine',
      info: texts.metformine
    })
    if (!patientComplications.length && patientImc < 30) { // Aucun soucis particulier
      result.push({
        label: 'Ajouter iSGLT2, AR GLP-1 ou iDPP4',
        children: [
          {
            label: 'Ou sulfamide',
            choice: 'non_privilegie'
          }
        ]
      })
      result.push(generalItem)
    } else if (patientImc >= 30) {
      result.push({
        label: 'Ajouter AR GLP-1 ou iSGLT2',
        children: [
          {
            label: 'Ou iDPP4 ou sulfamide',
            choice: 'non_privilegie'
          }
        ]
      })
      result.push(generalItem)
    } else {
      result.push({
        label: 'Ajouter iSGLT2, AR GLP-1 ou iDPP4',
        children: [
          {
            label: 'Ou sulfamide',
            choice: 2
          }
        ]
      })
      result.push(generalItem)
    }
    return result
  } else if (
    state.recommandation.recommandation === adaeasd2022
    || state.recommandation.recommandation === adakdigo2022
  ) {
    const hasTwoOrMoreFDR = [patientImc >= 30, state.patient.hta, state.patient.smoking === 3, hasAlbuminurie, state.patient.dyslipidemie].filter(i => i).length >= 2
    const isHighRiskCVForAdaEasd2022 = patientAge >= 55 && hasTwoOrMoreFDR
    if (hasRenalDisease) {
      let label = null
      let info = null
      if (state.patient.metformine) {
        let metforminMaxDosage = ''
        if (dfg < 45) {
          metforminMaxDosage = '(à 1g/jour maximum) '
        } else if (dfg < 60) {
          metforminMaxDosage = '(à 2g/jour maximum) '
        }
        label = dfg < 30
          ? 'Arrêter metformine et ajouter iSGLT2'
          : `Continuer metformine ${metforminMaxDosage}et ajouter iSGLT2`
        info = [texts.metformine_renal_issues].join('\n\n')
      } else {
        label = 'Ajouter iSGLT2'
        info = null
      }
      if (dfg >= 20) { // DFG supérieur ou égal à 20, possibilité de mettre un iSGLT2
        if (dfg < 25) {
          label += ' sans arrêter jusqu\'à dialyse ou transplantation' // Ici on ajoute cette info uniquement pour les DFG < 25
          label = label.replace('iSGLT2', 'iSGLT2 (empagliflozine uniquement)') // Si DFG entre 20 et 24, empagliflozine uniquement
        }
        result.push({
          label,
          info,
          children: [
            {
              label: 'Ou AR GLP-1 si intolérance ou contre-indication aux iSGLT2',
              choice: 2
            }
          ],
          nextLineIfLabel: 'et/ou risque résiduel cardio-rénal important'
        })
        result.push({
          label: 'Si patient sous iSGLT2, ajouter AR GLP-1'
        })
      } else { // DFG inférieur à 20, impossible de mettre un iSGLT2, AR-GLP1 privilégié
        if (state.patient.has_isglt2) {
          result.push({
            label: state.patient.metformine
              ? 'Arrêter metformine et poursuivre iSGLT2 sans arrêter jusqu\'à dialyse ou transplantation'
              : 'Poursuivre iSGLT2',
            info: state.patient.metformine
              ? texts.metformine_renal_issues
              : null
          })
          result.push({
            label: 'Ajouter AR GLP-1'
          })
        } else {
          result.push({
            label: state.patient.metformine
              ? 'Arrêter metformine et ajouter AR GLP-1'
              : 'Ajouter AR GLP-1',
            info: state.patient.metformine
              ? [texts.metformine_renal_issues, texts.aglp1_benefice_renal].join('\n\n')
              : texts.aglp1_benefice_renal
          })
        }
      }
      const dependingOnDFGLabel = dfg < 60 ? 'selon le DFG et ' : ''
      const labelLastItem = state.recommandation.recommandation === adaeasd2022
        ? `D'autres antidiabétiques peuvent être envisagés ${dependingOnDFGLabel}dans le respect des éventuelles contre indications, en tenant compte de leur efficacité variable sur le <DetailStrategie props={"type": "glycemie"}>contrôle de la glycémie</DetailStrategie> et <DetailStrategie props={"type": "poids"}>du poids</DetailStrategie>`
        : `D'autres antidiabétiques peuvent être envisagés ${dependingOnDFGLabel}dans le respect des éventuelles contre indications.`
      result.push({
        label: labelLastItem,
        style: {
          fontSize: 14.5
        }
      })
      // TODO renvoyer au tableau ou au texte
      return result
    } else if (hasMaladieAtheromateuse || isHighRiskCVForAdaEasd2022) {
      result.push({
        label: state.patient.metformine
          ? 'Continuer metformine et ajouter iSGLT2 ou AR GLP-1 quelle que soit l\'HbA1c'
          : 'Ajouter iSGLT2 ou AR GLP-1 quelle que soit l\'HbA1c',
        info: texts.isglt2_benefice_cardio,
        nextLineIfLabel: 'et/ou risque résiduel cardio-rénal important'
      })
      result.push({
        label: 'Si patient sous iSGLT2, ajouter AR GLP-1; si patient sous AR GLP-1, ajouter iSGLT2'
      })
      result.push({
        label: 'L\'ensemble des autres antidiabétiques peuvent être envisagés dans le respect des éventuelles contre indications et en tenant compte de leur efficacité variable sur le <DetailStrategie props={"type": "glycemie"}>contrôle de la glycémie</DetailStrategie> et <DetailStrategie props={"type": "poids"}>du poids</DetailStrategie>',
        style: {
          fontSize: 14.5
        }
      })
      return result
    } else if (hasInsuffisanceCardiaque) {
      result.push({
        label: state.patient.metformine
          ? 'Continuer metformine et ajouter iSGLT2 quelle que soit l\'HbA1c'
          : 'Ajouter iSGLT2 quelle que soit l\'HbA1c',
        info: state.patient.metformine ? texts.metformine_insuffisance_cardiaque : null
      })
      result.push({
        label: 'L\'ensemble des autres antidiabétiques peuvent être envisagés dans le respect des éventuelles contre indications et en tenant compte de leur efficacité variable sur le <DetailStrategie props={"type": "glycemie"}>contrôle de la glycémie</DetailStrategie> et <DetailStrategie props={"type": "poids"}>du poids</DetailStrategie>',
        style: {
          fontSize: 14.5
        }
      })
      return result
    } else {
      result.push({
        label: state.patient.metformine ? 'Habituellement, continuer metformine' : 'Habituellement, ajouter metformine',
        children: [
          {
            label: 'Une bitherapie peut être proposée d\'emblée chez les patients de moins de 40 ans ou présentant une HbA1c > 8,5% ou si l\'on craint une inertie thérapeutique'
          }
        ]
      })
      result.push({
        label: 'Choisir les lignes de traitements suivantes en fonction de l\'efficacité des différents antidiabétiques sur le <DetailStrategie props={"type": "glycemie"}>contrôle de la glycémie</DetailStrategie> et <DetailStrategie props={"type": "poids"}>du poids</DetailStrategie>, dans le respect des éventuelles contre indications'
      })
      if (patientImc >= 30) {
        result.push({
          label: 'Envisager la chirurgie métabolique'
        })
      }
      return result
    }
  }
}


export default getStrategieTherapeutique
