import React, { useEffect } from 'react'
import {
  Animated,
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity
} from 'react-native'
import Text from './Text'
import variables from '../assets/stylesheets/variables'
import Icon from 'react-native-vector-icons/Ionicons'
import moment from 'moment/moment'

const Dropdown = (props) => {
  const renderItem = ({ item }) => (
    <TouchableOpacity activeOpacity={0.8}
                      style={[styles.item, item.id === props.value ? styles.itemSelected : undefined]}
                      onPress={() => onPress(item)}>
      <Text style={getTextStyle(item)}>{item.label}</Text>
      {item.info &&
        <TouchableOpacity activeOpacity={0.8}
                          onPress={() => props.showModal('', item.info)}
                          style={{ width: 30, height: 30, alignItems: 'center', justifyContent: 'center' }}>
          <Icon style={{ marginTop: 4 }}
                name="ios-help-circle-outline"
                size={20}
                color={variables.colorMidGray} />
        </TouchableOpacity>
      }
    </TouchableOpacity>
  )

  const onPress = (item) => {
    props.onChange(item.id)
    props.closeDropdown()
  }
  const onCloseDropdown = () => {
    props.closeDropdown()
  }
  const getTextStyle = (item) => {
    return item.id === props.value ? styles.itemTextSeleted : styles.itemText
  }

  const formattedData = () => {
    if (props.type === 'number') {
      const result = []
      if (props.options.hasLower) {
        const minUnit = props.options.minUnit
        result.push({ label: `< ${minUnit} ${props.options.unit}`, id: minUnit - 1, value: minUnit - 1 })
      }
      for (let i = props.options.minUnit; i <= props.options.maxUnit; i = i + 1) {
        const label = props.options.unit ? `${i} ${props.options.unit}` : i
        result.push({ label, id: i, value: i })
      }
      if (props.options.hasHigher) {
        const maxUnit = props.options.maxUnit
        result.push({ label: `> ${maxUnit} ${props.options.unit}`, id: maxUnit + 1, value: maxUnit + 1 })
      }
      return result
    }
    return props.choices
  }

  const dropdownData = formattedData()
  const opacity = new Animated.Value(0)

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 150
    }).start()
  })

  const getInitialScrollIndex = () => {
    if (props.type === 'number') {
      if (props.value) {
        return dropdownData.findIndex(i => i.value === props.value)
      }
      if (props.options.initialUnits) {
        return dropdownData.findIndex(i => i.value === props.options.initialUnits)
      }
    }
    return 0
  }

  return (
    <View>
      <TouchableWithoutFeedback onPress={onCloseDropdown}>
        <View style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }} />
      </TouchableWithoutFeedback>
      <Animated.FlatList style={[styles.dropdown, { opacity }]}
                         contentContainerStyle={{ padding: 10, backgroundColor: 'white' }}
                         data={dropdownData}
                         renderItem={renderItem}
                         initialScrollIndex={getInitialScrollIndex()}
                         getItemLayout={(data, index) => (
                           { length: 44, offset: 44 * index, index }
                         )}
                         keyExtractor={item => item.id} />
    </View>
  )
}

const styles = StyleSheet.create({
  dropdown: {
    marginLeft: -1,
    marginRight: -1,
    left: 0,
    right: 0,
    position: 'absolute',
    bottom: -5,
    transform: [{ translateY: '100%' }],
    borderWidth: 1,
    borderColor: variables.colorLightGray,
    borderRadius: 4,
    backgroundColor: 'white',
    shadowColor: variables.colorMidGray,
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.07,
    shadowRadius: 20,
    maxHeight: 220
  },
  item: {
    borderRadius: 4,
    height: 44,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  itemSelected: {
    backgroundColor: variables.colorHoverItem
  },
  itemText: {
    fontSize: 16,
    fontFamily: variables.fontFamily.light
  },
  itemTextSeleted: {
    fontSize: 16,
    fontFamily: variables.fontFamily.regular
  }
})

export default Dropdown
