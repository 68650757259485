import React from 'react'
import {
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  StyleSheet
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../components/Text'
import variables from '../../assets/stylesheets/variables'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/Ionicons'
import { showPicker } from '../../store/actions/pickerActions'
import { selectRecommandation, objectifsTensionnels } from '../../store/actions/recommandationActions'
import { esc2023, hassfhta2016, sfdsfc2021 } from '../../config/recommandations'
import { showModal } from '../../store/actions/modalActions'

const dimensions = Dimensions.get('window')

class Objectifs extends React.Component {
  constructor(props) {
    super(props)
  }

  onPressRecommandation = () => {
    const choices = [
      esc2023,
      sfdsfc2021,
      hassfhta2016
    ]
    this.props.showPicker('Choix de la recommandation', choices, this.props.recommandation.id, 'choice', this.onRecommandationSelected)
  }

  onRecommandationSelected = (id) => {
    const choices = [
      sfdsfc2021,
      esc2021,
      hassfhta2016
    ]
    const selectedRecommandation = choices.find(c => c.id === id)
    this.props.refreshRecommandation(selectedRecommandation)
    this.props.refreshObjectifsTensionnels()

  }

  render() {
    return (
      <React.Fragment>
        <SafeAreaView style={{ position: 'absolute', zIndex: 3, width: '100%' }}>
          <View style={styles.headerContainer}>
            <View style={{ position: 'relative', width: '100%' }}>
              <TouchableOpacity style={{ marginTop: 4, position: 'absolute', left: 5, zIndex: 3, paddingLeft: 10, paddingRight: 10 }}
                                activeOpacity={0.8}
                                onPress={() => this.props.navigation.goBack()}>
                <Icon style={{ marginTop: 1 }}
                      name="chevron-back"
                      size={28}
                      color="white" />
              </TouchableOpacity>
              <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 26, color: 'white', letterSpacing: -1, textAlign: 'center' }}>
                Objectifs tensionnels
              </Text>
              <TouchableOpacity style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 5, paddingVertical: 5 }}
                                activeOpacity={0.8}
                                onPress={this.onPressRecommandation}>
                <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 14, color: 'white' }}>
                  Selon recommandations {this.props.recommandation.label}
                </Text>
                <Icon style={{ marginLeft: 5, marginTop: 3 }}
                      name="caret-down"
                      color="white"
                      size={14} />
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
        <View style={{ height: '100%' }}>
          <LinearGradient style={{ zIndex: 1, height: '40%', display: 'flex', justifyContent: 'flex-end' }}
            colors={['#007BEE', '#0069CB']}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 0 }}>
            <View style={[styles.resultContainer, { marginBottom: 30 }]}>
              <View style={styles.result}>
                <Text style={styles.resultNumber}>
                  {this.props.objectifsTensionnels.systolique.label}
                </Text>
                <Text style={styles.resultPercent}>mmHg</Text>
              </View>
              <Text style={styles.resultLabel}>
                SYSTOLIQUE
              </Text>
            </View>
          </LinearGradient>
          <View style={{ height: '60%', backgroundColor: '#004598' }}>
            <View style={[styles.resultContainer, { marginTop: 30 }]}>
              <View style={styles.result}>
                <Text style={styles.resultNumber}>
                  {this.props.objectifsTensionnels.diastolique.label}
                </Text>
                <Text style={styles.resultPercent}>mmHg</Text>
              </View>
              <Text style={styles.resultLabel}>
                DIASTOLIQUE
              </Text>
            </View>
            <LinearGradient style={{ height: dimensions.height*0.2, position: 'absolute', bottom: 0, width: '100%' }}
                            colors={variables.bgGradient} />
          </View>
          <View style={styles.bodyContainer}>
            <View style={{ flex: 0.5 }} />
            <View style={styles.roundContainer} />
            <View style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <View style={styles.slide}>
                <ScrollView alwaysBounceVertical={false}
                            contentContainerStyle={{ marginRight: 27, marginTop: 24, paddingBottom: 24 }}>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 14 }}>
                    <Text style={styles.itemTitle}>Objectifs de tension artérielle</Text>
                  </View>
                  <Text style={styles.itemContent}>{this.props.objectifsTensionnels.note}</Text>
                </ScrollView>
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    marginTop: 12
  },
  bodyContainer: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  roundContainer: {
    opacity: 0,
    width: 280,
    height: 285
  },
  resultContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  result: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: -10,
    marginBottom: 10
  },
  resultNumber: {
    color: 'white',
    fontSize: 50,
    fontFamily: 'OpenSans-SemiBold',
    letterSpacing: -3.2
  },
  resultPercent: {
    marginLeft: 8,
    lineHeight: 52,
    color: 'white',
    fontSize: 24,
    fontFamily: 'OpenSans-Light',
    letterSpacing: -3.2
  },
  resultLabel: {
    color: 'white',
    fontFamily: 'OpenSans-Semibold',
    fontSize: 14
  },
  slide: {
    zIndex: 1000,
    width: dimensions.width*0.85,
    minHeight: 190,
    maxHeight: dimensions.height*0.3,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 27,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    shadowColor: 'rgba(0, 0, 0, 0.07)',
    shadowOffset: {
      width: 0,
      height: 10
    },
    shadowOpacity: 1,
    shadowRadius: 25,
    elevation: 5
  },
  itemTitle: {
    fontSize: 16
  },
  itemContent: {
    fontSize: 15,
    color: variables.colorMidGray
  }
})

const mapDispatchToProps = (dispatch) => ({
  showPicker: (title, choices = [], value, childView, method, options = {}) => {
    dispatch(showPicker(title, choices, value, childView, method, options))
  },
  showModal: (title, childView) => {
    dispatch(showModal(title, childView))
  },
  refreshRecommandation: (recommandation) => {
    dispatch(selectRecommandation(recommandation))
  },
  refreshObjectifsTensionnels: () => {
    dispatch(objectifsTensionnels())
  }
})

const mapStateToProps = (state) => ({
  recommandation: state.recommandation.recommandation,
  objectifsTensionnels: state.recommandation.objectifsTensionnels
})

export default connect(mapStateToProps, mapDispatchToProps)(Objectifs)
