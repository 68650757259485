import variables from '../variables'

const styles = {
  text: {
    color: 'white',
    fontFamily: 'OpenSans-Semibold',
    fontSize: 16
  },
  textOutline: {
    color: variables.secondaryColor + 'CF'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
    maxWidth: 300,
    margin: 'auto',
    height: 45,
    backgroundColor: variables.secondaryColor,
    borderRadius: 4
  },
  buttonPrimary: {
    backgroundColor: variables.primaryColor
  },
  buttonOutline: {
    backgroundColor: variables.colorHoverItem
  }
}

export default styles
