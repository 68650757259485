export function showSidePanel (title, choices = [], value, childView, method) {
  return {
    type: 'SHOW_SIDEPANEL',
    title,
    choices,
    value,
    childView,
    method
  }
}

export function hideSidePanel () {
  return {
    type: 'HIDE_SIDEPANEL'
  }
}
