import React from 'react'
import Text from '../Text'
import { AsyncStorage, StyleSheet, TouchableHighlight, View } from 'react-native'
import variables from '../../assets/stylesheets/variables'
import CheckButton from '../CheckButton'
import { connect } from 'react-redux'
import { saveMethodToRunAfterMoreInformations, selectRecommandation } from '../../store/actions/recommandationActions'
import { showModal } from '../../store/actions/modalActions'
import Button from '../Button'
import * as NavigationService from '../../routes/NavigationService'
import { getAsyncStorageItem } from '../../utils'

class ModalChoixRecommandation extends React.Component {
  constructor() {
    super()
  }

  state = {
    choice: null,
    displayModal: false
  }

  async componentDidMount () {
    const recommandationPreferences = await getAsyncStorageItem('recommandation_preferences')
    if (recommandationPreferences === 'french_only') {
      await this.setState({
        choice: this.props.choices.find(i => i.flag === '🇫🇷')
      })
      this.onSubmit()
    } else {
      this.setState({
        displayModal: true
      })
    }
  }

  onValidateChoice = (choice) => {
    this.setState({
      choice
    })
  }

  isItemSelected = (choice) => {
    return this.state.choice && choice.id === this.state.choice.id
  }

  itemList = () => {
    return this.props.choices.map((choice, index) => (
      <TouchableHighlight key={choice.id}
                          activeOpacity={0.9}
                          underlayColor={variables.colorHoverItem}
                          onPress={() => this.onValidateChoice(choice)}>
        <View style={[styles.item, index < this.props.choices.length - 1 ? styles.itemBottomBorder : {}]}>
          <CheckButton checked={this.isItemSelected(choice)}/>
          {this.isItemSelected(choice) && <Text style={styles.labelSelected}>{choice.label}</Text>}
          {!this.isItemSelected(choice) && <Text style={styles.label}>{choice.label}</Text>}
          {choice.flag &&
            <Text style={{ fontSize: 16, marginLeft: 5 }}>{choice.flag}</Text>
          }
        </View>
      </TouchableHighlight>
    ))
  }

  validateNextScreen = (item) => {
    if (
      item
      && item.nextScreen
      && (!item.nextScreen.condition || item.nextScreen.condition(this.state.choice))
    ) {
      this.props.showModal('Recommandations', item.nextScreen.screen, {}, (newScreen = {}) => this.validateNextScreen(newScreen.nextScreen ? newScreen : item.nextScreen))
    } else {
      this.finish()
    }
  }

  onSubmit = () => {
    this.props.selectRecommandation(this.state.choice)
    this.validateNextScreen(this.props)
  }

  finish = () => {
    this.props.hideModal()
    if (this.state.choice.id === 'sfd_sfc_2021' && this.props.hasSfdSfc2021NextScreen) {
      this.props.saveMethodToRunAfterMoreInformations(this.props.modalMethod)
      NavigationService.navigate('MoreInformations')
    } else {
      this.props.modalMethod()
    }
  }

  render() {
    const disabled = !this.state.choice
    return this.state.displayModal && (
      <View style={{ marginTop: 20 }}>
        {this.itemList()}
        <Button onPress={this.onSubmit}
                type="primary"
                style={{ width: '100%', marginTop: 10, marginBottom: -10 }}
                disabled={disabled}
                label="Valider" />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 74
  },

  itemBottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: variables.colorLightGray
  },

  labelSelected: {
    fontSize: 16,
    marginLeft: 15
  },

  label: {
    fontSize: 16,
    marginLeft: 15,
    fontFamily: 'OpenSans-Light'
  }
})

const mapStateToProps = (state) => ({
  hasSfdSfc2021NextScreen: state.modal.dataModel.hasSfdSfc2021NextScreen
})

const mapDispatchToProps = (dispatch) => ({
  selectRecommandation: (recommandation) => {
    dispatch(selectRecommandation(recommandation))
  },
  saveMethodToRunAfterMoreInformations: (method) => {
    dispatch(saveMethodToRunAfterMoreInformations(method))
  },
  showModal: (title, childView, dataModel = {}, method = null) => {
    dispatch(showModal(title, childView, dataModel, method))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalChoixRecommandation)
