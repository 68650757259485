export function showModal (title, childView, dataModel = {}, method = null, hideInitialModal = false) {
  return {
    type: 'SHOW_MODAL',
    title,
    childView,
    dataModel,
    method,
    hideInitialModal
  }
}

export function hideModal () {
  return {
    type: 'HIDE_MODAL'
  }
}
