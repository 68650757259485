import { createStore, combineReducers } from 'redux'
import pickerReducer from './reducers/pickerReducer'
import modalReducer from './reducers/modalReducer'
import sidepanelReducer from './reducers/sidepanelReducer'
import patientReducer from './reducers/patientReducer'
import recommandationReducer from './reducers/recommandationReducer'

const mainReducer = combineReducers({
  picker: pickerReducer,
  modal: modalReducer,
  sidepanel: sidepanelReducer,
  patient: patientReducer,
  recommandation: recommandationReducer
})

export default createStore(mainReducer)
