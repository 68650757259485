import { Platform } from 'react-native'

const variables = {
  primaryColor: '#006ED7',
  secondaryColor: '#293040',
  stepColor: '#3481EF',
  colorDarkGray: '#404D66',
  colorMidGray: '#8193AE',
  colorShinyGray: '#7a86a0',
  colorLightGray: 'rgba(64, 77, 102, 0.1)',
  borderColor: 'rgba(64, 77, 102, 0.15)',
  colorHoverItem: 'rgba(64, 77, 102, 0.05)',
  primaryGradient: ['#006ED7', '#004393'],
  bgGradient: ['#FFFFFF', '#FFFFFF', '#f5f9ff'],
  fontFamily: {
    regular: Platform.OS === 'ios' ? 'OpenSans' : 'OpenSans-Regular',
    light: 'OpenSans-Light',
    semiBold: 'OpenSans-Semibold'
  },
  successColor: 'rgb(209, 250, 229)',
  warningColor: 'rgb(255, 237, 213)',
  dangerColor: 'rgb(254, 226, 226)',
  infoBgColor: '#DBEAFE',
  errorColor: '#EF4444'
}

export default variables
