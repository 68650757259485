import React from 'react'
import { View, ScrollView } from 'react-native'
import { imc, toLocaleString } from '../../../utils'
import InputList from '../../../components/InputList'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../../components/Text'
import { connect } from 'react-redux'
import { updatePatient } from '../../../store/actions/patientActions'
import Icon from 'react-native-vector-icons/Ionicons'
import Button from '../../../components/Button'
import LottieContainer from '../../../components/LottieContainer'

import variables from '../../../assets/stylesheets/variables'
import baseStyle from '../../../assets/stylesheets/layouts/base'
import stepsStyle from '../../../assets/stylesheets/views/steps'
import { AlertBanner } from '../../../components/AlertBanner'

class FirstStep extends React.Component {
  constructor() {
    super()
  }

  state = {
    errors: {},
    hasError: false
  }

  onChange = async (key, value) => {
    await this.props.updatePatient(key, value)
    this.validateField(key)
  }

  validateField = (field = null) => {
    const moreThan65 = this.props.patient.age >= 65

    const errors = {
      health: moreThan65 && !this.props.patient.health,
      age: !this.props.patient.age,
      gender: !this.props.patient.gender,
      height: !this.props.patient.height,
      weight: !this.props.patient.weight
    }

    this.setState((prevState) => {
      const newErrors = field ? {
        ...prevState.errors,
        [field]: errors[field]
      } : errors

      const hasError = !Object.values(newErrors).every(i => !i)
      return {
        errors: newErrors,
        hasError
      }
    })

    return errors
  }

  validateFirstStep = () => {
    const errors = this.validateField()
    const hasError = !Object.values(errors).every(i => !i)
    if (!hasError || __DEV__) {
      this.props.navigation.navigate('SecondStep')
    }
  }

  render() {
    const moreThan65 = this.props.patient.age >= 65
    const patientImc = imc(this.props.patient.weight, this.props.patient.height).toLocaleString('fr-FR')
    return (
      <View style={baseStyle.baseLayout}>
        <LinearGradient style={baseStyle.contentSection}
                        colors={variables.bgGradient}>
          <ScrollView alwaysBounceVertical={false}
                      contentContainerStyle={stepsStyle.contentContainerStyle}>
            <Text style={stepsStyle.stepTitle}>1. Données cliniques</Text>
            {this.state.hasError && (
              <>
                <AlertBanner type="error">
                  Certaines informations sont manquantes ou incorrectes
                </AlertBanner>
              </>
            )}
            <InputList title="Sexe"
                       type="radio"
                       error={this.state.errors.gender}
                       choices={[{ label: 'Homme', value: 1 }, { label: 'Femme', value: 2 }]}
                       onChange={(value) => this.onChange('gender', value)}
                       value={this.props.patient.gender} />
            <View style={{ flexBasis: '100%', height: 0 }} />
            <InputList title="Âge"
                       type="number"
                       error={this.state.errors.age}
                       placeholder="Âge du patient..."
                       options={{
                         regex: /^[1-9][0-9]?$/,
                         unit: 'ans'
                       }}
                       onChange={(value) => this.onChange('age', value)}
                       value={this.props.patient.age} />
            {moreThan65 &&
            <InputList title="État de santé"
                       type="choice"
                       info="etat-sante"
                       error={this.state.errors.health}
                       onChange={(value) => this.onChange('health', value)}
                       choices={[{ id: 1, label: 'Bonne santé' }, { id: 2, label: 'Fragile' }, { id: 3, label: 'Dépendant/Très altéré' }]}
                       value={this.props.patient.health} />
            }
            <InputList title="Taille"
                       type="number"
                       placeholder="Taille du patient..."
                       error={this.state.errors.height}
                       options={{
                         regex: /[1-2]([.|,]([0-9]{1,2})?)?$/,
                         unit: 'm',
                         digits: 2
                       }}
                       onChange={(value) => this.onChange('height', value)}
                       value={this.props.patient.height} />
            <InputList title="Poids"
                       type="number"
                       placeholder="Poids du patient..."
                       error={this.state.errors.weight}
                       options={{
                         regex: /^[0-9]{1,3}[,|.]?[0-9]?$/,
                         digits: 1,
                         unit: 'kg'
                       }}
                       onChange={(value) => this.onChange('weight', value)}
                       value={this.props.patient.weight} />
            {
              !isNaN(patientImc) &&
              <View style={stepsStyle.imcInfoBlock}>
                <Icon style={{ marginRight: 8, marginTop: 2 }}
                      name="ios-information-circle-outline"
                      color={variables.colorMidGray}
                      size={24} />
                <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 16, color: variables.colorMidGray }}>
                  IMC : {toLocaleString(patientImc, 1)} kg/m2
                </Text>
              </View>
            }
          </ScrollView>
          <View style={stepsStyle.footer}>
            <View style={stepsStyle.footerContainer}>
              <Button style={{ marginRight: 10 }}
                      type="outline"
                      onPress={() => this.props.navigation.navigate('Home')}
                      label="Retour" />
              <Button onPress={this.validateFirstStep}
                      label="Suivant" />
            </View>
          </View>
        </LinearGradient>
        <LottieContainer primary
                         lottieAnimation={require('../../../assets/lottie/data-analysis.json')} />
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (key, value) => {
    dispatch(updatePatient(key, value))
  }
})

const mapStateToProps = (state) => ({
  patient: state.patient
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep)
