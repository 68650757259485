import store from '../../../../store'
import { texts } from '../texts'

export function StrategieInsuffisanceCardiaque () {
  const state = store.getState()
  const patientAge = state.patient.age
  const hasRenalDisease = state.patient.complications.some(i => i.id === 3)
  const dfg = state.patient.dfg
  const fevgMoreThan40 = state.patient.ejection === 1
  const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
  const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
  const carefulGLP1 = hasInsuffisanceCardiaque && !fevgMoreThan40
  const result = []
  let label = null
  let info = null
  if (hasRenalDisease && dfg < 30) {
    label = state.patient.metformine ? 'Arrêter metformine' : 'Ne pas utiliser metformine'
    info = texts.metformine_renal_issues
  } else if (!hasRenalDisease || dfg >= 30) {
    label = state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine'
    if (dfg < 45) {
      label += ' à 1g/jour maximum'
    } else if (dfg < 60) {
      label += ' à 2g/jour maximum'
    }
  }
  if (!hasRenalDisease || dfg >= 25) {
    label += ' et ajouter iSGLT2 quelle que soit l\'HbA1c'
    if (patientAge >= 75) {
      label += ' (si envisageable pour ce patient agé)'
      info += texts.isglt2_ages
    }
    result.push({
      label: label,
      info: info,
      children: [
        {
          label: `Ou AR GLP-1 si intolérance ou contre-indication aux iSGLT2${hasMaladieAtheromateuse ? '' : ' ET objectif non atteint'}`,
          info: carefulGLP1 ? `${texts.careful_aglp1_fe}\n\n${texts.aglp1_benefice_cardiaque}` : texts.aglp1_benefice_cardiaque
        }
      ]
    })
    info = null

  } else { // On rentre dans l'autre condtion uniquement si DFG < 25

    if (dfg >= 20) {
      label += ' et ajouter empagliflozine quelle que soit l\'HbA1c'
      info = "Dans le cas d'un DFG entre 20 et 24 mL/min/1,73 et d'une insuffisance cardiaque, seul l'empagliflozine est utilisable à 10mg par jour."

      result.push({
        label: label,
        info: info,
        children: [
          {
            label: `Ou AR GLP-1 si intolérance ou contre-indication aux iSGLT2${hasMaladieAtheromateuse ? '' : ' ET objectif non atteint'}`,
            choice: 2,
            info: carefulGLP1 ? `${texts.careful_aglp1_fe}\n\n${texts.aglp1_benefice_cardiaque}` : texts.aglp1_benefice_cardiaque
          }
        ]
      })
      info = null
    } else if (dfg >= 15 && dfg < 20) {
      label = state.patient.metformine ? 'Arrêter metformine et ajouter AR GLP-1' : 'Ajouter AR GLP-1'
      info = carefulGLP1 ? `${texts.careful_aglp1_fe}\n\n${texts.aglp1_benefice_cardiaque}` : texts.aglp1_benefice_cardiaque
      if (patientAge >= 75) {
        label += ' (si envisageable pour ce patient agé)'
        info +=  `\n\n${texts.isglt2_ages}`
      }

      result.push({
        label: label,
        info: info
      })
      info = null
    }
  }

  info = [texts.metformine_insuffisance_cardiaque]
  info.push('Ne pas utiliser la saxagliptine.')
  if (dfg >= 60) {
    label = "L'ensemble des autres médicaments antidiabétiques oraux ou injectables peuvent être envisagés dans le respect des éventuelles contre-indications."
    info.push("L'insuline, le répaglinide et les sulfamides peuvent être envisagés en faisant attention au risque hypoglycémique.")
  } else if (dfg >= 30) { // moins de 60
    if (patientAge >= 75) {
      label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine et la sitagliptine peuvent également être utilisés à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    } else {
      label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine et la sitagliptine peuvent également être utilisés à dose de 50 mg/jour (sans association avec un AR GLP-1). Le répaglinide, le glimépiride, le gliclazide et l'insuline peuvent être utilisés en faisant attention au risque hypoglycémique."
    }
  } else if (dfg >= 20) { // On propose un iSGLT2 en premier, il faut donc rajouter le AR GLP-1 dans la phrase
    label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine peut également être utilisée à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
  } else if (dfg >= 15) {
    label = "La vildagliptine peut être utilisée à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
  } else {
    label = "La vildagliptine peut être utilisée à dose de 50 mg/jour. L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
  }

  result.push({
    label: label,
    info: info.join('\n\n'),
    style: {
      fontSize: 14.5
    }
  })
  return result
}
