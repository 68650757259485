import store from '../../../../store'
import { texts } from '../texts'

export function StrategiePersonneAgee () {
  const state = store.getState()
  const result = []
  let label = state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine'
  result.push({
    label: label,
    info: null
  })
  if (state.patient.health === 1) {
    result.push({
      label: 'Ajouter iDPP4 (iSGLT2 ou AR GLP-1 également envisageables au cas par cas)',
      info: texts.isglt2_ages
    })
    result.push({
      label: 'L\'ensemble des autres médicaments antidiabétiques oraux ou injectables peuvent être envisagés dans le respect des éventuelles contre-indications.',
      style: {
        fontSize: 14.5
      },
      info: 'L\'insuline, le répaglinide et les sulfamides peuvent être envisagés en faisant attention au risque hypoglycémique.'
    })
  } else {
    result.push({
      label: 'Ajouter iDPP4',
      info: null
    })
    result.push({
      label: 'Chez les sujets fragiles ou dépendants, l\'insuline est l\'option à privilégier, en faisant attention au risque hypoglycémique.',
      style: {
        fontSize: 14.5
      },
      info: 'Sulfamides et glinides déconseillés chez les sujet fragiles ou dépendants.'
    })
  }
  return result
}
