import React from 'react'
import { connect } from 'react-redux'
import variables from '../assets/stylesheets/variables'
import {
  StyleSheet,
  TouchableOpacity,
  Animated,
  Dimensions,
  View, Platform, ScrollView
} from 'react-native'
import Text from './Text'
import LinearGradient from 'react-native-linear-gradient'
import Icon from 'react-native-vector-icons/Ionicons'
import { hideModal } from '../store/actions/modalActions'
import ModalAntecedentCardio from './modals/ModalAntecedentCardio'
import ModalMaladieRenale from './modals/ModalMaladieRenale'
import ModalAntecedentFamilialCardioPrecoce from './modals/ModalAntecedentFamilialCardioPrecoce'
import ModalAlbuminurie from './modals/ModalAlbuminurie'
import ModalEtatSante from './modals/ModalEtatSante'
import ModalChoixRecommandation from './modals/ModalChoixRecommandation'
import ModalHasMetformine from './modals/ModalHasMetformine'
import ModalTooltip from './modals/ModalTooltip'
import ModalHasIsglt2 from './modals/ModalHasIsglt2'
import ModalSedentary from './modals/ModalSedentary'
import ModalFdrNonControle from './modals/ModalFdrNonControle'
import DetailStrategieAdaEasd2022Glycemie from './modals/detailStrategieAdaEasd2022/DetailStrategieAdaEasd2022Glycemie'
import DetailStrategieAdaEasd2022Poids from './modals/detailStrategieAdaEasd2022/DetailStrategieAdaEasd2022Poids'
import ModalClosePatient from './modals/ModalClosePatient'
import ModalHasIsglt2Intolerance from './modals/ModalHasIsglt2Intolerance'
import ModalScore2Diabetes from './modals/ModalScore2Diabetes'

class Modal extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    fadeAnim: new Animated.Value(0),
    scaleAnim: new Animated.Value(0.95)
  }

  componentDidMount () {
    this.toggleRevealModal(true)
  }

  toggleRevealModal = (reveal) => {
    Animated.timing(
      this.state.fadeAnim,
      {
        toValue: reveal ? 1 : 0,
        duration: 200,
        useNativeDriver: true
      }
    ).start()
    Animated.timing(
      this.state.scaleAnim,
      {
        toValue: reveal ? 1 : 0.95,
        duration: 200,
        useNativeDriver: true
      }
    ).start()
  }

  hideModal = () => {
    this.toggleRevealModal(false)
    setTimeout(() => {
      this.props.hideModal()
    }, 200)
  }

  render() {
    return (
      <Animated.View style={[styles.modalBg, { opacity: this.props.hideInitialModal ? 0 : this.state.fadeAnim }]}>
        <Animated.View style={[styles.modalContainer, { transform: [{ scale: this.state.scaleAnim }] }]}>
          <View>
            <LinearGradient style={{ ...styles.modalContainer }}
                            colors={['#FFFFFF', '#F8FBFF']}>
              <Text style={styles.modalTitle}>{this.props.title || ''}</Text>
              <TouchableOpacity style={{ position: 'absolute', top: 0, right: 0, paddingHorizontal: 20, paddingVertical: 10, zIndex: 9999 }}
                                activeOpacity={0.8}
                                onPress={this.hideModal}>
                <Icon color={variables.colorMidGray}
                      style={{ marginTop: 4 }}
                      size={22}
                      name="ios-close" />
              </TouchableOpacity>
              <ScrollView alwaysBounceVertical={false} contentContainerStyle={{ flexGrow: 1, padding: 20, paddingTop: 40, paddingBottom: 40 }}>
                {this.props.childView === 'antecedent-cardio' && <ModalAntecedentCardio />}
                {this.props.childView === 'maladie-renale' && <ModalMaladieRenale />}
                {this.props.childView === 'antecedent-familial-cv-precoce' && <ModalAntecedentFamilialCardioPrecoce />}
                {this.props.childView === 'sedentary' && <ModalSedentary />}
                {this.props.childView === 'fdr-non-controle' && <ModalFdrNonControle />}
                {this.props.childView === 'albuminurie' && <ModalAlbuminurie />}
                {this.props.childView === 'close-patient' && <ModalClosePatient hideModal={this.hideModal} />}
                {this.props.childView === 'choix-recommandation' &&
                  <ModalChoixRecommandation choices={this.props.dataModel.choices}
                                            nextScreen={this.props.dataModel.nextScreen}
                                            modalMethod={this.props.modalMethod}
                                            hideModal={this.hideModal}/>
                }
                {this.props.childView === 'check-has-metformine' &&
                  <ModalHasMetformine choices={this.props.dataModel.choices}
                                      modalMethod={this.props.modalMethod}
                                      hideModal={this.hideModal}/>
                }
                {this.props.childView === 'check-has-isglt2' &&
                  <ModalHasIsglt2 choices={this.props.dataModel.choices}
                                  modalMethod={this.props.modalMethod}
                                  hideModal={this.hideModal} />
                }
                {this.props.childView === 'check-has-isglt2-intolerance' &&
                  <ModalHasIsglt2Intolerance modalMethod={this.props.modalMethod} />
                }
                {this.props.childView === 'score2-diabetes' &&
                  <ModalScore2Diabetes modalMethod={this.props.modalMethod}
                                       hideModal={this.hideModal} />
                }
                {this.props.childView === 'detail-strategie-ada-easd-2022-glycemie' && <DetailStrategieAdaEasd2022Glycemie />}
                {this.props.childView === 'detail-strategie-ada-easd-2022-poids' && <DetailStrategieAdaEasd2022Poids />}
                {this.props.childView === 'etat-sante' && <ModalEtatSante />}
                {this.props.childView === 'tooltip' && <ModalTooltip text={this.props.dataModel.text} />}
              </ScrollView>
            </LinearGradient>
          </View>
        </Animated.View>
        <TouchableOpacity style={{ position: 'absolute', flex: 1, height: '100%', width: '100%' }}
                          activeOpacity={1}
                          onPress={this.hideModal} />
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  modalBg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'absolute',
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%'
  },
  modalContainer: {
    position: 'relative',
    width: Platform.OS === 'web' ? 500 : Dimensions.get('window').width*0.8,
    maxHeight: Dimensions.get('window').height*0.8,
    zIndex: 10001,
    borderRadius: 3,
    cursor: 'auto'
  },
  modalTitle: {
    position: 'absolute',
    left: 20,
    top: 18,
    textTransform: 'uppercase',
    fontFamily: 'OpenSans-Semibold',
    letterSpacing: -0.5,
    fontSize: 14
  }
})

const mapStateToProps = (state) => ({
  title: state.modal.title,
  childView: state.modal.childView,
  dataModel: state.modal.dataModel,
  modalMethod: state.modal.method,
  hideInitialModal: state.modal.hideInitialModal
})

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => {
    dispatch(hideModal())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
