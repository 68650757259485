import React from 'react'
import { View, StyleSheet } from 'react-native'
import Text from '../../Text'
import variables from '../../../assets/stylesheets/variables'
import { getAuthorizedGlp1, getAuthorizedIdpp4, getAuthorizedIsglt2, getAuthorizedMetformine } from './utils'
import { useSelector } from 'react-redux'

const DetailStrategieAdaEasd2022Poids = () => {

  const mediumEfficacity = [
    getAuthorizedGlp1(),
    getAuthorizedIsglt2()
  ].filter(i => i).join('\n')

  const neutralEfficacity = [
    getAuthorizedIdpp4(),
    getAuthorizedMetformine()
  ].filter(i => i).join('\n')

  const complications = useSelector(state => state.patient.complications)
  const dfg = useSelector(state => state.patient.dfg)
  const hasRenalDisease = complications.some(i => i.id === 3)
  const rearrangedTable = hasRenalDisease && dfg < 60 ? '(molécules utilisables en tenant compte du DFG du patient selon ADA/KDIGO 2022)' : ''

  return (
    <View>
      <Text style={styles.text}>
        Fixer des objectifs individualisés pour la perte de poids.
      </Text>
      <Text style={{ ...styles.text, fontFamily: variables.fontFamily.semiBold }}>
        Efficacité sur la perte de poids {rearrangedTable}:
      </Text>
      <View style={styles.veryHighEfficacityBlock}>
        <Text style={{ fontFamily: variables.fontFamily.semiBold, marginBottom: 4 }}>Très haute</Text>
        <Text>Sémaglutide, tirzepatide</Text>
      </View>
      <View style={styles.highEfficacityBlock}>
        <Text style={{ fontFamily: variables.fontFamily.semiBold, marginBottom: 4 }}>Haute</Text>
        <Text>Dulaglutide, liraglutide</Text>
      </View>
      <View style={styles.intermediateEfficacityBlock}>
        <Text style={{ fontFamily: variables.fontFamily.semiBold, marginBottom: 4 }}>Intermédiaire</Text>
        <Text>{mediumEfficacity}</Text>
      </View>
      <View style={styles.neutralEfficacityBlock}>
        <Text style={{ fontFamily: variables.fontFamily.semiBold, marginBottom: 4 }}>Neutre</Text>
        <Text>{neutralEfficacity}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 15,
    lineHeight: 24,
    marginBottom: 20
  },
  veryHighEfficacityBlock: {
    backgroundColor: variables.successColor,
    padding: 10,
    borderRadius: 0
  },
  highEfficacityBlock: {
    backgroundColor: variables.warningColor,
    padding: 10,
    borderRadius: 0
  },
  intermediateEfficacityBlock: {
    backgroundColor: variables.dangerColor,
    padding: 10,
    borderRadius: 0
  },
  neutralEfficacityBlock: {
    backgroundColor: variables.colorHoverItem,
    padding: 10,
    borderRadius: 0
  }
})

export default DetailStrategieAdaEasd2022Poids
