import React, { useCallback, useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import Text from '../../components/Text'
import { useNavigation, useRoute } from '@react-navigation/native'
import { formatPayloadForSemeiaAPI, parseAndFormatPatientProfile, parseJwt, redirectToDiabetowise } from './utils'
import { useDispatch, useStore } from 'react-redux'
import { loadPatientProfile } from '../../store/actions/patientActions'
import {
  objectifsGlycemiques,
  objectifsLipidiques,
  objectifsTensionnels,
  risqueCardio,
  selectRecommandation,
  strategieTherapeutique
} from '../../store/actions/recommandationActions'
import { esc2021, sfd2021 } from '../../config/recommandations'
import { sendPayload } from './api'
import { AlertBanner } from '../../components/AlertBanner'
import baseStyle from '../../assets/stylesheets/layouts/base'
import variables from '../../assets/stylesheets/variables'
import LinearGradient from 'react-native-linear-gradient'
import LoadingSpinner from '../../components/LoadingSpinner'

const Logo = require('../../assets/img/logo@3x.png')

export const Diabetowise = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const route = useRoute()
  // const navigation = useNavigation()         /* FOR TESTING WITH DIABETOWISE */

  const [error, setError] = useState(false)

  const decodePatientJwt = useCallback(() => {
    try {
      const { data } = parseJwt(route.params.token)
      return data
    } catch (e) {
      setError('Une erreur est survenue lors de la récupération des informations du patient.')
      throw new Error()
    }
  }, [route.params?.token])

  const sendPayloadToApi = useCallback(async (data) => {
    try {
      const payload = formatPayloadForSemeiaAPI(store.getState().recommandation, data)
      const result = await sendPayload(payload)
      return await result.json()
    } catch (e) {
      setError('Une erreur est survenue lors de l\'envoi des recommandations du patient.')
      throw new Error()
    }
  }, [store])

  useEffect(() => {
    const processJwt = async () => {
      try {
        const data = decodePatientJwt()
        const formattedPatient = parseAndFormatPatientProfile(data)
        dispatch(loadPatientProfile(formattedPatient))

        dispatch(selectRecommandation(esc2021))
        dispatch(risqueCardio())
        dispatch(objectifsLipidiques())
        dispatch(objectifsTensionnels())

        dispatch(selectRecommandation(sfd2021))
        dispatch(objectifsGlycemiques())
        dispatch(strategieTherapeutique())

        /* FOR TESTING WITH DIABETOWISE

          navigation.navigate('Steps', { screen: 'List' })
          return
         */

        const result = await sendPayloadToApi(data)

        setTimeout(() => {
          redirectToDiabetowise(result.data, data.patient_id)
        }, 3000)
      } catch (e) {
        console.error(e)
      }
    }

    processJwt()
  }, [decodePatientJwt, dispatch, sendPayloadToApi])

  return (
    <View style={baseStyle.baseLayout}>
      <LinearGradient colors={variables.primaryGradient}
                      style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}
                      start={{ x: 0, y: 1 }}
                      end={{ x: 1, y: 0 }}>
        <View style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Image style={{ width: 320, height: 50, resizeMode: 'contain' }}
                 source={Logo} />
          <Text style={{ color: 'white', fontSize: 16, fontFamily: variables.fontFamily.light, marginVertical: 40 }}>
            Récupération des recommandations du patient
          </Text>
          {
            error ? <AlertBanner type="error">
              Une erreur est survenue lors de la récupération des informations du patient.
            </AlertBanner> : <LoadingSpinner />
          }
        </View>
      </LinearGradient>
    </View>
  )
}
