import React from 'react'
import {
  View,
  TouchableOpacity,
  StyleSheet
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../components/Text'
import variables from '../../assets/stylesheets/variables'
import { showModal, hideModal } from '../../store/actions/modalActions'
import {
  getRisqueCardio,
  objectifsGlycemiques,
  objectifsLipidiques,
  objectifsTensionnels,
  risqueCardio,
  strategieTherapeutique
} from '../../store/actions/recommandationActions'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/Ionicons'
import {
  adaeasd2022,
  hassfhta2016,
  sfd2023,
  esc2023,
  sfdsfc2021,
  esc2021, adakdigo2022, ada2023
} from '../../config/recommandations'
import ListItem from '../../components/ListItem'
import { getAsyncStorageItem } from '../../utils'

class List extends React.Component {
  constructor() {
    super()
  }

  onObjectifSelected = () => {
    this.props.objectifsGlycemiques()
    this.props.navigation.navigate('Objectifs')
  }

  onPressObjectifs = () => {
    const dataModel = {
      choices: [
        sfd2023,
        ada2023
      ]
    }
    const hasRenalDisease = this.props.patient.complications.some(i => i.id === 3)
    const hasInsuffisanceCardiaque = this.props.patient.complications.some(i => i.id === 5)
    const hasMaladieAtheromateuse = this.props.patient.complications.some(i => i.id === 4)
    if (hasMaladieAtheromateuse || hasInsuffisanceCardiaque) {
      dataModel.choices.push(esc2023)
    }
    if (hasRenalDisease) {
      dataModel.choices.push(adakdigo2022)
    }
    this.props.showModal('Recommandations', 'choix-recommandation', dataModel, this.onObjectifSelected)
  }

  onRisquesCardioSelected = () => {
    this.props.risqueCardio()
    this.props.navigation.navigate('Cardiovasculaire')
  }

  onPressRisquesCardio = () => {
    const dataModel = {
      choices: [
        esc2023,
        sfdsfc2021
      ],
      nextScreen: {
        screen: 'score2-diabetes',
        condition: (recommandation) => {
          const { value } = getRisqueCardio(false)
          return recommandation === esc2023 && value === 'unknown'
        }
      },
      hasSfdSfc2021NextScreen: true
    }
    this.props.showModal('Recommandations', 'choix-recommandation', dataModel, this.onRisquesCardioSelected)
  }

  onLipidesSelected = () => {
    this.props.objectifsLipidiques()
    this.props.navigation.navigate('Lipides')
  }

  onPressLipides = () => {
    const dataModel = {
      choices: [
        esc2023,
        sfdsfc2021
      ],
      nextScreen: {
        screen: 'score2-diabetes',
        condition: (recommandation) => {
          const { value } = getRisqueCardio(false)
          return recommandation === esc2023 && value === 'unknown'
        }
      },
      hasSfdSfc2021NextScreen: true
    }
    this.props.showModal('Recommandations', 'choix-recommandation', dataModel, this.onLipidesSelected)
  }

  onTensionSelected = () => {
    this.props.objectifsTensionnels()
    this.props.navigation.navigate('Tension')
  }

  onPressTension = () => {
    const dataModel = {
      choices: [
        esc2023,
        sfdsfc2021,
        hassfhta2016
      ]
    }
    this.props.showModal('Recommandations', 'choix-recommandation', dataModel, this.onTensionSelected)
  }

  onStrategieSelected = () => {
    this.props.objectifsGlycemiques()
    this.props.strategieTherapeutique()
    this.props.navigation.navigate('StrategieTherapeutique')
  }

  onPressStrategies = () => {
    const hasRenalDisease = this.props.patient.complications.some(i => i.id === 3)

    const dataModel = {
      choices: [
        sfd2023,
        adaeasd2022
      ],
      nextScreen: {
        screen: 'check-has-metformine'
        /*
        nextScreen: {
          screen: 'check-has-isglt2-intolerance',
          condition: (recommandation) => {
            return recommandation === sfd2023 && (hasRenalDisease) // TODO add all cases when we ask
          }
        }
         */
      }
    }

    if (hasRenalDisease) {
      dataModel.choices.push(adakdigo2022)
    }
    this.props.showModal('Recommandations', 'choix-recommandation', dataModel, this.onStrategieSelected)
  }

  onPressClose = () => {
    this.props.showModal('', 'close-patient', {})
  }

  render() {
    const items = [
      {
        label: 'Objectifs glycémiques',
        content: "Recommandation d'hémoglobine glyquée adaptée au patient.",
        icon: require('../../assets/img/drop.png'),
        onPress: this.onPressObjectifs
      },
      {
        label: 'Niveau de risque cardiovasculaire',
        content: 'Calcul du risque cardiovasculaire, de modéré à haut risque.',
        icon: require('../../assets/img/heartbeat.png'),
        iconStyle: { marginTop: 4 },
        onPress: this.onPressRisquesCardio
      },
      {
        label: 'Stratégies\nthérapeutiques du DT2',
        content: 'Schéma de traitement personnalisé pour chaque patient.',
        icon: require('../../assets/img/pill.png'),
        labelStyle: { width: '130%' },
        onPress: this.onPressStrategies
      },
      {
        label: 'Objectifs\nlipidiques',
        content: 'Objectifs de cholestérol LDL découlant du risque cardiovasculaire.',
        icon: require('../../assets/img/lipides.png'),
        onPress: this.onPressLipides
      },
      {
        label: 'Objectifs tensionnels',
        content: 'Objectifs de tension conformes au profil patient renseigné.',
        icon: require('../../assets/img/tension.png'),
        onPress: this.onPressTension
      },
      {
        label: 'Bibliographie & liens',
        content: 'Dernières publications des sociétés savantes, mis à jour à chaque sortie.',
        icon: require('../../assets/img/web.png'),
        onPress: () => this.props.navigation.navigate('LiensUtiles')
      }
    ]
    return (
      <React.Fragment>
        <LinearGradient
          style={{ zIndex: 1 }}
          colors={variables.primaryGradient}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 0 }}>
          <SafeAreaView edges={['top']}>
            <View style={styles.headerContainer}>
              <View style={{ position: 'relative', width: '100%' }}>
                <TouchableOpacity style={{ marginTop: 5, position: 'absolute', left: 5, zIndex: 1, paddingLeft: 10, paddingRight: 10 }}
                                  activeOpacity={0.9}
                                  onPress={() => this.props.navigation.goBack()}>
                  <Icon style={{ marginTop: 1 }}
                        name="chevron-back"
                        size={28}
                        color="white" />
                </TouchableOpacity>
                <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 26, color: 'white', letterSpacing: -1, textAlign: 'center', marginTop: 2 }}>
                  DiabGuide
                </Text>
                <TouchableOpacity style={{ marginTop: 5, position: 'absolute', right: 5, zIndex: 1, paddingLeft: 10, paddingRight: 10 }}
                                  activeOpacity={0.9}
                                  onPress={this.onPressClose}>
                  <Icon style={{ marginTop: 1 }}
                        name="md-close"
                        size={28}
                        color="white" />
                </TouchableOpacity>
              </View>
            </View>
          </SafeAreaView>
        </LinearGradient>
        <View style={{ flex: 1, zIndex: 1, position: 'relative', backgroundColor: '#f5f9ff' }}>
          <View style={styles.body}>
            <View style={styles.bodyRow}>
              {items.map((item, index) => (
                <ListItem key={index}
                          label={item.label}
                          content={item.content}
                          icon={item.icon}
                          iconStyle={item.iconStyle}
                          labelStyle={item.labelStyle}
                          onPress={item.onPress} />
              ))}
            </View>
          </View>
        </View>
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    height: 135,
    display: 'flex',
    alignItems: 'center',
    marginTop: 12
  },

  body: {
    zIndex: 3,
    position: 'relative',
    top: -(164 + 10)*0.4,//Overflow de 40% vers le haut d'un item de hauteur 164 avec marge de 10
    width: '100%'
  },
  bodyRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
})

const mapDispatchToProps = (dispatch) => ({
  showModal: async (title, childView, dataModel = {}, method = null) => {
    const recommandationPreferences = await getAsyncStorageItem('recommandation_preferences')
    dispatch(showModal(title, childView, dataModel, method, childView === 'choix-recommandation' && recommandationPreferences === 'french_only'))
  },
  hideModal: () => {
    dispatch(hideModal())
  },
  objectifsGlycemiques: () => {
    dispatch(objectifsGlycemiques())
  },
  objectifsLipidiques: () => {
    dispatch(objectifsLipidiques())
  },
  objectifsTensionnels: () => {
    dispatch(objectifsTensionnels())
  },
  risqueCardio: () => {
    dispatch(risqueCardio())
  },
  strategieTherapeutique: () => {
    dispatch(strategieTherapeutique())
  }
})

const mapStateToProps = (state) => ({
  patient: state.patient
})

export default connect(mapStateToProps, mapDispatchToProps)(List)
