import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback, TouchableOpacity, Switch } from 'react-native'
import moment from 'moment'
import Text from './Text'
import RadioButton from './RadioButton'
import variables from '../assets/stylesheets/variables'
import Icon from 'react-native-vector-icons/Ionicons'
import { connect } from 'react-redux'
import { showPicker } from '../store/actions/pickerActions'
import { showSidePanel } from '../store/actions/sidepanelActions'
import { showModal } from '../store/actions/modalActions'
import ChoiceInput from './ChoiceInput'
import NumberInput from './NumberInput'

class InputList extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    isInputActive: false
  }

  radioInput = () => {
    const answerList = this.props.choices.map((choice, index) => (
      <TouchableWithoutFeedback key={choice.value}
                                onPress={() => this.props.onChange(choice.value)}>
        <View style={{ flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginRight: index - 1 === this.props.choices.length ? 0 : 40 }}>
          <RadioButton checked={this.props.value === choice.value} />
          <Text style={{ ...(this.props.value === choice.value ? styles.inputTextAnswer : styles.inputText), marginLeft: 8 }}>{choice.label}</Text>
        </View>
      </TouchableWithoutFeedback>
    ))
    return (
      <View style={styles.radioInput}>
        {answerList}
      </View>
    )
  }

  switchInput = () => {
    return (
      <View style={styles.touchableOpacityStyle}>
        {this.props.value && <Text style={{ ...styles.inputTextAnswer, color: variables.primaryColor }}>Oui</Text>}
        {!this.props.value && <Text style={styles.inputText}>Non</Text>}
        <Switch value={this.props.value}
                onValueChange={(value) => this.props.onChange(value)}
                style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
                tintColor={variables.colorLightGray}
                onTintColor={variables.primaryColor}/>
      </View>
    )
  }

  dateInput = () => {
    let text = null
    if (this.props.value) {
      const date = moment(this.props.value).format('DD/MM/YYYY')
      text = <Text style={styles.inputTextAnswer}>{date}</Text>
    } else {
      text = <Text style={styles.inputTextSelect}>Selectionner</Text>
    }
    return (
      <TouchableOpacity activeOpacity={0.8}
                        style={styles.touchableOpacityStyle}
                        onPress={() => this.props.showPicker(this.props.title, null, this.props.value,'date', this.props.onChange)}>
        {text}
        <Icon style={{ marginLeft: 2, marginTop: 3 }}
              name="chevron-down"
              color={variables.primaryColor}
              size={18}/>
      </TouchableOpacity>
    )
  }

  yearInput = () => {
    let text = null
    if (this.props.value) {
      text = <Text style={styles.inputTextAnswer}>{this.props.value}</Text>
    } else {
      text = <Text style={styles.inputTextSelect}>Selectionner</Text>
    }
    return (
      <TouchableOpacity activeOpacity={0.8}
                        style={styles.touchableOpacityStyle}
                        onPress={() => this.props.showPicker(this.props.title, null, this.props.value,'year', this.props.onChange)}>
        {text}
        <Icon style={{ marginLeft: 2, marginTop: 3 }}
              name="chevron-down"
              color={variables.primaryColor}
              size={18}/>
      </TouchableOpacity>
    )
  }

  sidepanelComplicationsInput = () => {
    let text = null
    if (this.props.value.length) {
      text = <Text style={styles.inputTextAnswer}>{this.props.value.length} {this.props.value.length > 1 ? 'complications' : 'complication'}</Text>
    } else {
      text = <Text style={styles.inputTextAnswer}>Aucune</Text>
    }
    return (
      <TouchableOpacity activeOpacity={0.8}
                        style={styles.touchableOpacityStyle}
                        onPress={() => this.props.showSidePanel(this.props.sidePanelTitle || this.props.title, this.props.choices, this.props.value, this.props.childView, this.props.onChange)}>
        {text}
        <Icon style={{ marginLeft: 2, marginTop: 3 }}
              name="chevron-down"
              color={variables.primaryColor}
              size={18}/>
      </TouchableOpacity>
    )
  }

  toggleActiveInput = (active) => {
    this.setState({
      isInputActive: active
    })
  }

  render() {
    let right = null
    if (this.props.type === 'radio') {
      right = this.radioInput()
    } else if (this.props.type === 'switch') {
      right = this.switchInput()
    } else if (this.props.type === 'choice') {
      right = <ChoiceInput {...this.props} toggleActiveInput={this.toggleActiveInput} />
    } else if (this.props.type === 'date') {
      right = this.dateInput()
    } else if (this.props.type === 'year') {
      right = <NumberInput {...this.props} toggleActiveInput={this.toggleActiveInput} />
    } else if (this.props.type === 'number') {
      right = <NumberInput {...this.props} toggleActiveInput={this.toggleActiveInput} />
    } else if (this.props.type === 'sidepanel') {
      if (this.props.childView === 'complications') {
        right = this.sidepanelComplicationsInput()
      }
    } else {
      right = <Text style={styles.inputTextAnswer}>{this.props.value}</Text>
    }
    return (
      <View style={[styles.test, this.state.isInputActive ? { zIndex: 1 } : undefined]}>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ ...styles.inputText, ...(this.props.error ? { color: variables.errorColor } : {}) }}>{this.props.title.replace('\n', '')}</Text>
          {this.props.info &&
          <TouchableOpacity activeOpacity={0.8}
                            onPress={() => this.props.showModal('', this.props.info)}
                            style={{ width: 40, alignItems: 'center', justifyContent: 'center' }}>
            <Icon name="ios-help-circle-outline"
                  size={20}
                  color={variables.colorMidGray} />
          </TouchableOpacity>
          }
        </View>
        <View style={[styles.inputContainer, this.props.error ? { borderColor: variables.errorColor } : undefined, this.state.isInputActive ? styles.inputContainerFocused : undefined, this.props.type === 'radio' ? { border: 0, paddingLeft: 0 } : undefined]}>
          {right}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  test: {
    width: '50%',
    paddingHorizontal: 10,
    marginBottom: 20
  },
  inputContainer: {
    width: '100%',
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: variables.colorLightGray,
    borderRadius: 4,
    marginTop: 10
  },
  inputContainerFocused: {
    borderWidth: 2,
    marginHorizontal: -1,
    borderColor: variables.primaryColor,
    borderRadius: 4
  },
  inputText: {
    fontFamily: variables.fontFamily.light,
    fontSize: 15
  },
  inputTextAnswer: {
    fontFamily: variables.fontFamily.regular,
    fontSize: 16
  },

  inputTextSelect: {
    fontFamily: variables.fontFamily.regular,
    fontSize: 16,
    color: variables.primaryColor
  },

  radioInput: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  touchableOpacityStyle: {
    height: 60,
    width: '100%',
    paddingLeft: 14,
    paddingRight: 18,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const mapDispatchToProps = (dispatch) => ({
  showPicker: (title, choices = [], value, childView, method, options = {}) => {
    dispatch(showPicker(title, choices, value, childView, method, options))
  },
  showSidePanel: (title, choices = [], value, childView, method) => {
    dispatch(showSidePanel(title, choices, value, childView, method))
  },
  showModal: (title, childView) => {
    dispatch(showModal(title, childView))
  }
})

const mapStateToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InputList)
