import store from '../store'
import {
  sfd2023,
  sfd2021,
  esc2021,
  sfdsfc2021, ada2023, adakdigo2022, adaeasd2022, esc2023
} from '../../config/recommandations'
import getStrategieTherapeutique from './methods/getStrategieTherapeutique'
import moment from 'moment'
import { imc } from '../../utils'

const tirTables = {
  normal: [
    {
      label: '0,70 - 1,80 g/L',
      value: '> 70 %'
    },
    [
      {
        label: '< 0,70 g/L',
        value: '< 4 %'
      },
      {
        label: '< 0,54 g/L',
        value: '< 1 %'
      }
    ],
    [
      {
        label: '> 1,80 g/L',
        value: '< 25 %'
      },
      {
        label: '> 2,50 g/L',
        value: '< 5 %'
      }
    ]
  ],
  weak: [
    {
      label: '0,70 - 1,80 g/L',
      value: '> 50 %'
    },
    [
      {
        label: '< 0,70 g/L',
        value: '< 1 %'
      },
      {
        label: '< 0,54 g/L',
        value: '0 %'
      }
    ],
    [
      {
        label: '> 1,80 g/L',
        value: '< 50 %'
      },
      {
        label: '> 2,50 g/L',
        value: '< 10 %'
      }
    ]
  ]
}

export function getObjectifsGlycemiques () {
  const state = store.getState()
  let hba = '7,0'
  let subtitle = null
  let note = ''

  const patientAge = state.patient.age
  const yearsOfDiabetes = parseInt(moment().year()) - parseInt(state.patient.diabetesSince)
  const hasRenalDisease = state.patient.complications.some(i => i.id === 3)

  if (state.recommandation.recommandation === sfd2021) {
    if (patientAge >= 75 && state.patient.health === 3) { // Patient agé et dépendant
      hba = '9,0'
      note = 'Objectif < 9,0% et/ou glycémies capillaires préprendiales entre 1 et 2 g/L en restant au-dessus de 8,0% avec des glycémies préprandiales > 1,40 g/L en cas de traitement par sulfamide, glinide ou insuline.'
      return { hba, note }
    }
    if (patientAge >= 75 && state.patient.health === 2) { // Patient agé et fragile
      hba = '8,0'
      note = 'Objectif < 8,0% en restant au dessus de 7,0% en cas de traitement par sulfamide, glinide ou insuline.'
      return { hba, note }
    }
    if (state.patient.lifeExpectancy === 1 // Esperance de vie limité
    || (hasRenalDisease && state.patient.dfg < 30) // IRC sévère ou terminale
    || state.patient.comorbidite // comorbidité sévère
    ) {
      hba = '8,0'
      note = 'Objectif < 8,0% en restant au dessus de 7,0% en cas de traitement par sulfamide, glinide ou insuline.'
      return { hba, note }
    }
    if (patientAge >= 75) { // Patient agé et en bonne santé
      hba = '7,0'
      note = 'Objectif < 7,0% pour les personnes agées de plus de 75 ans dites "en bonne santé".'
    } else { // Patient jeune et en bonne santé
      hba = '7,0'
      subtitle = 'voire 6,5%'
      note = "Objectif < 7,0% voire < 6,5% à condition que cet objectif soit atteignable grâce aux modifications du mode de vie et/ou à des traitements ne provoquant pas d'hypoglycémie."
    }

    // Ici on rajoute la condition du diabète depuis plus de 10 ans avec risque d'hypoglycémie
    if (yearsOfDiabetes > 10) {
      note += "\n\nSi la cible de 7,0% s'avère difficile à atteindre car l'intensification thérapeutique expose au risque d'hypoglycémies sévères, un objectif de 8,0% peut être envisagé en restant au-dessus de 7,0% en cas de traitement par sulfamide, glinide ou insuline."
    }

    return { hba, subtitle, note }
  }

  if (state.recommandation.recommandation === sfd2023) {
    let patientTirTable = 'normal'
    if (patientAge >= 75 && state.patient.health === 3) { // Patient agé et dépendant
      hba = '9,0'
      note = ['Objectif < 9,0% et/ou glycémies capillaires préprendiales entre 1 et 2 g/L en restant au-dessus de 7,5% avec des glycémies préprandiales > 1,40 g/L en cas de traitement par sulfamide, glinide ou insuline.']
      patientTirTable = 'weak'
      return { hba, tir: tirTables[patientTirTable], note }
    }
    if (patientAge >= 75 && state.patient.health === 2) { // Patient agé et fragile
      hba = '8,0'
      note = ['Objectif < 8,0% en restant au dessus de 7,0% en cas de traitement par sulfamide, glinide ou insuline.']
      patientTirTable = 'weak'
      return { hba, tir: tirTables[patientTirTable], note }
    }
    if (state.patient.lifeExpectancy === 1 // Esperance de vie limité
      || (hasRenalDisease && state.patient.dfg < 30) // IRC sévère ou terminale
      || state.patient.comorbidite // comorbidité sévère
    ) {
      hba = '8,0'
      note = ['Objectif < 8,0% en restant au dessus de 7,0% en cas de traitement par sulfamide, glinide ou insuline.']
      patientTirTable = 'weak'
      return { hba, tir: tirTables[patientTirTable], note }
    }
    if (patientAge >= 75) { // Patient agé et en bonne santé
      hba = '7,0'
      note = ['Objectif < 7,0% pour les personnes agées  de plus de 75 ans dites "en bonne santé".']
    } else { // Patient jeune et en bonne santé
      hba = '7,0'
      subtitle = 'voire 6,5%'
      note = ["Objectif < 7,0% voire < 6,5% à condition que cet objectif soit atteignable grâce aux modifications du mode de vie et/ou à des traitements ne provoquant pas d'hypoglycémie."]
    }

    // Ici on rajoute la condition du diabète depuis plus de 10 ans avec risque d'hypoglycémie
    if (yearsOfDiabetes > 10) {
      note.push("Si la cible de 7,0% s'avère difficile à atteindre car l'intensification thérapeutique expose au risque d'hypoglycémies sévères, un objectif de 8,0% peut être envisagé en restant au-dessus de 7,0% en cas de traitement par sulfamide, glinide ou insuline.")
    }

    return { hba, tir: tirTables[patientTirTable], subtitle, note }
  }

  if (state.recommandation.recommandation === ada2023) {
    if (state.patient.lifeExpectancy === 1) { // esperance de vie limitée
      hba = '8,0'
      note = 'Selon ADA 2023, les patients ayant une espérance de vie limitée ont un objectif d\'HbA1c < 8,0% en limitant au maximum le risque d\'hypoglycémies.'
      return { hba, note }
    }
    if (hasRenalDisease && state.patient.dfg < 30) { // IRC sévère
      hba = '8,0'
      note = 'Selon ADA 2023, l\'objectif d\'HbA1c le plus souvent recommandé chez les patients présentant une insuffisance rénale chronique sévère ou terminale est de 8,0% en limitant au maximum le risque d\'hypoglycémies.'
      return { hba, note }
    }
    if (state.patient.comorbidite) { // Autre pathologie sévère
      hba = '8,0'
      note = 'Selon ADA 2023, l\'objectif d\'HbA1c le plus souvent recommandé chez les patients présentant une ou plusieurs comorbidités sévère est de 8,0% en limitant au maximum le risque d\'hypoglycémies.'
      return { hba, note }
    }
    if (patientAge >= 65 && (state.patient.health === 2 || state.patient.health === 3)) { // Fragile ou dépendant uniquement
      hba = '8,0'
      note = state.patient.health === 2
        ? 'Selon ADA 2023, l\'objectif d\'HbA1c le plus souvent recommandé chez les patients âgés fragiles est de 8,0% en limitant au maximum le risque d\'hypoglycémies.'
        : 'Selon ADA 2023, l\'objectif principal chez les patients âgés dépendants et/ou à la santé très altérée n\'est pas d\'obtenir une HbA1c cible mais d\'éviter les hypoglycémies et les hyperglycémies symptomatiques.'
      return { hba, note }
    }
    hba = '7,0'
    note = patientAge >= 65
      ? 'Selon ADA 2023, l\'objectif d\'HbA1c les patients de plus de 65 ans en bonne santé est de 7,0% - 7,5% pouvant aller jusqu\'à 8,0% en fonction du profil du patient en limitant au maximum le risque d\'hypoglycémies.'
      : 'Selon ADA 2023, objectif < 7,0% à moduler entre 6,5% si cette cible peut être atteinte sans hypoglycémies et 8,0% en limitant au maximum le risque d\'hypoglycémies, en fonction du profil du patient.'
    return { hba, note }
  }
  if (state.recommandation.recommandation === esc2023) { // On entre dans cette reco UNIQUEMENT si maladie athéromateuse ou IC. Condition dans List.js
    if (state.patient.lifeExpectancy === 1) {
      hba = '8,5'
      note = 'Selon ESC 2023, les patients atteints d\'une maladie cardiovasculaire et ayant une espérance de vie limitée ont un objectif d\'HbA1c < 8,0% en utilisant des molécules ayant démontré un effet cadioprotecteur, et en limitant au maximum le risque d\'hypoglycémies.'
    } else {
      hba = '7,0'
      note = 'Selon ESC 2023, ce patient a un objectif d\'HbA1c < 7,0% en utilisant des molécules ayant démontré un effet cadioprotecteur, et en limitant au maximum le risque d\'hypoglycémies.'
    }
    return { hba, note }
  }
  if ([adakdigo2022, adaeasd2022].includes(state.recommandation.recommandation)) { // Memes recommandations ici
    return {
      hba: '6,5 - 8,0',
      note: "Chez les patients présentant une maladie rénale chronique, l'objectif d'HbA1c se situe entre 6,5% et 8,0% en fonction du DFG et du profil clinique du patient.",
      subtitle: 'selon profil',
      bannerInfo: 'pour une évaluation plus précise de la cible, on pourra se référer aux recommandations de la SFD 2021 ou de l\'ADA 2023.'
    }
  }
}

function getObjectifsLipidiques () {
  const state = store.getState()
  let result = null
  if (state.recommandation.recommandation === esc2023) {
    const patientRisk = getRisqueCardio().value
    if (patientRisk === 4) {
      return {
        ldl: '0,55',
        note: 'Selon recommandations ESC 2023, pour les patients ayant un risque cardiovasculaire très élevé, LDL-C < 0,55 g/L et réduction du LDL-C d\'au moins 50%.'
      }
    }
    if (patientRisk === 3) {
      return {
        ldl: '0,7',
        note: 'Selon recommandations ESC 2023, pour les patients ayant un risque cardiovasculaire élevé, LDL-C < 0,7 g/L et réduction du LDL-C d\'au moins 50%.'
      }
    }
    return {
      ldl: '1,0',
      note: 'Selon recommandations ESC 2023, LDL-C < 1,0 g/L.'
    }
  } else if (state.recommandation.recommandation === esc2021) {
    const patientRisk = getRisqueCardio().value
    if (patientRisk === 4) {
      return {
        ldl: '0,55',
        note: 'Selon recommandations ESC 2021, pour les patients ayant un risque cardiovasculaire très élevé, LDL-C < 0,55 g/L et réduction du LDL-C d\'au moins 50%. En fonction du profil clinique, des comorbidités ou des préférences du patient et/ou si cette cible s’avère difficile à atteindre, un objectif de LDL < 0,7 g/L pourra être proposé.'
      }
    }
    if (patientRisk === 3) {
      return {
        ldl: '0,7',
        note: 'Selon recommandations ESC 2021, pour les patients ayant un risque cardiovasculaire élevé, LDL-C < 0,7 g/L et réduction du LDL-C d\'au moins 50%. En fonction du profil clinique, des comorbidités ou des préférences du patient et/ou si cette cible s’avère difficile à atteindre, un objectif de LDL < 1,0 g/L pourra être proposé.'
      }
    }
    return {
      ldl: '1,0',
      note: 'Selon recommandations ESC 2021, LDL-C < 1,0 g/L.'
    }
  } else if (state.recommandation.recommandation === sfdsfc2021) {
    const patientRisk = getRisqueCardio().value
    if (patientRisk === 4) {
      return {
        ldl: '0,55',
        note: 'Selon recommandations SFD/SFC 2021, pour les patients ayant un risque cardiovasculaire très élevé, LDL-C < 0,55 g/L et non HDL-C < 0,85 g/L.'
      }
    }
    if (patientRisk === 3) {
      return {
        ldl: '0,7',
        note: 'Selon recommandations SFD/SFC 2021, pour les patients ayant un risque cardiovasculaire élevé, LDL-C < 0,7 g/L et non HDL-C < 1,0 g/L.'
      }
    }
    return {
      ldl: '1,0',
      note: 'Selon recommandations SFD/SFC 2021, chez les patients ayant un risque cardiovasculaire modéré, LDL-C < 1,0 g/L et non HDL-C < 1,30 g/L.'
    }
  } else {
    result = { ldl: '1,3', note: 'Risque cardiovasculaire modéré, LDL-C < 1,3 g/L.' }
    const patientAge = state.patient.age
    const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
    const hasRenalDisease = state.patient.complications.some(i => i.id === 3)
    const isYoung = (state.patient.gender === 1 && patientAge < 50) || (state.patient.gender === 2 && patientAge < 60)
    let risks = 0
    if (state.patient.familleCV && (state.patient.gender === 1 && patientAge >= 50) || (state.patient.gender === 2 && patientAge >= 60)) {
      risks += 1
    }
    if (hasMaladieAtheromateuse && ((state.patient.gender === 1 && patientAge <= 50) || (state.patient.gender === 2 && patientAge <= 60))) {
      risks += 1
    }
    if (state.patient.smoking === 3 || (state.patient.smoking === 2 && state.patient.weanedSince === 1)) {
      risks += 1
    }
    if (state.patient.hta) {
      risks += 1
    }
    if (state.patient.hdl === 1) {
      risks += 1
    }
    if (hasRenalDisease && ((state.patient.dfg < 45 && !isYoung) || (state.patient.dfg < 60 && isYoung))) {
      risks += 1
    }
    if (risks === 1) {
      result = { ldl: '1,0', note: 'Risque cardiovasculaire élevé, LDL-C < 1,0 g/L.' }
    }
    if (risks > 1 || hasMaladieAtheromateuse || state.patient.complications.length >= 1) {
      result = { ldl: '0,7', note: 'Risque cardiovasculaire très élevé, LDL-C < 0,7 g/L.' }
    }
    return result
  }
}

function getObjectifsTensionnels () {
  const state = store.getState()
  const patientAge = state.patient.age
  let result = {}
  if (state.recommandation.recommandation === esc2023) {
    if (patientAge >= 65) {
      return {
        systolique: { label: '130 - 139' },
        diastolique: { label: '< 80' },
        note: 'Selon ESC 2023, chez les patients âgés de plus de 65 ans, cible de TA systolique inférieure à entre 130 et 139 mmHg dans descendre sous 120 mmHg, cible de TA diastolique inférieure à 80 mmHg.'
      }
    }
    return {
      systolique: { label: '< 130' },
      diastolique: { label: '< 80' },
      note: 'Selon ESC 2023, cible de TA systolique inférieure à 130 mmHg, cible de TA diastolique inférieure à 80 mmHg.'
    }
  } else if (state.recommandation.recommandation === esc2021) {
    if (patientAge >= 70) {
      return {
        systolique: { label: '< 140' },
        diastolique: { label: '< 80' },
        note: 'Selon ESC 2021, chez les patients âgés de plus de 70 ans, cible de TA systolique inférieure à 140 mmHg, cible de TA diastolique inférieure à 80 mmHg.'
      }
    }
    const patientRisk = getRisqueCardio().value
    if (patientRisk === 3 || patientRisk === 4) {
      return {
        systolique: { label: '120 - 130' },
        diastolique: { label: '< 80' },
        note: 'Selon ESC 2021, chez les patients ayant un risque cardiovasculaire élevé ou très élevé, il est recommandé que le premier objectif soit compris entre 120/80 mmHg et 130/80 mmHg. En fonction du profil clinique, des comorbidités ou des préférences du patient et/ou si cette cible s’avère difficile à atteindre, un objectif compris entre 130/80 mmHg et 140/80 mmHg pourra être proposé.'
      }
    }
    return {
      systolique: { label: '130 - 140' },
      diastolique: { label: '< 80' },
      note: 'Selon ESC 2021, chez les patients ayant un risque cardiovasculaire modéré, cible de TA systolique entre à 130 et 140 mmHg, cible de TA diastolique inférieure à 80 mmHg.'
    }
  } else if (state.recommandation.recommandation === sfdsfc2021) {
    return {
      systolique: { label: '< 130' },
      diastolique: { label: '< 80' },
      note: 'Selon SFD/SFC 2021, il est recommandé que l\'objectif soit inférieur à 130/80 mmHg pour l\'ensemble des patients.'
    }
  } else { // hassfhta2016
    let note = 'Des données récentes suggèrent que la PAS optimale pourrait être plus basse que 130 mmHg, voire de l’ordre de 120 mmHg chez certains patients.'
    if (patientAge < 80) {
      result = {
        systolique: { label: '130 - 139' },
        diastolique: { label: '< 90' },
        note: note
      }
    } else {
      note = 'Pour les patients de plus de 80 ans, la lutte contre une iatrogénie est impérative. Le fait de ne pas dépasser dans la plupart des cas 3 molécules antihypertensives entre dans ce cas.\n\n' + note
      result = {
        systolique: { label: '< 150' },
        diastolique: { label: '< 90' },
        note: note
      }
    }
    return result
  }
}

export function getRisqueCardio (withScore = true) {
  const state = store.getState()
  const patientAge = state.patient.age
  const yearsOfDiabetes = parseInt(moment().year()) - parseInt(state.patient.diabetesSince)
  if (state.recommandation.recommandation === esc2023) {
    const result = {
      value: 'unknown', // Without score2 diabetes, the value is unkown
      note: 'Selon recommandations ESC 2023, '
    }

    const score2 = state.patient.score2
    const hasRetino = state.patient.complications.some(i => i.id === 1)
    const hasNeuropathie = state.patient.complications.some(i => i.id === 'neuropathie')
    const hasRenalDisease = state.patient.complications.some(i => i.id === 3)
    const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
    const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
    const hasAlbuminurie = hasRenalDisease && state.patient.albuminurie && state.patient.albuminurie !== 'under_30'

    if (hasMaladieAtheromateuse || hasInsuffisanceCardiaque) { // Maladie cardiovasculaire avérée
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasRenalDisease && state.patient.dfg < 45) {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasRenalDisease
      && (state.patient.dfg >= 45 && state.patient.dfg < 60)
      && (hasAlbuminurie && state.patient.albuminurie === 'between_30_and_300')) {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasAlbuminurie && state.patient.albuminurie === 'over_300') {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasRetino && hasNeuropathie && hasAlbuminurie) {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }

    if (withScore) {
      if (score2 === 'over_20') {
        result.value = 4
        result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      }
      if (score2 === 'between_10_and_20') {
        result.value = 3
        result.note += 'ce patient présente un risque cardiovasculaire élevé.'
      }
      if (score2 === 'between_5_and_10') {
        result.value = 2
        result.note += 'ce patient présente un risque cardiovasculaire modéré.'
      }
      if (score2 === 'below_5') {
        result.value = 1
        result.note += 'ce patient présente un risque cardiovasculaire faible.'
      }
    }
    return result
  }
  if (state.recommandation.recommandation === esc2021) {
    const result = {
      value: 2,
      note: 'Selon recommandations ESC 2021, '
    }
    const hasRetino = state.patient.complications.some(i => i.id === 1)
    const hasNeuropathie = state.patient.complications.some(i => i.id === 'neuropathie')
    const hasRenalDisease = state.patient.complications.some(i => i.id === 3)
    const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
    const hasAlbuminurie = hasRenalDisease && state.patient.albuminurie && state.patient.albuminurie !== 'under_30'
    const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
    if (hasMaladieAtheromateuse) {//CVD
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasRenalDisease && state.patient.dfg < 45) {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasRenalDisease
      && (state.patient.dfg >= 45 && state.patient.dfg < 60)
      && (hasAlbuminurie && state.patient.albuminurie === 'between_30_and_300')) {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasAlbuminurie && state.patient.albuminurie === 'over_300') {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasRetino && hasNeuropathie && hasAlbuminurie) {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (hasInsuffisanceCardiaque) {
      result.value = 4
      result.note += 'ce patient présente un risque cardiovasculaire très élevé.'
      return result
    }
    if (!state.patient.complications.length
      && parseFloat(state.patient.last_hba1c) <= 7
      && yearsOfDiabetes < 10
      && !state.patient.dyslipidemie
      && !state.patient.hta
      && (!state.patient.smoking || (state.patient.smoking === 2 && state.patient.weanedSince === 2))
      && imc(state.patient.weight, state.patient.height) < 30
    ) {
      result.value = 2
      result.note += 'ce patient présente un risque cardiovasculaire modéré.'
      return result
    }
    result.value = 3
    result.note += 'ce patient présente un risque cardiovasculaire élevé. La réalisation d\'un score calcique peut être utile pour reclassifier le risque de ce patient.'
    return result
  } else if (state.recommandation.recommandation === sfdsfc2021) {
    const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
    const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
    const hasRenalDisease = state.patient.complications.some(i => i.id === 3)
    const hasAlbuminurie = hasRenalDisease && state.patient.albuminurie && state.patient.albuminurie !== 'under_30'
    const hasSevereRetino = state.patient.complications.some(i => i.id === 1) && state.patient.severeRetino

    if (hasMaladieAtheromateuse
      || state.patient.fibrillation
      || hasInsuffisanceCardiaque
      || state.patient.ldl === 'above_and_trated'
      || state.patient.albuminurie === 'over_300'
      || state.patient.dfg < 30
      || state.patient.ondesQ
      || state.patient.ventriculaireAnormale) {
      return {
        value: 4,
        note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire très élevé.'
      }
    }
    let note = 0
    if (yearsOfDiabetes >= 10) {
      note += 1
    }
    if (state.patient.familleCV === 'yes_early') {
      note += 1
    }
    if (state.patient.fdrNonControle) {
      note += 1
    }
    if (hasAlbuminurie) {
      note += 1
    }
    if (state.patient.dfg < 60) { // Entre 30 et 59
      note += 1
    }
    if (hasSevereRetino || state.patient.neuropathieAutonome || state.patient.dysfonctionErectile) {
      note += 1
    }
    if (state.patient.sedentary) {
      note += 1
    }
    if (note >= 2) { // Risque élevé
      const scoreCalcique = withScore ? state.patient.scoreCalcique : null //disable score calcique
      if (scoreCalcique === 'below_10') {
        return {
          value: 2,
          note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire modéré.'
        }
      }
      if (scoreCalcique === 'between_11_and_100') {
        if (patientAge >= 50) {
          return {
            value: 2,
            note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire modéré.'
          }
        } else {
          return {
            value: 3,
            note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire élevé.'
          }
        }
      }
      if (scoreCalcique === 'between_101_and_400') {
        if (patientAge >= 60) {
          return {
            value: 3,
            note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire élevé.'
          }
        } else {
          return {
            value: 4,
            note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire très élevé.'
          }
        }
      }
      if (scoreCalcique === 'above_400') {
        return {
          value: 4,
          note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire très élevé.'
        }
      }
      return {
        value: 3,
        note: "Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire élevé.\n\nAttention : dans le cas d'un risque élevé, le score calcique est nécessaire afin de requalifier le risque."
      }
    }
    return {
      value: 2,
      note: 'Selon SFD/SFC 2021, le patient possède un risque cardiovasculaire modéré.'
    }
  }
}

export function selectRecommandation (recommandation) {
  return {
    type: 'SELECT_RECOMMANDATION',
    recommandation
  }
}

export function objectifsGlycemiques () {
  return {
    type: 'OBJECTIFS_GLYCEMIQUES',
    objectifsGlycemiques: getObjectifsGlycemiques()
  }
}

export function objectifsLipidiques () {
  return {
    type: 'OBJECTIFS_LIPIDIQUES',
    objectifsLipidiques: getObjectifsLipidiques()
  }
}

export function objectifsTensionnels () {
  return {
    type: 'OBJECTIFS_TENSIONNELS',
    objectifsTensionnels: getObjectifsTensionnels()
  }
}

export function risqueCardio () {
  return {
    type: 'RISQUE_CARDIO',
    risqueCardio: getRisqueCardio()
  }
}

export function strategieTherapeutique () {
  return {
    type: 'STRATEGIE_THERAPEUTIQUE',
    strategieTherapeutique: getStrategieTherapeutique()
  }
}

export function resetRecommandation () {
  return {
    type: 'RESET_RECOMMANDATION',
    recommandation: null
  }
}

export function saveMethodToRunAfterMoreInformations (method) {
  return {
    type: 'SAVE_METHOD_TO_RUN_AFTER_MORE_INFORMATIONS',
    methodToRunAfterMoreInformations: method
  }
}
