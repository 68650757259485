import { complications } from '../../views/steps/utils'

const initialState = {
  age: null,
  health: null,
  gender: null,
  menopause: false,
  weight: '80,0',
  height: '1,70',
  smoking: false,
  hta: false,
  weanedSince: null,
  comorbidite: false,
  lifeExpectancy: 2,
  diabetes: 2,
  diabetesSince: null,
  treatment: null,
  complications: [],
  albuminurie: 'under_30',
  ejection: 1,
  dfg: 60,
  last_hba1c: '7,0',
  risk: 2,
  ldl: 'below',
  familleCV: false,
  antecedentAVC: false,
  metformine: null,
  has_isglt2: null,
  hasIsglt2Intolerance: null,
  sedentary: null,
  ondesQ: false,
  severeRetino: null,
  ventriculaireAnormale: false,
  dysfonctionErectile: false,
  dyslipidemie: false,
  neuropathieAutonome: false,
  fibrillation: false,
  fdrNonControle: false,
  scoreCalcique: null,
  isHighRiskCV: false,
  score2: null
}

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case 'UPDATE_PATIENT': {
      const newState = {
        ...state,
        [action.key]: action.value
      }
      if (newState.age < 65) {
        newState.health = null
      }
      if (newState.dfg && newState.dfg < 60 || newState.albuminurie && newState.albuminurie !== 'under_30') { // Add MRC if albuminurie or DFG < 60
        if (newState.complications.indexOf(complications.find(i => i.id === 3)) === -1) {
          newState.complications.push(complications.find(i => i.id === 3))
        }
      } else {
        newState.complications = newState.complications.filter(i => i.id !== 3)
      }
      if (!newState.complications.some(i => i.id === 5)) { // RESET FRACTION EJECTION
        newState.ejection = 1
      }
      return newState
    }
    case 'LOAD_PATIENT_PROFILE': {
      return {
        ...state,
        ...action.patientProfile
      }
    }
    case 'RESET_PATIENT': {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}
