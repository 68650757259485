import store from '../../../store/store'
import { upperFirstCase } from '../../../utils'

const dfg = store.getState().patient.dfg
const hasRenalImpairment = dfg < 60

export const getAuthorizedGlp1 = () => {
  const glp1 = []
  if (dfg >= 30) {
    glp1.push('exenatide (avec prudence)')
  }
  if (dfg < 30) {
    glp1.push('lixisenatide')
  }
  if (dfg < 15) {
    glp1.push('liraglutide')
  }

  return upperFirstCase(glp1.join(', '))
}
export const getAuthorizedIsglt2 = () => {
  if (!hasRenalImpairment || dfg >= 30) {
    return 'iSGLT2'
  }
  const isglt2 = ['dapagliflozine']
  if (dfg >= 20) {
    isglt2.push('empagliflozine')
    return upperFirstCase(isglt2.join(', '))
  }
  return null
}

export const getAuthorizedMetformine = () => {
  if (!hasRenalImpairment) {
    return 'Metformine'
  }
  if (dfg >= 45) {
    return 'Metformine (à 2g/jour maximum)'
  }
  if (dfg >= 30) {
    return 'Metformine (à 1g/jour maximum)'
  }
  return null
}

export const getAuthorizedIdpp4 = () => {
  return dfg < 45 ? 'iDPP4 (utilisation possible, avec adaptation de dose sauf pour la linagliptine)' : 'iDPP4'
}

export const getAuthorizedSulfamides = () => {
  return dfg < 45 ? 'Sulfamides (utilisation prudente avec adaptation de dose en raison du risque hypoglycémique, glibenclamide non recommandé)' : 'Sulfamides'
}
