import { AsyncStorage, Dimensions, Linking, Platform } from 'react-native'
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import variables from '../assets/stylesheets/variables'

export const imc = (weight, height) => {
  const numberHeight = typeof height === 'number' ? height : parseFloat(height.replace(',', '.'))
  const numberWeight = typeof weight === 'number' ? weight : parseFloat(weight.replace(',', '.'))
  return (numberWeight/(numberHeight*numberHeight)).toFixed(1)
}

export const toLocaleString = (number, digits = 0) => {
  if (typeof number === 'number') {
    return number.toFixed(digits).replace('.', ',')
  }
  return parseFloat(number.replace(',', '.')).toFixed(digits).replace('.', ',')
}

export const scaleFontSize = (fontSize) => {
  const SCREEN_WIDTH = Dimensions.get('window').width
  const SCALE = 375

  const ratio = fontSize / SCALE
  const newSize = Math.round(ratio * SCREEN_WIDTH)
  if (newSize < fontSize - 2) {
    return fontSize - 2
  }
  if (newSize > fontSize + 2) {
    return fontSize + 2
  }
  return newSize
}

export const openWebBrowser = async (url) => {
  try {
    if (Platform.OS !== 'web' && await InAppBrowser.isAvailable()) {
      await InAppBrowser.open(url, {
        // iOS Properties
        dismissButtonStyle: 'done',
        preferredBarTintColor: 'white',
        preferredControlTintColor: variables.primaryColor,
        readerMode: false,
        animated: true,
        modalPresentationStyle: 'fullScreen',
        modalTransitionStyle: 'coverVertical',
        modalEnabled: false,
        enableBarCollapsing: false,
        // Android Properties
        showTitle: true,
        toolbarColor: 'white',
        secondaryToolbarColor: variables.primaryColor,
        enableUrlBarHiding: true,
        enableDefaultShare: true,
        forceCloseOnRedirection: false,
        animations: {
          startEnter: 'slide_in_right',
          startExit: 'slide_out_left',
          endEnter: 'slide_in_left',
          endExit: 'slide_out_right'
        }
      })
    } else {
      Linking.openURL(url)
    }
  } catch (error) {
    alert(error.message)
  }
}

export const upperFirstCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getAsyncStorageItem = (key) => {
  if (Platform.OS === 'web') {
    return
  }
  return AsyncStorage.getItem(key)
}

export const isXlScreen = Dimensions.get('window').width >= 375
export const isSmScreen = Dimensions.get('window').width < 375
export const isXsScreen = Dimensions.get('window').width <= 320
