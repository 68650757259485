import React from 'react'
import Text from '../Text'

class ModalAntecedentCardio extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Text style={{ fontSize: 15, lineHeight: 24 }}>
        Antécédent d’événement vasculaire significatif
        (IDM, AVC ischémique, revascularisation, amputation en lien avec une ischémie…)
        ou lésion athéromateuse significative (sténose de plus de 50% sur une coronaire,
        une carotide ou une artère des membres inférieurs ; angor instable/ischémie myocardique
        silencieuse avec atteinte documentée par imagerie ou test fonctionnel ; claudication
        intermittente avec IPS inférieur à 0,9).
      </Text>
    )
  }
}

export default ModalAntecedentCardio
