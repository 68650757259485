import React from 'react'
import { Image, View, Platform } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import Home from '../views/Home'
import FirstStep from '../views/steps/FirstStep'
import SecondStep from '../views/steps/SecondStep'
import ThirdStep from '../views/steps/ThirdStep'
import List from '../views/steps/List'
import Objectifs from '../views/steps/Objectifs'
import Lipides from '../views/steps/Lipides'
import Tension from '../views/steps/Tension'
import Cardiovasculaire from '../views/steps/Cardiovasculaire'
import StrategieTherapeutique from '../views/steps/StrategieTherapeutique'
import Notifications from '../views/Notifications'
import LiensUtiles from '../views/steps/LiensUtiles'
import EntypoIcon from 'react-native-vector-icons/Entypo'
import Ionicon from 'react-native-vector-icons/Ionicons'
import More from '../views/More'
import variables from '../assets/stylesheets/variables'
import MoreInformations from '../views/steps/MoreInformations'
import tabBarStyle from '../assets/stylesheets/components/tabBar'
import TabBar from '../components/TabBar'

import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { NavigationService } from './NavigationService'
import Preferences from '../views/Preferences'
import { Diabetowise } from '../views/Diabetowise'

const Tab = createBottomTabNavigator()
const Stack = createStackNavigator()

const HomeTabNavigator = () => (
  <Tab.Navigator tabBar={Platform.OS === 'web' ? props => <TabBar {...props} /> : undefined}
                 screenOptions={{
                   title: 'DiabGuide | Application',
                   headerShown: false,
                   tabBarShowLabel: false,
                   tabBarActiveTintColor: variables.primaryColor,
                   tabBarInactiveTintColor: '#D5D9E2',
                   tabBarStyle: {
                     backgroundColor: 'transparent',
                     ...tabBarStyle.tabBar
                   }
                 }}>
    <Tab.Screen name="Home"
                component={Home}
                options={{
                  tabBarLabel: 'Accueil',
                  tabBarIcon: ({ color, focused }) => (
                    <View style={{ cursor: 'pointer' }}>
                      <Image style={{ width: 26, height: 26, tintColor: color }}
                             source={require('../assets/img/ic-home.png')} />
                    </View>
                  )
                }} />
    <Tab.Screen name="HomeLiensUtiles"
                component={LiensUtiles}
                options={{
                  tabBarLabel: 'Bibliographie',
                  tabBarIcon: ({ color, focused }) => (
                    <View style={{ cursor: 'pointer' }}>
                      <Ionicon size={27}
                               style={{ marginTop: 6 }}
                               color={color}
                               name="book" />
                    </View>
                  )
                }}/>
    <Tab.Screen name="More"
                component={More}
                options={{
                  tabBarLabel: 'Plus d\'infos',
                  tabBarIcon: ({ color, focused }) => (
                    <View style={{ cursor: 'pointer' }}>
                      <EntypoIcon size={30}
                                  style={{ marginTop: 4 }}
                                  color={color}
                                  name="dots-three-horizontal" />
                    </View>
                  )
                }} />
  </Tab.Navigator>
)

const StepsNavigator = () => (
  <Stack.Navigator screenOptions={{
                     title: 'DiabGuide | Application',
                     headerShown: false,
                     animationEnabled: true,
                     cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
                  }}>
    <Stack.Screen name="FirstStep"
                  component={FirstStep} />
    <Stack.Screen name="SecondStep"
                  component={SecondStep} />
    <Stack.Screen name="ThirdStep"
                  component={ThirdStep} />
    <Stack.Screen name="List"
                  component={List} />
    <Stack.Screen name="MoreInformations"
                  component={MoreInformations} />
    <Stack.Screen name="Objectifs"
                  component={Objectifs} />
    <Stack.Screen name="Lipides"
                  component={Lipides} />
    <Stack.Screen name="Tension"
                  component={Tension} />
    <Stack.Screen name="Cardiovasculaire"
                  component={Cardiovasculaire} />
    <Stack.Screen name="StrategieTherapeutique"
                  component={StrategieTherapeutique} />
    <Stack.Screen name="LiensUtiles"
                  component={LiensUtiles} />
  </Stack.Navigator>
)

const FirstOpeningNavigator = () => (
  <Stack.Navigator screenOptions={{ headerShown: false, cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS, gestureEnabled: false }}>
    <Stack.Screen name="Notifications"
                  component={Notifications} />
    <Stack.Screen name="Preferences"
                  component={Preferences} />
  </Stack.Navigator>
)

const linking = {
  config: {
    screens: {
      Diabetowise: { path: '/diabetowise' }
    }
  },
  getPathFromState(state, config) {
    return '/'
  }
}
const Navigator = () => (
  <NavigationContainer ref={NavigationService}
                       linking={linking}>
    <Stack.Navigator screenOptions={{
                       title: 'DiabGuide | Application',
                       headerShown: false,
                       animationEnabled: true,
                       cardOverlayEnabled: true,
                       cardShadowEnabled: true,
                       presentation: 'modal',
                       cardStyleInterpolator: Platform.OS === 'web' ? CardStyleInterpolators.forScaleFromCenterAndroid : CardStyleInterpolators.forVerticalIOS
                     }}>
      <Stack.Screen name="HomeTab"
                    component={HomeTabNavigator} />
      <Stack.Screen name="FirstOpeningNavigator"
                    component={FirstOpeningNavigator}
                    options={{ gestureEnabled: false }} />
      <Stack.Screen name="Steps"
                    component={StepsNavigator}
                    options={{ gestureEnabled: false }} />
      {Platform.OS === 'web' && (
        <Stack.Screen name="Diabetowise"
                      component={Diabetowise}
                      options={{ gestureEnabled: false }} />
      )}
    </Stack.Navigator>
  </NavigationContainer>
)
export default Navigator
