import { complications } from '../steps/utils'

const patientKeys = {
  age: { key: 'age' },
  dys: { // Useless for DiabetoWise for ESC2021 because patient has to have at least 1 complication
    key: 'dyslipidemie',
    mapping: {
      true: true,
      false: false,
      unknown: null,
      null: null,
      undefined: null
    }
  },
  smo: { // Useless for DiabetoWise for ESC2021 because patient has to have at least 1 complication
    key: 'smoking',
    mapping: {
      true: 3,
      false: false,
      unknown: false,
      null: null,
      undefined: null
    }
  },
  dfg: {
    key: 'dfg',
    mapping: {
      null: 60
    }
  },
  state: { key: 'health' },
  exp: {
    key: 'lifeExpectancy',
    mapping: { // j'ai dit a Semeia de dire true si l'espérance est SUPÉRIEURE
      true: 2,
      false: 1
    }
  },
  fevg: {
    key: 'ejection',
    label: "Fraction d'éjection",
    mapping: {
      over_40: 1,
      under_40: 2,
      unknown: 1,
      null: 1
    }
  },
  hba: { key: 'last_hba1c' },
  height: { key: 'height' },
  weight: { key: 'weight' },
  year: { key: 'diabetesSince' },
  hta: {
    key: 'hta',
    label: 'Hypertension artérielle',
    mapping: {
      treated: 'treated',
      yes: 'known',
      no: false,
      unknown: null
    }
  },
  ic: {
    key: 'ic',
    label: 'Insuffisance cardiaque',
    type: 'complication',
    id: 5,
    mapping: {
      true: true,
      false: false,
      unknown: null
    }
  },
  mcva: {
    key: 'mcva',
    label: 'Maladie athéromateuse',
    type: 'complication',
    id: 4,
    mapping: {
      true: true,
      false: false,
      unknown: null
    }
  },
  met: {
    key: 'metformine',
    mapping: {
      true: true,
      false: false
    }
  },
  mrc: {
    key: 'mrc',
    label: 'Maladie rénale chronique',
    type: 'complication',
    id: 3,
    mapping: {
      true: true,
      false: false,
      unknown: null
    }
  },
  neur: {
    key: 'neur',
    label: 'Neuropathie',
    type: 'complication',
    id: 'neuropathie',
    mapping: {
      true: true,
      false: false,
      unknown: null
    }
  },
  oth: {
    key: 'comorbidite',
    label: 'Autres comorbidités',
    mapping: {
      true: true,
      false: false,
      unknown: null
    }
  },
  rac: {
    key: 'albuminurie',
    label: 'Albuminurie',
    mapping: {
      under_30: 'under_30',
      between_30_and_300: 'between_30_and_300',
      over_300: 'over_300',
      unknown: null
    }
  },
  reti: {
    key: 'reti',
    label: 'Rétinopathie',
    type: 'complication',
    id: 1,
    mapping: {
      true: true,
      false: false,
      unknown: null
    }
  }
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export const parseAndFormatPatientProfile = (payload) => {
  const patient = {}
  const patientComplications = []
  for (let key in payload) {
    const patientKey = patientKeys[key]
    if (patientKey) {
      const mappingValue = patientKey.mapping?.hasOwnProperty(payload[key]) ? patientKey.mapping?.[payload[key]] : payload[key]
      if (patientKey.type === 'complication') {
        mappingValue && patientComplications.push(complications.find(c => c.id === patientKey.id))
      } else {
        patient[patientKey.key] = mappingValue
      }
    }
  }
  patient.complications = patientComplications
  patient.height = patient.height/100 // On a besoin de la taille en m et elle nous est envoyée en cm
  return patient
}

const getWarningsFromUnknownValues = (data) => {
  const missingFields = Object.entries(data).filter(i => i[1] === 'unknown').map(i => patientKeys[i[0]].label)
  return missingFields && missingFields.length
    ? [`Les champs suivants sont requis pour de meilleures recommandations : ${missingFields.join(', ')}`]
    : []
}

export const formatPayloadForSemeiaAPI = (recommandations, data) => {
  return {
    data: {
      attributes: {
        'occurred-at': `${new Date()}`,
        warning: getWarningsFromUnknownValues(data),
        data: {
          'cardio-risk': recommandations.risqueCardio,
          glycemic_objectives: recommandations.objectifsGlycemiques,
          lipid_objectives: recommandations.objectifsLipidiques,
          blood_pressure_objectives: {
            ...recommandations.objectifsTensionnels,
            systolic: recommandations.objectifsTensionnels.systolique,
            diastolic: recommandations.objectifsTensionnels.diastolique
          },
          therapeutic_strategy: recommandations.strategieTherapeutique
        }
      },
      relationships: {
        patient: {
          data: {
            type: 'patients',
            id: data.patient_id
          }
        },
        'diabguide-recommendation-started-journal-event': {
          data: {
            type: 'diabguide-recommendation-started-journal-events',
            id: data.origin_journal_event_id
          }
        }
      },
      type: 'diabguide-recommendation-computed-journal-events'
    }
  }
}

export const redirectToDiabetowise = (data, patientId) => {
  const redirectUrl = process.env.NODE_ENV === 'production'
    ? `https://wise.semeia.io/medics-web/patients/${patientId}/diabguide/edition-form/${data.id}`
    :`https://wise-staging.semeia.io/medics-web/patients/${patientId}/diabguide/edition-form/${data.id}`
  window.location.href = redirectUrl
}
