import React from 'react'
import { connect } from 'react-redux'
import variables from '../assets/stylesheets/variables'
import {
  View,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Animated,
  DatePickerIOS,
  Platform
} from 'react-native'
import { Picker as NativePicker } from '@react-native-picker/picker'
import Text from './Text'
import LinearGradient from 'react-native-linear-gradient'
import CheckButton from './CheckButton'
import { hidePicker } from '../store/actions/pickerActions'
import moment from 'moment'
import Icon from 'react-native-vector-icons/Ionicons'
import { showModal } from '../store/actions/modalActions'

class Picker extends React.Component {
  constructor(props) {
    super(props)
  }

  initSelectedValue = () => {
    switch (this.props.childView) {
      case 'choice': {
        return this.props.value
      }
      case 'date': {
        return this.props.value || new Date('1970-01-01')
      }
      case 'year': {
        return this.props.value || (moment().year() - 5)
      }
      default:
        return this.props.value
    }
  }

  state = {
    selectedValue: this.initSelectedValue(),
    units: this.props.childView === 'number' && this.props.value ? parseInt(this.props.value.toString().split(',')[0]) : this.props.options ? this.props.options.initialUnits : null,
    decimals: this.props.childView === 'number' && this.props.value ? parseInt(this.props.value.toString().split(',')[1]) : this.props.options ? this.props.options.initialDecimals : null,
    fadeAnim: new Animated.Value(0),
    translateAnim: new Animated.Value(400)
  }

  componentDidMount () {
    this.toggleRevealPicker(true)
  }

  containerStyle () {

    switch (this.props.childView) {
      case 'choice': {
        return styles.choiceContainer
      }
      case 'date': {
        return styles.dateContainer
      }
      case 'year': {
        return styles.dateContainer
      }
      case 'number': {
        return styles.numberContainer
      }
      default: {
        return styles.choiceContainer
      }
    }
  }

  isItemSelected = (choice) => {
    return this.state.selectedValue === choice.id
  }

  onValidateChoice = (choice) => {
    this.setState({
      selectedValue: choice.id
    })
    this.props.selectValue(choice.id)
    this.hidePicker()
  }

  onDateChange = (date) => {
    this.setState({
      selectedValue: date
    })
  }

  onUnitsChange = (units) => {
    this.setState({
      units
    })
  }

  onDecimalsChange = (decimals) => {
    this.setState({
      decimals
    })
  }

  onValidateNumber = () => {
    if (this.props.options.decimals) {
      this.props.selectValue(`${this.state.units},${this.state.decimals}`)
    } else {
      this.props.selectValue(`${this.state.units}`)
    }
    this.hidePicker()
  }


  onValidateDate = () => {
    this.props.selectValue(this.state.selectedValue)
    this.hidePicker()
  }

  toggleRevealPicker = (reveal) => {
    Animated.timing(
      this.state.fadeAnim,
      {
        toValue: reveal ? 1 : 0,
        duration: 200,
        useNativeDriver: false
      }
    ).start()
    Animated.timing(
      this.state.translateAnim,
      {
        toValue: reveal ? 0 : 400,
        duration: 200,
        useNativeDriver: false
      }
    ).start()
  }

  hidePicker = () => {
    this.toggleRevealPicker(false)
    setTimeout(() => {
      this.props.hidePicker()
    }, 200)
  }

  itemList = () => {
    return this.props.choices.map((choice, index) => (
      <TouchableHighlight key={choice.id}
                          activeOpacity={0.9}
                          underlayColor={variables.colorHoverItem}
                          onPress={() => this.onValidateChoice(choice)}>
        <View style={[styles.item, index < this.props.choices.length - 1 ? styles.itemBottomBorder : styles.itemLast]}>
          <CheckButton checked={this.isItemSelected(choice)}/>
          <Text style={this.isItemSelected(choice) ? styles.labelSelected : styles.label}>{choice.labelSelector || choice.label}</Text>
          {choice.info &&
          <TouchableOpacity activeOpacity={0.8}
                            onPress={() => this.props.showModal('', choice.info)}
                            style={{ width: 30, height: 30, alignItems: 'center', justifyContent: 'center' }}>
            <Icon style={{ marginTop: 4 }}
                  name="ios-help-circle-outline"
                  size={20}
                  color={variables.colorMidGray} />
          </TouchableOpacity>
          }
        </View>
      </TouchableHighlight>
    ))
  }

  choicePickerView = () => (
    <React.Fragment>
      <Text style={styles.choiceTitle}>{this.props.title}</Text>
      {this.itemList()}
    </React.Fragment>
  )

  datePickerView = () => (
    <React.Fragment>
      <View style={styles.titleContainer}>
        <Text style={styles.dateTitle}>{this.props.title}</Text>
        <TouchableOpacity activeOpacity={0.8}
                          hitSlop={10}
                          onPress={this.onValidateDate}>
          <Text style={styles.ok}>OK</Text>
        </TouchableOpacity>
      </View>
      <DatePickerIOS date={this.state.selectedValue}
                     onDateChange={this.onDateChange}
                     maximumDate={new Date()}
                     locale="fr"
                     mode="date" />
    </React.Fragment>
  )

  yearPickerView = () => {
    const years = []
    for (let i = 1900; i <= moment().year(); i++) {
      years.push(<NativePicker.Item label={`${i}`} value={i} key={'year' + i}/>)
    }
    return (
      <React.Fragment>
        <View style={styles.titleContainer}>
          <Text style={styles.dateTitle}>{this.props.title}</Text>
          <TouchableOpacity activeOpacity={0.8}
                            hitSlop={10}
                            onPress={this.onValidateDate}>
            <Text style={styles.ok}>OK</Text>
          </TouchableOpacity>
        </View>
        <NativePicker onValueChange={this.onDateChange}
                      selectedValue={this.state.selectedValue}>
          {years}
        </NativePicker>
      </React.Fragment>
    )
  }

  NumberPickerView = () => {
    const units = []
    const decimals = []
    if (this.props.options.hasLower) {
      units.push(<NativePicker.Item label={`< ${this.props.options.minUnit}`} value={this.props.options.minUnit - 1} key="lowerUnit"/>)
    }
    for (let i = this.props.options.minUnit; i <= this.props.options.maxUnit; i++) {
      units.push(<NativePicker.Item label={`${i}`} value={i} key={'unit' + i}/>)
    }
    if (this.props.options.hasHigher) {
      units.push(<NativePicker.Item label={`> ${this.props.options.maxUnit}`} value={this.props.options.maxUnit + 1} key="higherUnit"/>)
    }
    if (this.props.options.decimals) {
      const maxDecimal = this.props.options.decimals === 1 ? 9 : 99
      for (let i = 0; i <= maxDecimal; i++) {
        let label = ''
        if (this.props.options.decimals === 1) {
          label = `${i}`
        } else {
          if (i < 10) {
            label = `0${i}`
          } else {
            label = `${i}`
          }
        }
        decimals.push(<NativePicker.Item label={label} value={i} key={'decimal' + i}/>)
      }
    }
    return (
    <React.Fragment>
      <View style={{ ...styles.titleContainer, marginBottom: 0 }}>
        <Text style={styles.dateTitle}>{this.props.title}</Text>
        <TouchableOpacity activeOpacity={0.8}
                          hitSlop={10}
                          onPress={this.onValidateNumber}>
          <Text style={styles.ok}>OK</Text>
        </TouchableOpacity>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 200 }}>
        <NativePicker style={{ flex: (this.props.options.decimals || this.props.options.unit.length > 7) ? 0.3 : 0.4 }}
                itemStyle={{ textAlign: 'right' }}
                onValueChange={this.onUnitsChange}
                selectedValue={this.state.units}>
          {units}
        </NativePicker>
        {this.props.options.decimals &&
          <React.Fragment>
            <View>
              <Text style={{ fontSize: 21.5 }}>,</Text>
            </View>
            <NativePicker style={{ flex: this.props.options.decimals === 1 ? 0.25 : 0.35 }}
                          itemStyle={{ textAlign: 'left' }}
                          onValueChange={this.onDecimalsChange}
                          selectedValue={this.state.decimals}>
              {decimals}
            </NativePicker>
          </React.Fragment>
        }
        <View style={{ flex: this.props.options.unit.length > 7 ? 0.6 : (this.props.options.decimals ? 0.2 : 0.4) }}
              pointerEvents="none">
          <Text style={{ fontSize: 21.5 }}>
            {this.props.options.decimals ? this.props.options.unit : ` ${this.props.options.unit}`}
          </Text>
        </View>
      </View>
    </React.Fragment>
  )}

  render() {
    const containerStyle = this.containerStyle()
    return (
      <Animated.View style={[styles.choiceBg, { opacity: this.state.fadeAnim }]}>
        <TouchableOpacity style={{ flex: 1 }}
                          activeOpacity={1}
                          onPress={this.hidePicker}>
          <Animated.View style={[containerStyle, { transform: [{ translateY: this.state.translateAnim }] }]}>
            <TouchableWithoutFeedback onPress={() => {}}>
              <LinearGradient style={{ ...containerStyle, position: Platform.OS === 'ios' ? 'absolute' : 'relative', minHeight: Platform.OS === 'ios' ? undefined : 200 }}
                              colors={['#FFFFFF', '#F8FBFF']}>
                {this.props.childView === 'choice' && this.choicePickerView()}
                {this.props.childView === 'date' && this.datePickerView()}
                {this.props.childView === 'year' && this.yearPickerView()}
                {this.props.childView === 'number' && this.NumberPickerView()}
              </LinearGradient>
            </TouchableWithoutFeedback>
          </Animated.View>
        </TouchableOpacity>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  choiceBg: {
    display: 'flex',
    flex: 1,
    position: 'absolute',
    zIndex: 9998,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%'
  },
  choiceContainer: {
    width: '100%',
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: 'white',
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },

  dateContainer: {
    width: '100%',
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: 'white',
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingBottom: 20
  },

  numberContainer: {
    width: '100%',
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: 'white',
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingBottom: Platform.OS === 'ios' ? 40 : 0
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 26,
    paddingRight: 26,
    marginTop: 30,
    marginBottom: 20
  },

  ok: {
    color: variables.primaryColor,
    paddingHorizontal: 20,
    marginRight: -20,
    fontSize: 16,
    fontFamily: 'OpenSans-Semibold'
  },

  choiceTitle: {
    marginLeft: 26,
    marginTop: 30,
    marginBottom: 30,
    textTransform: 'uppercase',
    fontFamily: 'OpenSans-Semibold',
    letterSpacing: -0.5,
    fontSize: 14
  },

  dateTitle: {
    textTransform: 'uppercase',
    fontFamily: 'OpenSans-Semibold',
    letterSpacing: -0.5,
    fontSize: 14
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 74,
    paddingLeft: 26,
    paddingRight: 26
  },

  itemBottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: variables.colorLightGray
  },

  itemLast: {
    marginBottom: 25
  },

  labelSelected: {
    fontSize: 16,
    marginLeft: 15
  },

  label: {
    fontSize: 16,
    marginLeft: 15,
    fontFamily: 'OpenSans-Light'
  }
})

const mapStateToProps = (state) => ({
  selectValue: state.picker.method,
  title: state.picker.title,
  choices: state.picker.choices,
  childView: state.picker.childView,
  value: state.picker.value,
  options: state.picker.options
})

const mapDispatchToProps = (dispatch) => ({
  hidePicker: () => {
    dispatch(hidePicker())
  },
  showModal: (title, childView) => {
    dispatch(showModal(title, childView))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Picker)
