import React from 'react'
import Text from '../Text'

class ModalAntecedentCardio extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Text style={{ fontSize: 15, lineHeight: 24 }}>
        Antécédent de maladie cardiovasculaire précoce chez un membre de la famille au premier degré (inférieur à 50 ans pour un homme, inférieur à 60 ans pour une femme).
      </Text>
    )
  }
}

export default ModalAntecedentCardio
