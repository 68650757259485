import variables from '../variables'

const styles = {
  tabBar: {
    position: 'fixed',
    top: 0,
    right: 0,
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopColor: 'transparent'
  },
  tabBarItem: {
    fontSize: 14,
    maxWidth: 'fit-content',
    marginRight: 40,
    cursor: 'pointer',
    color: variables.colorDarkGray
    //fontFamily: variables.fontFamily.light
  }
}

export default styles
