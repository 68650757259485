import React from 'react'
import Text from '../Text'
import { View } from 'react-native'
import { connect } from 'react-redux'
import Button from '../Button'
import * as NavigationService from '../../routes/NavigationService'
import { resetPatient } from '../../store/actions/patientActions'

class ModalClosePatient extends React.Component {
  constructor() {
    super()
  }

  onPressClosePatient = () => {
    this.props.hideModal()
    NavigationService.navigate('Home')
  }

  render() {
    return (
      <View style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 15, lineHeight: 24 }}>
          Voulez-vous vraiment fermer le profil patient ? Les données entrées seront réinitialisées.
        </Text>
        <View style={{ marginTop: 20, marginBottom: -10, width: '100%' }}>
          <Button onPress={this.onPressClosePatient}
                  type="primary"
                  label="Fermer le profil patient" />
        </View>
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  resetPatient: dispatch(resetPatient())
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalClosePatient)
