const initialState = {
  choices: [],
  title: '',
  value: null,
  childView: null,
  active: false,
  method: null,
  options: {}
}

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case 'SHOW_PICKER': {
      return {
        ...state,
        active: true,
        title: action.title,
        choices: action.choices,
        value: action.value,
        childView: action.childView,
        method: action.method,
        options: action.options
      }
    }
    case 'HIDE_PICKER': {
      return {
        ...state,
        active: false,
        title: '',
        choices: [],
        value: null,
        childView: null,
        method: null
      }
    }
    default:
      return state
  }
}
