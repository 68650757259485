import React from 'react'
import { connect } from 'react-redux'
import ComponentPicker from './Picker'
import SidePanel from './SidePanel'
import Modal from './Modal'
import Navigator from '../routes/Navigator'

class RootContainer extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <React.Fragment>
        <Navigator />
        {this.props.modalActive && <Modal />}
        {this.props.pickerActive && <ComponentPicker />}
        {this.props.sidepanelActive && <SidePanel />}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  modalActive: state.modal.active,
  pickerActive: state.picker.active,
  sidepanelActive: state.sidepanel.active
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
