import store from '../../../../store'
import { texts } from '../texts'

export function StrategieInsuffisanceRenale () {
  const state = store.getState()
  const patientAge = state.patient.age
  const dfg = state.patient.dfg
  const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
  const hasMaladieAtheromateuse = state.patient.complications.some(i => i.id === 4)
  const fevgMoreThan40 = state.patient.ejection === 1
  const carefulGLP1 = hasInsuffisanceCardiaque && !fevgMoreThan40
  const result = []
  let label = null
  let info = null
  if (dfg < 30) {
    label = state.patient.metformine ? 'Arrêter metformine' : 'Ne pas utiliser metformine'
    info = texts.metformine_renal_issues
  } else {
    label = state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine'
    if (dfg < 45) {
      label += ' à 1g/jour maximum'
    } else if (dfg < 60) {
      label += ' à 2g/jour maximum'
    }
    info = hasInsuffisanceCardiaque ? texts.metformine_insuffisance_cardiaque : ''
  }
  if (hasInsuffisanceCardiaque) {
    if (dfg >= 25) {
      label += " et ajouter iSGLT2 quelle que soit l'HbA1c"
    } else if (dfg >= 20) { // Entre 20 et 24
      label += ' et ajouter empagliflozine quelle que soit l\'HbA1c'
      info = "Dans le cas d'un DFG entre 20 et 24 mL/min/1,73 et d'une insuffisance cardiaque, seul l'empagliflozine est utilisable à 10mg par jour."
    } else if (dfg >= 15) { // Entre 15 et 19
      label += ' et ajouter AR GLP-1'
    }

    if (patientAge >= 75) {
      label += ' (si envisageable pour ce patient âgé)'
      info += texts.isglt2_ages
    }

    result.push({
      label: label,
      info: info,
      children: dfg >= 20 ? [ // Si entre 15 et 20, on ajoutera aGLP1 en 1er
        {
          label: `Ou AR GLP-1 si intolérance ou contre-indication aux iSGLT2${hasMaladieAtheromateuse ? '' : ' ET objectif non atteint'}`,
          info: carefulGLP1 ? `${texts.careful_aglp1_fe}\n\n${texts.aglp1_benefice_cardiaque}` : texts.aglp1_benefice_cardiaque
        }
      ] : null
    })
    info = null // reset info
  } else { // Si pas d'insuffisance cardique, on propose ISGLT2 ou dapagliflozine
    if (dfg >= 30) {
      label += ' et ajouter iSGLT2 quelle que soit l\'HbA1c'
      if (patientAge >= 75) {
        label += ' (si envisageable pour ce patient agé)'
        info =  texts.isglt2_ages
      }
    } else if (dfg >= 25) { // Entre 25 et 29
      label += ' et ajouter dapagliflozine quelle que soit l\'HbA1c'
      info = "Dans le cas d'un DFG entre 25 et 29 mL/min/1,73, seule la dapagliflozine est utilisable à 10mg par jour."
    } else if (dfg >= 15 && dfg < 25) {
      label += ' et ajouter AR GLP-1'
      info = carefulGLP1 ? `${texts.careful_aglp1_fe}\n\n${texts.aglp1_benefice_renal}` : texts.aglp1_benefice_renal
      if (patientAge >= 75) {
        label += ' (si envisageable pour ce patient agé)'
        info += `\n\n${texts.isglt2_ages}`
      }
    }

    result.push({
      label: label,
      info: info,
      children: dfg >= 25 ? [ // Si entre 15 et 25, on ajoutera aGLP1 en 1er
        {
          label: `Ou AR GLP-1 si intolérance ou contre-indication aux iSGLT2${hasMaladieAtheromateuse ? '' : ' ET objectif non atteint'}`,
          info: texts.aglp1_benefice_renal
        }
      ] : null
    })
    info = null
  }

  if (dfg >= 60) { // cas d'une albuminurie
    label = "L'ensemble des autres médicaments antidiabétiques oraux ou injectables peuvent être envisagés dans le respect des éventuelles contre-indications."
    info = "L'insuline, le répaglinide et les sulfamides peuvent être envisagés en faisant attention au risque hypoglycémique."
  } else if (dfg >= 30) { // moins de 60
    if (patientAge >= 75) {
      label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine et la sitagliptine peuvent également être utilisés à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    } else {
      label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine et la sitagliptine peuvent également être utilisés à dose de 50 mg/jour (sans association avec un AR GLP-1). Le répaglinide, le glimépiride, le gliclazide et l'insuline peuvent également être utilisés en faisant attention au risque hypoglycémique."
    }
    info = null
  } else if (hasInsuffisanceCardiaque ? dfg >= 20 : dfg >= 25) {// On propose un iSGLT2 en premier, il faut donc rajouter le AR GLP-1 dans la phrase
    label = "Les AR GLP-1 peuvent être utilisés. La vildagliptine peut également être utilisée à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique. "
    info = null
  } else if (dfg >= 15) {
    label = "La vildagliptine peut être utilisée à dose de 50 mg/jour (sans association avec un AR GLP-1). L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    info = null
  } else {
    label = "La vildagliptine peut être utilisée à dose de 50 mg/jour. L'insuline et le répaglinide peuvent être utilisés en faisant attention au risque hypoglycémique."
    info = null
  }

  result.push({
    label: label,
    info: info,
    style: {
      fontSize: 14.5
    }
  })
  return result
}
