import React from 'react'
import {
  View,
  Animated,
  Easing
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../components/Text'
import variables from '../../assets/stylesheets/variables'
import homeStyle from '../../assets/stylesheets/views/home'
import Button from '../../components/Button'
import LottieContainer from '../../components/LottieContainer'

class Home extends React.Component {
  constructor() {
    super()
  }

  state = {
    rotateAnim: new Animated.Value(0)
  }

  componentDidMount () {
    Animated.timing(
      this.state.rotateAnim,
      {
        toValue: 1,
        delay: 1500,
        duration: 800,
        easing: Easing.elastic(1.2),
        useNativeDriver: true
      }
    ).start(() => {
      Animated.timing(
        this.state.rotateAnim,
        {
          toValue: 0,
          delay: 1500,
          duration: 800,
          easing: Easing.elastic(1.2),
          useNativeDriver: true
        }
      ).start()
    })
  }

  render() {
    return (
      <View style={homeStyle.container}>
        <LinearGradient colors={variables.primaryGradient}
                        start={{ x: 0, y: 1 }}
                        end={{ x: 1, y: 0 }}
                        style={homeStyle.gradientContainer}>
          <Animated.Image style={{
            width: 80,
            height: 80,
            transform:
              [
                {
                  rotate: this.state.rotateAnim.interpolate(
                    {
                      inputRange: [0, 1],
                      outputRange: ['0deg', '180deg']
                    }
                  )
                }
              ]
          }}
                          source={require('../../assets/img/logo-square-white.png')} />
          <View style={homeStyle.textWrapper}>
            <Text style={{ ...homeStyle.text, marginTop: 40, marginBottom: 20 }}>
              Bienvenue sur DiabGuide, votre outil d'accompagnement dans la prise en charge de vos patients atteints de diabète de type 2.
            </Text>
            <Text style={{ ...homeStyle.text, marginBottom: 60 }}>
              Pour démarrer, veuillez renseigner le profil de votre patient.
            </Text>
            <Button onPress={() => this.props.navigation.navigate('Steps')}
                    style={{ width: '100%' }}
                    label="Renseigner profil patient" />
          </View>
        </LinearGradient>
        <LottieContainer lottieAnimation={require('../../assets/lottie/data-analysis.json')} />
      </View>
    )
  }
}

export default Home
