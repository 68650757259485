import React, { useState } from 'react'
import { View, TouchableWithoutFeedback, TouchableHighlight, StyleSheet, Platform, Linking } from 'react-native'
import Text from '../Text'
import variables from '../../assets/stylesheets/variables'
import CheckButton from '../CheckButton'
import Button from '../Button'
import { useDispatch } from 'react-redux'
import { updatePatient } from '../../store/actions/patientActions'

const styles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 74
  },

  itemBottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: variables.colorLightGray
  },

  labelSelected: {
    fontSize: 16,
    marginLeft: 15
  },

  label: {
    fontSize: 16,
    marginLeft: 15,
    fontFamily: 'OpenSans-Light'
  },

  validateButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingVertical: 10,
    backgroundColor: variables.primaryColor,
    borderRadius: 4,
    marginTop: 15,
    marginBottom: -15
  }
})


const choices = [
  {
    label: 'Supérieur à 20%',
    id: 'over_20'
  },
  {
    label: 'Entre 10% et 20%',
    id: 'between_10_and_20'
  },
  {
    label: 'Entre 5% et 10%',
    id: 'between_5_and_10'
  },
  {
    label: 'Moins de 5%',
    id: 'below_5'
  }
]

const ModalScore2Diabetes = ({ modalMethod }) => {
  const dispatch = useDispatch()
  const [selectedChoice, setSelectedChoice] = useState({})

  const getESCUrl = () => {
    if (Platform.OS === 'ios') {
      return 'https://apps.apple.com/app/id1498801246'
    }
    if (Platform.OS === 'android') {
      return 'https://play.google.com/store/apps/details?id=org.escardio.esccvdriskcalculation'
    }
    if (Platform.OS === 'web') {
      return 'https://www.escardio.org/Education/ESC-Prevention-of-CVD-Programme/Risk-assessment/esc-cvd-risk-calculation-app'
    }
    return ''
  }

  const isItemSelected = (choice) => {
    return selectedChoice.id === choice.id
  }

  const onValidate = () => {
    dispatch(updatePatient('score2', selectedChoice.id))
    modalMethod()
  }

  return (
    <View>
      <Text style={{ marginTop: 10 }}>
        Afin de se conformer aux recommandations de l'ESC 2023, il est nécessaire de connaître la valeur du <Text strong>SCORE2-Diabetes</Text>. Vous pouvez la retrouver en utilisant
        <TouchableWithoutFeedback
          onPress={() => Linking.openURL(getESCUrl())}>
          <Text strong style={{ color: variables.primaryColor }}> l'application ESC CVD Risk Calculation en cliquant sur ce lien</Text>
        </TouchableWithoutFeedback>.
      </Text>
      {
        choices.map((choice, index) => (
          <TouchableHighlight key={choice.id}
                              activeOpacity={0.9}
                              underlayColor={variables.colorHoverItem}
                              onPress={() => setSelectedChoice(choice)}>
            <View style={[styles.item, index < choices.length - 1 ? styles.itemBottomBorder : {}]}>
              <CheckButton checked={isItemSelected(choice)} />
              {isItemSelected(choice) && <Text style={styles.labelSelected}>{choice.label}</Text>}
              {!isItemSelected(choice) && <Text style={styles.label}>{choice.label}</Text>}
            </View>
          </TouchableHighlight>
        ))
      }
      <Button disabled={!selectedChoice.id}
              type="primary"
              onPress={() => onValidate()}
              label="Valider" />
    </View>
  )
}

export default ModalScore2Diabetes
