import store from '../../../../store'
import { texts } from '../texts'
import { imc } from '../../../../../utils'

export function StrategiePersonneAgee () {
  const state = store.getState()
  const patientComplications = state.patient.complications.filter(i => i.id !== 1)
  const hasRenalDisease = patientComplications.some(i => i.id === 3)
  const dfg = state.patient.dfg
  const hasInsuffisanceCardiaque = state.patient.complications.some(i => i.id === 5)
  const patientImc = imc(state.patient.weight, state.patient.height)

  const result = []

  let firstLabel = state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine'
  let info = null
  if (hasRenalDisease && dfg < 30) {
    firstLabel = state.patient.metformine ? 'Arrêter metformine' : 'Ne pas utiliser metformine'
    info = texts.metformine_renal_issues
  }

  result.push({
    label: firstLabel,
    info
  })

  if ((hasRenalDisease && state.patient.health === 2) || hasInsuffisanceCardiaque) {
    // On peut ajouter un iSGLT2 si patient fragile ayant une IC ou MRC, ou si patient dépendant ayant une IC
    result.push({
      label: `Ajouter iDPP4 et/ou iSGLT2 au cas par cas et, le cas échéant, après avis d'un ${hasInsuffisanceCardiaque ? 'cardiologue' : 'néphrologue'}`,
      info: null
    })
  } else if (patientImc > 30 && state.patient.health === 2) {
    result.push({
      label: 'Ajouter iDPP4 ou AR GLP-1 au cas par cas',
      info: "L'utilisation d'un AR GLP-1 peut parfois se discuter au cas par cas en tenant compte d'un risque majoré de sarcopénie et de dénutrition."
    })
  } else { // Patient n'ayant ni MRC, ni IC, pas obèse et état 2 ou 3
    result.push({
      label: 'Ajouter iDPP4'
    })
  }

  result.push({
    label: 'Chez les sujets fragiles ou dépendants, l\'insuline basale est l\'option à privilégier, en faisant attention au risque hypoglycémique.',
    style: {
      fontSize: 14.5
    }
  })

  return result
}
