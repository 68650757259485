import store from '../../../../store'
import { texts } from '../texts'

export function StrategieMaladieAtheromateuse () {
  const state = store.getState()
  const patientAge = state.patient.age

  const hasAVC = state.patient.antecedentAVC
  const result = []
  let label = null
  let info = null

  label = state.patient.metformine ? 'Continuer metformine' : 'Ajouter metformine'
  label += hasAVC ? ' et ajouter AR GLP-1' : ' et ajouter iSGLT2 ou AR GLP-1 quelle que soit l\'HbA1c'
  info = [texts.aglp1_benefice_cardiaque]
  if (patientAge >= 75) {
    label += ' (si envisageable pour ce patient âgé)'
    info.push(texts.isglt2_ages)
  }
  result.push({
    label: label,
    info: info.join('\n\n'),
    ...(hasAVC && {
      children: [
        {
          label: 'Ou iSGLT2',
          choice: 2
        }
      ]
    }),
    nextLineIfLabel: 'ou protection cardiovasculaire supplémentaire recherchée'
  })

  result.push({
    label: 'Si patient sous AR GLP-1, ajouter iSGLT2; si patient sous iSGLT2, ajouter AR GLP-1'
  })

  label = "L'ensemble des autres médicaments antidiabétiques oraux ou injectables peuvent être envisagés dans le respect des éventuelles contre-indications."
  info = "L'insuline, le répaglinide et les sulfamides peuvent être envisagés en faisant attention au risque hypoglycémique."

  result.push({
    label: label,
    info: info,
    style: {
      fontSize: 14.5
    }
  })
  return result
}
