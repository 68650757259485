import React from 'react'
import Text from '../Text'

class ModalSedentary extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Text style={{ fontSize: 15, lineHeight: 24 }}>
        Le patient ne peut pas monter plus de deux étages.
      </Text>
    )
  }
}

export default ModalSedentary
