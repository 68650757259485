import React from 'react'
import {
  View,
  ScrollView
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import InputList from '../../../components/InputList'
import { updatePatient } from '../../../store/actions/patientActions'
import { showModal } from '../../../store/actions/modalActions'
import { connect } from 'react-redux'
import { showPicker } from '../../../store/actions/pickerActions'
import Button from '../../../components/Button'

import variables from '../../../assets/stylesheets/variables'
import stepsStyle from '../../../assets/stylesheets/views/steps'
import baseStyle from '../../../assets/stylesheets/layouts/base.web'
import Text from '../../../components/Text'
import LottieContainer from '../../../components/LottieContainer'
import { AlertBanner } from '../../../components/AlertBanner'

class ThirdStep extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    errors: {},
    hasError: false
  }

  onChange = async (key, value) => {
    await this.props.updatePatient(key, value)
    this.validateField(key)
  }

  validateField = (field = null) => {
    const weaned = this.props.patient.smoking === 2

    const errors = {
      weanedSince: weaned && !this.props.patient.weanedSince
    }

    this.setState((prevState) => {
      const newErrors = field ? {
        ...prevState.errors,
        [field]: errors[field]
      } : errors

      const hasError = !Object.values(newErrors).every(i => !i)
      return {
        errors: newErrors,
        hasError
      }
    })

    return errors
  }

  validateThirdStep = () => {
    const errors = this.validateField()
    const hasError = !Object.values(errors).every(i => !i)
    if (!hasError || __DEV__) {
      this.props.navigation.navigate('List')
    }
  }

  render() {
    const weaned = this.props.patient.smoking === 2

    const hasRenalDisease = this.props.patient.complications.some(i => i.id === 3)
    const hasMaladieAtheromateuse = this.props.patient.complications.some(i => i.id === 4)
    const hasInsuffisanceCardiaque = this.props.patient.complications.some(i => i.id === 5)

    return (
      <View style={baseStyle.baseLayout}>
        <LinearGradient style={baseStyle.contentSection}
                        colors={variables.bgGradient}>
          <ScrollView alwaysBounceVertical={false}
                      contentContainerStyle={stepsStyle.contentContainerStyle}>
            <Text style={stepsStyle.stepTitle}>3. Facteurs de risque</Text>
            {this.state.hasError && (
              <AlertBanner type="error">
                Certaines informations sont manquantes ou incorrectes
              </AlertBanner>
            )}
            <InputList title="Tabagisme"
                       type="choice"
                       onChange={(value) => this.onChange('smoking', value)}
                       choices={[{ id: false, label: 'Non fumeur' }, { id: 2, label: 'Sevré' }, { id: 3, label: 'Actif' }]}
                       value={this.props.patient.smoking} />
            {weaned &&
              <InputList title="Sevré depuis"
                         type="choice"
                         error={this.state.errors.weanedSince}
                         choices={[{ id: 1, label: 'Moins de trois ans' }, { id: 2, label: 'Plus de trois ans' }]}
                         onChange={(value) => this.onChange('weanedSince', value)}
                         value={this.props.patient.weanedSince} />
            }
            <InputList title="Hypertension"
                       type="choice"
                       onChange={(value) => this.onChange('hta', value)}
                       choices={[{ id: 'treated', label: 'Oui, traitée' }, { id: 'known', label: 'Oui, non traitée' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.hta} />
            <InputList title="Dyslipidémie"
                       type="choice"
                       onChange={(value) => this.onChange('dyslipidemie', value)}
                       choices={[{ id: 'treated', label: 'Oui, traitée' }, { id: 'known', label: 'Oui, non traitée' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.dyslipidemie} />
            <InputList title={'Hypertrophie ou dysfonction \ndu ventricule gauche'}
                       type="choice"
                       onChange={(value) => this.onChange('ventriculaireAnormale', value)}
                       choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.ventriculaireAnormale} />
            <InputList title={'Antécédent familial de \nmaladie cardiovasculaire'}
                       type="choice"
                       onChange={(value) => this.onChange('familleCV', value)}
                       choices={[{ id: 'yes_early', label: 'Oui, précoce', info: 'antecedent-familial-cv-precoce' }, { id: 'yes', label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.familleCV} />
            {hasMaladieAtheromateuse &&
              <InputList title={'Antécédent d\'AVC ischémique'}
                         type="choice"
                         onChange={(value) => this.onChange('antecedentAVC', value)}
                         choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                         value={this.props.patient.antecedentAVC} />
            }
            {(!hasRenalDisease && !hasInsuffisanceCardiaque && !hasMaladieAtheromateuse) &&
              <InputList title={'Patient à haut ou très haut\nrisque cardiovasculaire'}
                         type="choice"
                         onChange={(value) => this.onChange('isHighRiskCV', value)}
                         choices={[{ id: true, label: 'Oui' }, { id: 'unknown', label: 'Je ne sais pas' }, { id: false, label: 'Non' }]}
                         value={this.props.patient.isHighRiskCV} />
            }
          </ScrollView>
          <View style={stepsStyle.footer}>
            <View style={stepsStyle.footerContainer}>
              <Button style={{ marginRight: 10 }}
                      type="outline"
                      onPress={() => this.props.navigation.goBack()}
                      label="Retour" />
              <Button onPress={this.validateThirdStep}
                      label="Valider" />
            </View>
          </View>
        </LinearGradient>
        <LottieContainer primary
                         lottieAnimation={require('../../../assets/lottie/data-analysis.json')} />
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (key, value) => {
    dispatch(updatePatient(key, value))
  },
  showModal: (title, childView) => {
    dispatch(showModal(title, childView))
  },
  showPicker: (title, choices = [], value, childView, method, options = {}) => {
    dispatch(showPicker(title, choices, value, childView, method, options))
  }
})

const mapStateToProps = (state) => ({
  patient: state.patient
})

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep)
