import React from 'react'
import { Text } from 'react-native'
import variables from '../assets/stylesheets/variables'
import { scaleFontSize } from '../utils'

class TextComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      style: Object.assign({
        fontFamily: variables.fontFamily.regular,
        letterSpacing: -0.7,
        color: variables.colorDarkGray
      }, this.props.style)
    }
    if (this.state.style.fontSize) {
      this.state.style.fontSize = scaleFontSize(this.state.style.fontSize)
    }
  }

  static getDerivedStateFromProps (props, state) {
    state.style = Object.assign({
      fontFamily: props.strong ? variables.fontFamily.semiBold : variables.fontFamily.regular,
      letterSpacing: -0.7,
      color: variables.colorDarkGray
    }, props.style)

    if (state.style.fontSize) {
      state.style.fontSize = scaleFontSize(state.style.fontSize)
    }
    return state.style
  }

  formatText = (text) => {
    if (typeof text === 'string') {
      const formattedText = text.split('iSGLT2').flatMap((item) => [item, <Text>iSGLT2</Text>])
      formattedText.pop()
      return formattedText
    }
    return text
  }

  render() {
    return (
      <Text {...this.props}
            style={this.state.style}
            allowFontScaling={false}>
        {this.props.children}
      </Text>
    )
  }
}

export default TextComponent
