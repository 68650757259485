import React from 'react'
import Text from './Text'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'
import { useDispatch } from 'react-redux'
import { showModal } from '../store/actions/modalActions'

export const BannerHba1c = ({ objectifsGlycemiques }) => {
  const dispatch = useDispatch()

  const onPress = () => {
    dispatch(showModal('', 'tooltip', { text: objectifsGlycemiques.bannerInfo }))
  }
  return (
    <View style={styles.bannerHba}>
      <Text style={styles.bannerHbaText}>
        Objectif d'HbA1c : <Text style={styles.bannerHbaResult}>
        {objectifsGlycemiques.hba}% {objectifsGlycemiques.subtitle ? `${objectifsGlycemiques.subtitle}` : '' }</Text>
      </Text>
      {objectifsGlycemiques.bannerInfo && (
        <TouchableOpacity style={{ padding: 10, margin: -10, marginLeft: 0  }}
                          activeOpacity={0.8}
                          onPress={() => onPress()}>
          <Icon name="ios-information-circle-outline"
                size={24}
                color="white" />
        </TouchableOpacity>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  bannerHba: {
    minHeight: 46,
    paddingVertical: 10,
    borderRadius: 6,
    width: '100%',
    backgroundColor: '#1B69C7',
    borderLeftWidth: 10,
    borderLeftColor: '#66AAFF',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#E5EAF5',
    shadowOffset: {
      width: 0,
      height: 15
    },
    shadowOpacity: 0.7,
    shadowRadius: 9,
    marginBottom: 30
  },
  bannerHbaText: {
    fontSize: 15,
    color: 'white',
    fontFamily: 'OpenSans-Light'
  },
  bannerHbaResult: {
    fontSize: 15,
    fontFamily: 'OpenSans-Semibold',
    color: 'white'
  }
})
