import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import Text from './Text'
import buttonStyle from '../assets/stylesheets/components/button'
import LoadingSpinner from './LoadingSpinner'

class Button extends React.Component {

  render() {
    let style = StyleSheet.compose(buttonStyle.button, this.props.style)
    if (this.props.type === 'primary') {
      style = StyleSheet.compose(style, buttonStyle.buttonPrimary)
    }
    if (this.props.type === 'outline') {
      style = StyleSheet.compose(style, buttonStyle.buttonOutline)
    }

    let textStyle = buttonStyle.text
    if (this.props.type === 'outline') {
      textStyle = { ...textStyle, ...buttonStyle.textOutline }
    }

    return (
      <View pointerEvents={this.props.disabled || this.props.loading ? 'none' : 'auto'}
            style={[this.props.disabled ? { opacity: 0.4 } : undefined, this.props.style]}>
        <TouchableOpacity style={style}
                          activeOpacity={0.8}
                          onPress={this.props.onPress}>
          {this.props.loading && (
            <LoadingSpinner />
          )}
          {!this.props.loading && (
            <Text style={textStyle}>{this.props.label}</Text>
          )}
        </TouchableOpacity>
      </View>
    )
  }
}

export default Button
