import React from 'react'
import {
  View,
  StyleSheet,
  SafeAreaView,
  Image, TouchableOpacity, AsyncStorage
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../components/Text'
import variables from '../assets/stylesheets/variables'
import { connect } from 'react-redux'
import Button from '../components/Button'
import CheckButton from '../components/CheckButton'
import { getAsyncStorageItem } from '../utils'

class Preferences extends React.Component {
  constructor() {
    super()
  }

  async componentDidMount () {
    const recommandationPreferences = await getAsyncStorageItem('recommandation_preferences')
    this.setState({
      selectedValue: recommandationPreferences || null
    })
  }

  state = {
    selectedValue: null
  }

  choices = [
    {
      label: 'Utiliser les dernières recommandations françaises uniquement',
      value: 'french_only'
    },
    {
      label: 'Choisir la société savante pour chaque recommandation',
      value: 'all'
    }
  ]

  onSubmit = async () => {
    await AsyncStorage.setItem('recommandation_preferences', this.state.selectedValue)
    this.props.navigation.navigate(this.props.route.params?.from || 'Home')
  }

  render() {
    return (
      <LinearGradient
        colors={variables.primaryGradient}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
        style={styles.container}>
        <SafeAreaView style={{ marginVertical: 60 }}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text style={styles.informationTitle}>
              Préférences de recommandations
            </Text>
            <Text style={styles.information}>
              Choisissez d'utiliser les recommandations françaises, ou bien de sélectionner la société savante de votre choix pour chaque recommandation intégrée à DiabGuide.
              {'\n\nVous pouvez à tout moment changer ces préférences dans vos paramètres.'}
            </Text>
            {this.choices.map((choice, index) => (
              <TouchableOpacity key={index}
                                activeOpacity={0.8}
                                onPress={() => this.setState({ selectedValue: choice.value })}
                                style={[styles.choiceItem, choice.value === this.state.selectedValue ? styles.choiceItemSelected : undefined]}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <CheckButton checked={choice.value === this.state.selectedValue}
                               style={{ borderColor: 'white' }}/>
                  <Text style={{ fontSize: 14, marginLeft: 15, color: choice.value === this.state.selectedValue ? variables.colorDarkGray : 'white' }}>{choice.label}</Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
          <Button onPress={this.onSubmit}
                  disabled={!this.state.selectedValue}
                  label="Enregistrer mes préférences" />
        </SafeAreaView>
      </LinearGradient>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingHorizontal: 40
  },
  informationTitle: {
    fontFamily: variables.fontFamily.semiBold,
    fontSize: 21,
    color: 'white',
    letterSpacing: -0.7
  },
  information: {
    fontFamily: 'OpenSans-Light',
    fontSize: 16,
    color: 'white',
    marginVertical: 25,
    lineHeight: 28
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100%',
    paddingVertical: 10,
    backgroundColor: variables.secondaryColor,
    borderRadius: 4
  },
  choiceItem: {
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.6)',
    borderRadius: 6,
    padding: 15,
    marginTop: 10
  },
  choiceItemSelected: {
    borderColor: 'white',
    backgroundColor: 'white'
  }
})

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Preferences)
