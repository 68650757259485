import React from 'react'
import {
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  Image
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../components/Text'
import variables from '../../assets/stylesheets/variables'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/Ionicons'
import { showPicker } from '../../store/actions/pickerActions'
import { selectRecommandation, risqueCardio } from '../../store/actions/recommandationActions'
import { esc2023, sfdsfc2021 } from '../../config/recommandations'
import { showModal } from '../../store/actions/modalActions'
import { isSmScreen } from '../../utils'

const dimensions = Dimensions.get('window')

const risks = [
  {
    id: 4,
    label: 'Très élevé'
  },
  {
    id: 3,
    label: 'Élevé'
  },
  {
    id: 2,
    label: 'Modéré'
  },
  {
    id: 1,
    label: 'Faible'
  }
]

class Cardiovasculaire extends React.Component {
  constructor(props) {
    super(props)
  }

  onPressRecommandation = () => {
    const choices = [
      esc2023,
      sfdsfc2021
    ]
    this.props.showPicker('Choix de la recommandation', choices, this.props.recommandation.id, 'choice', this.onRecommandationSelected)
  }

  onRecommandationSelected = (id) => {
    const choices = [
      esc2023,
      sfdsfc2021
    ]
    const selectedRecommandation = choices.find(c => c.id === id)
    this.props.refreshRecommandation(selectedRecommandation)
    this.props.refreshRisqueCardio()
  }

  render() {
    const riskLabel = risks.find(i => i.id === this.props.risqueCardio.value) ? risks.find(i => i.id === this.props.risqueCardio.value).label : 'Non défini'
    return (
      <React.Fragment>
        <SafeAreaView style={{ position: 'absolute', zIndex: 3, width: '100%' }}
                      edges={['top']}>
          <View style={styles.headerContainer}>
            <View style={{ position: 'relative', width: '100%' }}>
              <TouchableOpacity style={{ marginTop: 4, position: 'absolute', left: 5, zIndex: 3, paddingLeft: 10, paddingRight: 10 }}
                                activeOpacity={0.8}
                                onPress={() => this.props.navigation.goBack()}>
                <Icon style={{ marginTop: 1 }}
                      name="chevron-back"
                      size={28}
                      color="white" />
              </TouchableOpacity>
              <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 26, color: 'white', letterSpacing: -1, textAlign: 'center' }}>
                Risque cardiovasculaire
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 5, paddingVertical: 5 }}
                                activeOpacity={0.8}
                                onPress={this.onPressRecommandation}>
                <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 14, color: 'white' }}>
                  Selon recommandations {this.props.recommandation.label}
                </Text>
                {false &&
                  <Icon style={{ marginLeft: 5, marginTop: 3 }}
                        name="caret-down"
                        color="white"
                        size={14} />
                }
              </View>
            </View>
          </View>
        </SafeAreaView>
        <View style={{ height: '100%' }}>
          <LinearGradient style={{ zIndex: 1, height: '40%' }}
            colors={['#007BEE', '#0069CB']}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 0 }} />
          <View style={{ height: '60%', backgroundColor: '#004598' }}>
            <LinearGradient style={{ height: dimensions.height*0.2, position: 'absolute', bottom: 0, width: '100%' }}
                            colors={variables.bgGradient} />
          </View>
          <View style={styles.bodyContainer}>
            <View style={{ flex: 0.5 }} />
            <View style={styles.roundContainer}>
              <Image style={{ ...styles.roundContainer, resizeMode: 'stretch' }}
                     source={require('../../assets/img/round.png')} />
              <View style={styles.resultContainer}>
                <View style={styles.result}>
                  <Text style={styles.resultLabel}>Risque</Text>
                  <Text style={styles.resultNumber}>
                    {riskLabel}
                  </Text>
                </View></View>
            </View>
            <View style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <View style={styles.slide}>
                <ScrollView alwaysBounceVertical={false}
                            contentContainerStyle={{ marginRight: 27, marginTop: 24, paddingBottom: 24 }}>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 14 }}>
                    <Text style={styles.itemTitle}>Niveau de risque cardiovasculaire</Text>
                  </View>
                  <Text style={styles.itemContent}>{this.props.risqueCardio.note}</Text>
                </ScrollView>
              </View>
            </View>
          </View>
        </View>
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    marginTop: 12
  },
  bodyContainer: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  roundContainer: {
    width: isSmScreen ? Dimensions.get('window').width*0.67 : Dimensions.get('window').width*0.8,
    height: (isSmScreen ? Dimensions.get('window').width*0.67 : Dimensions.get('window').width*0.8) + 5,
    maxWidth: dimensions.height*0.43,
    maxHeight: dimensions.height*0.43
  },
  resultContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  result: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -10,
    marginBottom: 10
  },
  resultNumber: {
    color: 'white',
    fontSize: 50,
    fontFamily: 'OpenSans-Light',
    letterSpacing: -3.2
  },
  resultPercent: {
    lineHeight: 85,
    color: 'white',
    fontSize: 48,
    fontFamily: 'OpenSans-Light',
    letterSpacing: -3.2
  },
  resultLabel: {
    color: 'white',
    fontFamily: 'OpenSans-Semibold',
    fontSize: 16,
    marginBottom: 10
  },
  slide: {
    zIndex: 1000,
    width: dimensions.width*0.85,
    minHeight: 190,
    maxHeight: dimensions.height*0.3,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 27,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    shadowColor: 'rgba(0, 0, 0, 0.07)',
    shadowOffset: {
      width: 0,
      height: 10
    },
    shadowOpacity: 1,
    shadowRadius: 25,
    elevation: 5
  },
  itemTitle: {
    fontSize: 16
  },
  itemContent: {
    fontSize: 16,
    color: variables.colorMidGray
  }
})

const mapDispatchToProps = (dispatch) => ({
  showPicker: (title, choices = [], value, childView, method, options = {}) => {
    dispatch(showPicker(title, choices, value, childView, method, options))
  },
  showModal: (title, childView, dataModel = {}, method = null) => {
    dispatch(showModal(title, childView, dataModel, method))
  },
  refreshRecommandation: (recommandation) => {
    dispatch(selectRecommandation(recommandation))
  },
  refreshRisqueCardio: () => {
    dispatch(risqueCardio())
  }
})

const mapStateToProps = (state) => ({
  recommandation: state.recommandation.recommandation,
  risqueCardio: state.recommandation.risqueCardio
})

export default connect(mapStateToProps, mapDispatchToProps)(Cardiovasculaire)
