import React from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import Text from './Text'
import variables from '../assets/stylesheets/variables'
import { useDispatch } from 'react-redux'
import { showModal } from '../store/actions/modalActions'
import Icon from 'react-native-vector-icons/Ionicons'

const DetailStrategie = ({ children, type }) => {
  const dispatch = useDispatch()
  const onPressDetailStrategie = () => {
    dispatch(showModal('', `detail-strategie-ada-easd-2022-${type}`, {}))
  }
  return (
    <TouchableWithoutFeedback onPress={onPressDetailStrategie}>
      <Text style={{ color: variables.primaryColor, fontFamily: variables.fontFamily.semiBold }}>
        {children}
        {true &&
          (
            <>
              &nbsp;
              <Icon style={{ paddingLeft: 40, marginTop: 2, lineHeight: 400 }}
                    name="ios-information-circle-outline"
                    size={14}
                    color={variables.primaryColor} />
            </>

          )
        }
      </Text>
    </TouchableWithoutFeedback>
  )
}

export default DetailStrategie
