import React from 'react'
import { View, StyleSheet } from 'react-native'
import variables from '../assets/stylesheets/variables'

class RadioButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={[this.props.checked ? styles.radioChecked : styles.radioUnchecked]} />
    )
  }
}

const styles = StyleSheet.create({

  radioChecked: {
    width: 14,
    height: 14,
    borderRadius: 8,
    backgroundColor: variables.colorDarkGray
  },

  radioUnchecked: {
    width: 14,
    height: 14,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: variables.colorMidGray
  }
})

export default RadioButton
