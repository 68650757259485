import React from 'react'
import Text from '../Text'

class ModalAntecedentCardio extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Text style={{ fontSize: 15, lineHeight: 24 }}>
        La maladie rénale chronique est definie par un débit de filtration glomérulaire (DFG) inférieur à 60 ml/min/1,73m2
        et/ou un rapport albuminurie/créatininurie (RAC) >= 30 mg/g ou 3 mg/mmol.
      </Text>
    )
  }
}

export default ModalAntecedentCardio
