import React from 'react'
import Text from '../Text'

class ModalTooltip extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Text style={{ fontSize: 15, lineHeight: 24 }}>
        {this.props.text}
      </Text>
    )
  }
}

export default ModalTooltip
