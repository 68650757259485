import React from 'react'
import Text from '../Text'
import { View } from 'react-native'

class ModalAlbuminurie extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <View>
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 15, marginBottom: 10, fontFamily: 'OpenSans-Semibold' }}>
            Microalbuminurie si :
          </Text>
          <Text style={{ fontSize: 15 }}>albuminurie/créatininurie > 30 mg/g ou albuminurie > 30 mg/24h.</Text>
        </View>
        <Text style={{ fontSize: 15, marginBottom: 10, fontFamily: 'OpenSans-Semibold' }}>
          Macroalbuminurie si :
        </Text>
        <Text style={{ fontSize: 15 }}>albuminurie/créatininurie > 300 mg/g ou albuminurie > 300 mg/24h.</Text>
      </View>

    )
  }
}

export default ModalAlbuminurie
