import React from 'react'
import Text from '../Text'
import { View } from 'react-native'

class ModalAlbuminurie extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <View>
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 15, marginBottom: 10, fontFamily: 'OpenSans-Semibold' }}>
            En bonne santé :
          </Text>
          <Text style={{ fontSize: 15 }}>
            bien intégrées socialement et
            autonomes d’un point de vue décisionnel et fonctionnel, et dont
            l’espérance de vie est jugée satisfaisante.
          </Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 15, marginBottom: 10, fontFamily: 'OpenSans-Semibold' }}>
            Fragile :
          </Text>
          <Text style={{ fontSize: 15 }}>
            À l’état de santé intermédiaire et à risque de
            basculer dans la catégorie « dépendant ».
          </Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 15, marginBottom: 10, fontFamily: 'OpenSans-Semibold' }}>
            Dépendant :
          </Text>
          <Text style={{ fontSize: 15 }}>
            Ayant une polypathologie chronique évoluée génératrice de handicaps
            et d’un isolement social.
          </Text>
        </View>
      </View>

    )
  }
}

export default ModalAlbuminurie
