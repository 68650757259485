import React from 'react'
import Text from '../Text'

class ModalFdrNonControle extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Text style={{ fontSize: 15, lineHeight: 24 }}>
        Parmi : HbA1c, cholestérol LDL, cholestérol non HDL, tension artérielle, tabac.
      </Text>
    )
  }
}

export default ModalFdrNonControle
