import React, { useState } from 'react'
import Text from './Text'
import { StyleSheet, TouchableOpacity, TextInput, View } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'
import variables from '../assets/stylesheets/variables'
import Dropdown from './Dropdown'
import { toLocaleString } from '../utils'

const ChoiceInput = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const initialValue = props.value ? props.value + ` ${props.options.unit}` : ''
  const [formattedValue, setFormattedValue] = useState(initialValue)

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
    props.toggleActiveInput(!dropdownOpen)
  }

  const onFocusTextInput = () => {
    props.toggleActiveInput(true)
    if (formattedValue.includes(` ${props.options.unit}`)) {
      setFormattedValue(formattedValue.split(` ${props.options.unit}`)[0])
    }
  }

  const onBlurTextInput = () => {
    props.toggleActiveInput(false)
    if (formattedValue) {
      setFormattedValue(toLocaleString(formattedValue, props.options.digits) + ` ${props.options.unit}`)
    }
  }

  const onChangeText = (text) => {
    const regex = new RegExp(props.options.regex)
    if (regex.test(text) || !text) {
      text = text.replace('.', ',')
      props.onChange(text)
      setFormattedValue(text)
    }
  }

  let text = null
  if (props.value) {
    let value = props.value
    if (props.options.hasLower && value < props.options.minUnit) {
      value = `< ${props.options.minUnit}`
    } else if (props.options.hasHigher && value > props.options.maxUnit) {
      value = `> ${props.options.maxUnit}`
    }
    text = <Text style={styles.inputTextAnswer}>{value} {props.options.unit || ''}</Text>
  } else {
    text = <Text style={styles.inputTextSelect}>Selectionner</Text>
  }

  const isInputText = !!props.options.regex

  return (
    <View style={{ width: '100%', zIndex: 1 }}>
      {isInputText &&
        <TextInput style={styles.textInputStyle}
                   onFocus={onFocusTextInput}
                   onBlur={onBlurTextInput}
                   onChangeText={onChangeText}
                   placeholder={props.placeholder}
                   placeholderTextColor={variables.colorShinyGray + 60}
                   value={formattedValue} />
      }
      {!isInputText &&
        <TouchableOpacity activeOpacity={0.8}
                          style={styles.touchableOpacityStyle}
                          onPress={toggleDropdown}>
          {text}
          <Icon style={{ marginLeft: 2, marginTop: 3 }}
                name="chevron-down"
                color={variables.primaryColor}
                size={18}/>
        </TouchableOpacity>
      }
      {dropdownOpen &&
        <Dropdown {...props}
                  closeDropdown={toggleDropdown} />
      }
    </View>
  )
}

const styles = StyleSheet.create({
  inputTextAnswer: {
    fontFamily: variables.fontFamily.regular,
    fontSize: 16
  },
  inputTextSelect: {
    fontFamily: variables.fontFamily.regular,
    fontSize: 16,
    color: variables.primaryColor
  },
  touchableOpacityStyle: {
    height: 60,
    width: '100%',
    paddingLeft: 14,
    paddingRight: 18,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textInputStyle: {
    fontFamily: variables.fontFamily.regular,
    color: variables.colorDarkGray,
    letterSpacing: -0.7,
    height: 60,
    outlineStyle: 'none',
    width: '100%',
    paddingLeft: 14,
    paddingRight: 18,
    fontSize: 18
  }
})

export default ChoiceInput
