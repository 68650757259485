import React from 'react'
import {
  ScrollView,
  View
} from 'react-native'
import InputList from '../../../components/InputList'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../../components/Text'
import variables from '../../../assets/stylesheets/variables'
import { connect } from 'react-redux'
import { updatePatient } from '../../../store/actions/patientActions'
import Button from '../../../components/Button'
import LottieContainer from '../../../components/LottieContainer'
import baseStyle from '../../../assets/stylesheets/layouts/base'
import stepsStyle from '../../../assets/stylesheets/views/steps'

class MoreInformations extends React.Component {
  constructor() {
    super()
  }

  onSubmit = () => {
    this.props.methodToRunAfterMoreInformations()
  }

  render() {
    const hasRetino = this.props.patient.complications.some(i => i.id === 1)
    const disabled = !this.props.patient.scoreCalcique || this.props.patient.sedentary === null
    return (
      <View style={baseStyle.baseLayout}>
        <LinearGradient style={baseStyle.contentSection}
                        colors={variables.bgGradient}>
          <ScrollView alwaysBounceVertical={false}
                      contentContainerStyle={stepsStyle.contentContainerStyle}>
            <Text style={stepsStyle.stepTitle}>Informations complémentaires</Text>
            <View style={{ marginBottom: 30, marginHorizontal: 10 }}>
              <Text style={{ fontSize: 14, lineHeight: 28 }}>
                Afin de répondre au mieux aux recommandations SFD SFC 2021, le profil patient doit être complété :
              </Text>
            </View>
            <InputList title="Activité physique faible"
                       type="choice"
                       onChange={(value) => this.props.updatePatient('sedentary', value)}
                       choices={[{ id: true, label: 'Oui', info: 'sedentary' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.sedentary} />
            {hasRetino &&
              <InputList title="Rétinopathie sévère"
                         type="choice"
                         choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                         onChange={(value) => this.props.updatePatient('severeRetino', value)}
                         value={this.props.patient.severeRetino} />
            }
            <InputList title="Neuropathie autonome"
                       type="choice"
                       onChange={(value) => this.props.updatePatient('neuropathieAutonome', value)}
                       choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.neuropathieAutonome} />
            <InputList title="Cholestérol LDL"
                       type="choice"
                       onChange={(value) => this.props.updatePatient('ldl', value)}
                       choices={[{ id: 'above_and_trated', label: '> 1,90 g/L et traité' }, { id: 'above', label: '> 1,90 g/L non traité' }, { id: 'below', label: '< 1,90 g/L' }]}
                       value={this.props.patient.ldl} />
            {this.props.patient.gender === 1 &&
              <InputList title="Dysfonction érectile"
                         type="choice"
                         onChange={(value) => this.props.updatePatient('dysfonctionErectile', value)}
                         choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                         value={this.props.patient.dysfonctionErectile} />
            }
            <InputList title={'Hypertrophie ou dysfonction \ndu ventricule gauche'}
                       type="choice"
                       onChange={(value) => this.props.updatePatient('ventriculaireAnormale', value)}
                       choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.ventriculaireAnormale} />
            <InputList title="Ondes Q pathologiques"
                       type="choice"
                       onChange={(value) => this.props.updatePatient('ondesQ', value)}
                       choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.ondesQ} />
            <InputList title="Fibrillation auriculaire"
                       type="choice"
                       onChange={(value) => this.props.updatePatient('fibrillation', value)}
                       choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.fibrillation} />
            <InputList title={'Au moins un facteur\nde risque non contrôlé'}
                       type="choice"
                       info="fdr-non-controle"
                       onChange={(value) => this.props.updatePatient('fdrNonControle', value)}
                       choices={[{ id: true, label: 'Oui' }, { id: false, label: 'Non' }]}
                       value={this.props.patient.fdrNonControle} />
            <InputList title={'Score calcique'}
                       type="choice"
                       onChange={(value) => this.props.updatePatient('scoreCalcique', value)}
                       choices={[{ id: 'below_10', label: 'Inférieur à 10' }, { id: 'between_11_and_100', label: 'Entre 11 et 100' }, { id: 'between_101_and_400', label: 'Entre 101 et 400' }, { id: 'above_400', label: 'Supérieur à 400' }, { id: 'unknown', label: 'Non communiqué' }]}
                       value={this.props.patient.scoreCalcique} />
          </ScrollView>
          <View style={stepsStyle.footer}>
            <View style={stepsStyle.footerContainer}>
              <Button style={{ marginRight: 10 }}
                      type="outline"
                      onPress={() => this.props.navigation.goBack()}
                      label="Retour" />
              <Button disabled={disabled}
                      onPress={this.onSubmit}
                      label="Valider" />
            </View>
          </View>
        </LinearGradient>
        <LottieContainer primary
                         lottieAnimation={require('../../../assets/lottie/data-analysis.json')} />
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (key, value) => {
    dispatch(updatePatient(key, value))
  }
})

const mapStateToProps = (state) => ({
  patient: state.patient,
  methodToRunAfterMoreInformations: state.recommandation.methodToRunAfterMoreInformations
})

export default connect(mapStateToProps, mapDispatchToProps)(MoreInformations)
