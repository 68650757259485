import React from 'react'
import Text from '../Text'
import { StyleSheet, TouchableHighlight, TouchableOpacity, View } from 'react-native'
import variables from '../../assets/stylesheets/variables'
import CheckButton from '../CheckButton'
import { connect } from 'react-redux'
import { updatePatient } from '../../store/actions/patientActions'

const choices = [
  {
    label: 'Oui',
    id: 1
  },
  {
    label: 'Non',
    id: 0
  }
]

class ModalHasIsglt2 extends React.Component {
  constructor() {
    super()
  }

  state = {
    choice: null
  }

  onValidateChoice = (choice) => {
    this.setState({
      choice
    })
  }

  isItemSelected = (choice) => {
    return this.state.choice && choice.id === this.state.choice.id
  }

  itemList = () => {
    return choices.map((choice, index) => (
      <TouchableHighlight key={choice.id}
                          activeOpacity={0.9}
                          underlayColor={variables.colorHoverItem}
                          onPress={() => this.onValidateChoice(choice)}>
        <View style={[styles.item, index < choices.length - 1 ? styles.itemBottomBorder : {}]}>
          <CheckButton checked={this.isItemSelected(choice)}/>
          {this.isItemSelected(choice) && <Text style={styles.labelSelected}>{choice.label}</Text>}
          {!this.isItemSelected(choice) && <Text style={styles.label}>{choice.label}</Text>}
        </View>
      </TouchableHighlight>
    ))
  }

  onSubmit = () => {
    this.props.updatePatient('has_isglt2', this.state.choice.id)
    this.props.hideModal()
    this.props.modalMethod()
  }

  render() {
    const disabled = !this.state.choice
    return (
      <View style={{ marginTop: 20 }}>
        <Text style={{ fontSize: 14.5, marginVertical: 10 }}>
          Le patient est-il actuellement sous <Text style={{ fontFamily: 'OpenSans-Bold' }}>iSGLT2</Text> ?
        </Text>
        {this.itemList()}
        <View pointerEvents={disabled ? 'none' : 'auto'}
              style={[{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }, disabled ? { opacity: 0.4 } : {}]}>
          <TouchableOpacity style={styles.validateButton}
                            activeOpacity={0.8}
                            onPress={this.onSubmit}>
            <Text style={{ color: 'white', fontFamily: 'OpenSans-Semibold', fontSize: 16 }}>
              Valider
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 74
  },

  itemBottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: variables.colorLightGray
  },

  labelSelected: {
    fontSize: 16,
    marginLeft: 15
  },

  label: {
    fontSize: 16,
    marginLeft: 15,
    fontFamily: 'OpenSans-Light'
  },

  validateButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingVertical: 10,
    backgroundColor: variables.primaryColor,
    borderRadius: 4,
    marginTop: 15,
    marginBottom: -15
  }
})

const mapStateToProps = () => ({

})

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (key, value) => {
    dispatch(updatePatient(key, value))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalHasIsglt2)
