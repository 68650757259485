export const texts = {
  isglt2_obese: "Chez un patient indemne de pathologie cardiovasculaire ou rénale, l'association metformine + iDPP4 constitue l'option présentant le meilleur compromis entre efficacité anti-hyperglycémiante, profil de tolérance/de sécurité et coût journalier de traitement.\n\nLe choix d'un iSGLT2 ou AR GLP-1 peut être envisagé lorsque l'objectif d'HbA1c n'est pas atteint sous metformine chez le patient dont l'IMC est entre 30 et 35 kg/m2.",
  isglt2_benefice_cardio: 'En utilisant une molécule qui à prouvé son bénéfice cardiovasculaire.',
  no_isglt2_dfg: "Les iSGLT2 doivent être arrêtés si DFG inférieur à 45 mL/min et ne doivent pas être introduits si DFG inférieur à 60 mL/min. Les AR GLP-1 doivent être privilégiés, sauf si la fraction d'éjection est inférieure à 40%. ",
  isglt2_ages: 'Après 75 ans, l’utilisation des AR GLP-1 et des iSGLT2 doit être réservée à une minorité de patients, idéalement après avis d’un endocrinologue-diabétologue.',
  metformine: 'A la dose maximale tolérée et dans le respect des contre indications (notamment rénales).',
  metformine_other_issues: 'La metformine doit être prise à la dose maximale tolérée et dans le respect des autres contre indications.',
  metformine_renal_issues: 'La metformine doit être arrêtée si DFG inférieur à 30 mL/min.',
  metformine_insuffisance_cardiaque: 'Ne pas utiliser la metformine si insuffisance cardiaque sévère ou décompensée.',
  aglp1_renal_issues: 'Tous contre indiqués en cas de DFG < 15 mL/min.',
  idpp4_avoid_saxagliptine: 'Saxagliptine à éviter.',
  aglp1: 'Ayant fait la preuve de son bénéfice cardiovasculaire : liraglutide, dulaglutide, sémaglutide avec un niveau de preuve moins élevé.',
  aglp1_benefice_renal: 'AR GLP-1 ayant fait la preuve de son bénéfice rénal : liraglutide, dulaglutide, sémaglutide avec un niveau de preuve moins élevé. ',
  aglp1_benefice_cardiaque: 'AR GLP-1 ayant fait la preuve de son bénéfice cardiaque : liraglutide, dulaglutide, sémaglutide avec un niveau de preuve moins élevé.',
  careful_aglp1_fe: "Dans l'attente de nouvelles données, les AR GLP-1 doivent être utilisés avec précaution en cas d'insuffisance cardiaque à fraction d'éjection diminuée (< 40 %)."
}
