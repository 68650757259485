/**
 * @format
 */

import 'react-native-gesture-handler'
import { AppRegistry } from 'react-native'
import App from './App'
import { name as appName } from './app.json'
import 'react-native-vector-icons/Fonts/Ionicons.ttf'
import 'react-native-vector-icons/Fonts/Entypo.ttf'
import './assets/fonts/OpenSans-Regular.ttf'
import './assets/fonts/OpenSans-Light.ttf'
import './assets/fonts/OpenSans-Semibold.ttf'
import './assets/fonts/OpenSans-Bold.ttf'

if (module.hot) {
  module.hot.accept()
}

AppRegistry.registerComponent(appName, () => App)

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('app-root')
})

document.addEventListener('copy', e => {
  e.preventDefault()
  return false
})
