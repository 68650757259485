const styles = {
  container: {
    position: 'relative',
    zIndex: 999,
    width: '100%',
    flexDirection: 'row',
    minHeight: '100vh'
  },
  gradientContainer: {
    zIndex: 999,
    width: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10
  },
  textWrapper: {
    maxWidth: '700px',
    paddingHorizontal: 43
  },
  text: {
    color: 'white',
    fontSize: 19,
    textAlign: 'center',
    lineHeight: 30,
    fontFamily: 'OpenSans-Light'
  }
}

export default styles
