import React from 'react'
import { View, StyleSheet } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'
import variables from '../assets/stylesheets/variables'

class CheckButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let checkbox = null
    if (this.props.checked) {
      checkbox = (
        <View style={styles.radioChecked}>
          <Icon name="checkmark-sharp"
                color="white"
                size={14} />
        </View>
      )
    } else {
      checkbox = <View style={{ ...styles.radioUnchecked, ...this.props.style }} />
    }
    return checkbox
  }
}

const styles = StyleSheet.create({

  radioChecked: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: variables.primaryColor
  },

  radioUnchecked: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: variables.colorMidGray
  }
})

export default CheckButton
