import React from 'react'
import { View, StyleSheet } from 'react-native'
import { getAuthorizedGlp1, getAuthorizedIsglt2, getAuthorizedMetformine, getAuthorizedIdpp4, getAuthorizedSulfamides } from './utils'
import Text from '../../Text'
import variables from '../../../assets/stylesheets/variables'
import { useSelector } from 'react-redux'

const DetailStrategieAdaEasd2022Glycemie = () => {
  const highEfficacity = [
    getAuthorizedGlp1(),
    'Thiazolidinediones',
    getAuthorizedSulfamides(),
    getAuthorizedMetformine(),
    getAuthorizedIsglt2()
  ].filter(i => i).join('\n')

  const complications = useSelector(state => state.patient.complications)
  const dfg = useSelector(state => state.patient.dfg)
  const hasRenalDisease = complications.some(i => i.id === 3)
  const rearrangedTable = hasRenalDisease && dfg < 60 ? '(molécules utilisables en tenant compte du DFG du patient selon ADA/KDIGO 2022)' : ''

  return (
    <View>
      <Text style={styles.text}>
        En général, les traitements les plus efficaces sont ceux qui donnent le plus de chances d'atteindre les objectifs glycémiques.
      </Text>
      <Text style={{ ...styles.text, fontFamily: variables.fontFamily.semiBold }}>
        Efficacité sur la réduction de la glycémie {rearrangedTable}:
      </Text>
      <View style={styles.veryHighEfficacityBlock}>
        <Text style={{ fontFamily: variables.fontFamily.semiBold, marginBottom: 4 }}>Très haute</Text>
        <Text style={{ marginBottom: 2 }}>Dulaglutide (haute dose), sémaglutide, tirzepatide</Text>
        <Text style={{ marginBottom: 2 }}>Insuline</Text>
        <Text>Combinaison liraglutide & degludec</Text>
        {dfg > 30 && (
            <Text>Combinaison lixisenatide & glargine</Text>
          )
        }
      </View>
      <View style={styles.highEfficacityBlock}>
        <Text style={{ fontFamily: variables.fontFamily.semiBold, marginBottom: 4 }}>Haute</Text>
        <Text>{highEfficacity}</Text>
      </View>
      <View style={styles.intermediateEfficacityBlock}>
        <Text style={{ fontFamily: variables.fontFamily.semiBold, marginBottom: 4 }}>Intermédiaire</Text>
        <Text>{getAuthorizedIdpp4()}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 15,
    lineHeight: 24,
    marginBottom: 20
  },
  veryHighEfficacityBlock: {
    backgroundColor: variables.successColor,
    padding: 10,
    borderRadius: 0
  },
  highEfficacityBlock: {
    backgroundColor: variables.warningColor,
    padding: 10,
    borderRadius: 0
  },
  intermediateEfficacityBlock: {
    backgroundColor: variables.dangerColor,
    padding: 10,
    borderRadius: 0
  }
})

export default DetailStrategieAdaEasd2022Glycemie
