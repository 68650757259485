export const complications = [
  {
    id: 1,
    label: 'Rétinopathie'
  },
  {
    id: 'neuropathie',
    label: 'Neuropathie'
  },
  {
    id: 4,
    label: 'Maladie athéromateuse',
    info: 'antecedent-cardio'
  },
  {
    id: 5,
    label: 'Insuffisance cardiaque'
  },
  {
    id: 3,
    label: 'Maladie rénale chronique',
    disabled: true
  }
]
