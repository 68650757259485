export const esc2019 = { id: 2, label: 'ESC/EASD 2019', flag: '🇪🇺' }
export const sfdsfensfa2016 = { id: 4, label: 'SFD/SFE/NSFA 2016', flag: '🇫🇷' }
export const ada2019 = { id: 6, label: 'ADA/EASD 2019', flag: '🇺🇸🇪🇺' }
export const hassfhta2016 = { id: 7, label: 'HAS/SFHTA 2016', flag: '🇫🇷' }
export const sfd2023 = { id: 'sfd_2023', label: 'SFD 2023', flag: '🇫🇷' }
export const sfd2021 = { id: 'sfd_2021', label: 'SFD 2021', flag: '🇫🇷' }
export const sfdsfc2021 = { id: 'sfd_sfc_2021', label: 'SFD/SFC 2021', flag: '🇫🇷' }
export const esc2021 = { id: 'esc_2021', label: 'ESC 2021', flag: '🇪🇺' }
export const esc2023 = { id: 'esc_2023', label: 'ESC 2023', flag: '🇪🇺' }
export const adaeasd2022 = { id: 6, label: 'ADA/EASD 2022', flag: '🇺🇸🇪🇺' }
export const adakdigo2022 = { id: 'ada_kdigo_2022', label: 'ADA/KDIGO 2022', flag: '🇺🇸🌍' }
export const ada2023 = { id: 'ada_2023', label: 'ADA 2023', flag: '🇺🇸' }
